
import { LOGOUT_USER } from 'actions/auth'
import { GET_QUOTA } from 'actions/quota'

export const quotaState = {
  fetchError: null,
  isFetching: false,
  isFetched: false,
  item: {}
}

export const quota = (state=quotaState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        fetchError: null,
        isFetched: false,
        isFetching: false,
        item: {},
      }
    }
    case GET_QUOTA.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    }
    case GET_QUOTA.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      })
    }
    case GET_QUOTA.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        item: {},
        fetchError: action.response.metadata,
      })
    }
  }

  return state
}

