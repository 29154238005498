import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'

// components
import Loading from 'components/Loading'
import LoginForm from 'components/LoginForm'
import UserForm from 'components/UserForm'
import ApplicationForm from 'components/ApplicationForm'
import ApplicationSelect from 'components/ApplicationSelect'
import Tabs from 'components/Tabs'

// actions
import { getIntegrationRequest, bindIntegration } from 'actions/integration_request'
import { loginUser } from 'actions/auth'
import { createUser } from 'actions/user'
import { createApplication, setDefaultApplication } from 'actions/application'

// other
import { validateLogin, validateUser, validateApplicationForm } from 'utils/validation'
import styles from './WeeblySetup.css'
import { themesState } from 'reducers/themes';

export class WeeblySetupView extends React.Component {

  static propTypes = {
    applications: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    system: PropTypes.object.isRequired,
    integration: PropTypes.object.isRequired,
    loginError: PropTypes.object,
  }

  state = {
    loginValidation: null,
    signupValidation: null,
    createApplicationValidation: null,  // validation for creating a new application
    tab: 'signup',                      // login or signup view
    connectOption: 'createApplication'  // if user should link to existing app, or create a new one
  }

  componentWillMount() {
    this.props.dispatch(getIntegrationRequest({ id: this.props.location.query.ir  }))
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.system.ready) {
      if (nextProps.applications.items.length > 0) {
        this.setState({ connectOption: 'listApplications' })
      }
    }
  }

  /**
   * Login into existing account
   *
   */
  handleLoginClick = (loginData) => {
    const validationResult = validateLogin(loginData)

    if (validationResult.ok) {
      this.props.dispatch(loginUser({
        email: loginData.email,
        password: loginData.password,
        integrationRequestUUID: this.props.integration.item.uuid,
      }))
    }

    this.setState({
      loginValidation: validationResult
    })
  }

  /**
   * When create account is clicked
   *
   */
  handleRegisterClick = (userData) => {
    const validationResult = validateUser(userData)

    if (validationResult.ok) {
      this.props.dispatch(createUser({ user: userData }))
      this.setState({ signupValidation: null })
    } else {
      this.setState({ signupValidation: validationResult })
    }
  }

  /**
   * When new application is created
   *
   */
  handleCreateApplicationClick = (evt, applicationData) => {
    const results = validateApplicationForm(applicationData)

    evt.preventDefault()

    if (!results.ok) {
      this.setState({ createApplicationValidation: results })
    } else {
      this.props.dispatch(createApplication({data : applicationData }))

      // saga will wait for application to be created, and inject the application id
      // into the request
      this.props.dispatch(bindIntegration({
        integrationRequestUUID: this.props.integration.item.uuid,
        redirect: '/',
        growl: 'Weebly has been setup',
      }))
    }
  }

  /**
   * Called when an application is selected in the list
   *
   */
  handleApplicationChange = (application) => {
    this.props.dispatch(setDefaultApplication(application))
  }

  /**
   * Bind the integration to an existing application
   *
   */
  handleConnectToApplicationClick = (evt) => {
    evt.preventDefault()

    this.props.dispatch(bindIntegration({
      application: this.props.applications.selected,
      integrationRequestUUID: this.props.integration.item.uuid,
      redirect: '/',
      growl: 'Weebly has been setup',
    }))
  }

  /**
   * Tabs for showing existing apps or new application form
   *
   */
  renderConnectOptionsTabs = () => {
    const tabs = [
      { label: 'Create new project', value: 'createApplication' },
      { label: 'Existing project', value: 'listApplications' },
    ]
    const selectedTab = tabs.find((tab) => tab.value === this.state.connectOption)

    return (
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab ? selectedTab : null}
        onClick={(tab) => this.setState({ connectOption: tab.value })}
      />
    )
  }

  /**
   * Logged in view
   *
   */
  renderLoggedInView() {
    let applications = this.props.applications.items
    let content = null
    let applicationData = {
      name: this.props.integration.item.site.site_title,
      url: 'http://' + this.props.integration.item.site.domain,
    }

    let createApplication = (
      <ApplicationForm
        validation={this.state.createApplicationValidation}
        onSave={this.handleCreateApplicationClick}
        isSaving={this.props.applications.isCreating}
        application={applicationData}
        buttonName={'Add your Weebly website'}
        buttonClassName={'is-fullwidth'}
      />
    )

    let listApplications = (
      <>
        <p className="content">
          You've got existing applications in your account, would you like to one to use one for your Weebly site,
          or create a new application
        </p>
        <ApplicationSelect applications={this.props.applications} onChange={this.handleApplicationChange} />
        <div className={styles.buttonRow}>
          <a href="#connect" onClick={this.handleConnectToApplicationClick} className="btn btn-primary btn-block">Connect with Weebly website</a>
        </div>
      </>
    )

    if (this.state.connectOption == 'listApplications') {
      content = listApplications
    } else {
      content = createApplication
    }

    return (
      <div className="box">
        { applications.length > 0 ? this.renderConnectOptionsTabs() : null }
        <div className="mt-3">
          {content}
        </div>
      </div>
    )
  }

  /**
   * Render the logged out view
   *
   */
  renderLoggedOutView() {
    let tabs = this.renderTabs()
    let signup = (
      <UserForm
        serverError={this.props.user.createError}
        validation={this.state.signupValidation}
        onRegisterClick={this.handleRegisterClick}
        user={this.props.integration.item.user}
        loading={this.props.user.isCreating}
      />
    )

    let login = (
      <LoginForm
        validation={this.state.loginValidation}
        serverError={this.props.loginError}
        onLoginClick={this.handleLoginClick}
        loading={this.props.system.isLoading}
      />
    )

    return (
      <div className="box">
        <p>
          Awesome, we've connected to your Weebly account!</p>
        <p>If you've got a Crowdlever account, login here or create a new account to complete setup</p>
        {tabs}
        <div className="mt-3">
          { this.state.tab == 'login' ? login : signup }
        </div>
      </div>
    )
  }

  /**
   * Render tabs
   *
   */
  renderTabs = () => {
    const tabs = [
      { label: 'Signup', value: 'signup' },
      { label: 'Login', value: 'login' },
    ]
    const selectedTab = tabs.find(tab => this.state.tab === tab.value)

    return (
      <Tabs
        selectedTab={selectedTab}
        tabs={tabs}
        onClick={(tab) => this.setState({ tab: tab.value })}
      />
    )
  }

  render() {
    let content = null

    if (this.props.auth.loggedIn && this.props.system.isLoading) {
      return (
        <Loading loading={this.props.system.isLoading}>
          Please wait while the app loads...
        </Loading>
      )
    }

    if (this.props.integration.isFetching) {
      return (
        <Loading loading={this.props.integration.isFetching}>
          Loading information from your weebly account
        </Loading>
      )
    }

    if (this.props.auth.loggedIn) {
      content = this.renderLoggedInView()
    } else {
      content = this.renderLoggedOutView()
    }

    return (
      <div className={styles.root}>
        <h2 className="mb-5 text-center">Setup your Crowdlever account</h2>
        <div className={styles.content}>
          {content}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    user: state.user,
    location: ownProps.location,
    system: state.system,
    integration: state.integrationRequest,
    loginError: state.auth.loginError,
    applications: state.applications,
  }
}

export default connect(mapStateToProps)(WeeblySetupView)

