import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Link } from 'react-router'

import styles from './Billing.css'
import { getInvoices, cancelSubscription, getSubscription } from 'actions/subscription'
import { getCreditCard } from 'actions/payment'
import { getSubscriptionDescription } from './utils'
import Modal from 'components/Modal'
import InvoiceList from 'components/InvoiceList'
import { useModal } from 'containers/Modal/hook'

// if there's a free plan, this should be set to false
const CAN_CANCEL = true

const CancelSubscriptionModal = ({ onClose, onCancelSubscription }) => {
  const handleClickCancelSubscription = evt => {
    evt.preventDefault()
    onCancelSubscription()
  }
  return (
    <Modal onClose={onClose}>
      <p>Are you sure you want to do this?</p>
      <p>If you delete your subscription you will not be able to use this app. Don't worry though, your account can be reactivated any time in the future</p>
      <div>
        <button className="btn btn-primary" href="#" onClick={handleClickCancelSubscription}>Yes, cancel my subscription</button>
        <a className="btn btn-link" onClick={onClose}>Nevermind</a>
      </div>
    </Modal>
  )
}

const SubscriptionInformation = ({ subscription, onCancel }) => {

  const handleClickCancel = evt => {
    evt.preventDefault()
    onCancel()
  }

  if (subscription.isFetching) {
    return <p><i className="fas fa-spinner fa-spin" /> Fetching subscription information...</p>
  }

  if (subscription.item == null) {
    return (
      <div>
        <p>No subscription</p>
        <Link className="btn btn-primary" to={'/subscription'}>Subscribe to plan</Link>
      </div>
    )
  }

  let canChangePlan = !subscription.item.cancelAtPeriodEnd
  let canCancel = subscription.item.gateway === 'stripe' && !subscription.item.cancelAtPeriodEnd && CAN_CANCEL
  let description = getSubscriptionDescription(subscription.item)

  const cancelBtnClass = classNames('btn', 'btn-danger', {
    'loading': subscription.isCancelling
  })

  return (
    <div className={styles.subscriptionBlock}>
      {description}
      <div className={styles.actions}>
        { canChangePlan ? <Link className="btn btn-primary" to={'/subscription'}>Change plan</Link> : null }
        { canCancel ? <button className={cancelBtnClass} onClick={handleClickCancel}>Cancel subscription</button> : null }
      </div>
    </div>
  )
}

const PaymentInformation = ({ payment }) => {
  let content = null

  if (payment.isFetching) {
    return <p><i className="fas fa-spinner fa-spin" /> Fetching payment information...</p>
  }

  if (!payment.item) {
    content = (
      <div>
        <p>There is no payment method on file. When you upgrade to a paid plan, we'll show your payment method here.</p>
        <Link className="btn btn-link" to={'/payment'}>Add credit card</Link>
      </div>
    )
  } else {
    let card = payment.item
    content = (
      <div className={styles.paymentInfo}>
        <div><i className="fal fa-credit-card" /> Card ending in: {card.last4}. Expires: {card.month}/{card.year}</div>
        <Link className="btn btn-link" to={'/payment'}>Change credit card</Link>
      </div>
    )
  }

  return (
    <div className={styles.paymentBlock}>
      {content}
    </div>
  )
}

const StripeBillingDetails = ({ auth, invoices, subscription, payment, onCancel }) => {
    return (
      <div className={styles.root}>
        <h3 className="mt-3">Your subscription</h3>
        <SubscriptionInformation subscription={subscription} onCancel={onCancel} />
        <div className="divider"></div>

        <h3 className="title is-h3">Payment method</h3>
        <PaymentInformation payment={payment} />
        <div className="divider"></div>

        <h3 className="title is-h3">Invoices</h3>
        { invoices.isFetched
          ? <InvoiceList auth={auth} invoices={invoices.items} />
          : null 
        }
      </div>
    )
  }

const ShopifyBillingView = ({ subscription }) => {
  return (
    <div className={styles.root}>
      <h3 className="mt-3">Your subscription</h3>
      { subscription.item
        ? this.renderSubscriptionInformation()
        : <div>No subscription</div>
      }
    </div>
  )
}

const BillingView = ({ auth, subscription, invoices, payment, user, dispatch }) => {
  const { setModal, closeModal } = useModal()

  React.useEffect(() => {
    dispatch(getSubscription())
    dispatch(getInvoices())
    dispatch(getCreditCard())
  }, [])

  const handleConfirmCancel = () => {
    closeModal()
    dispatch(cancelSubscription({
      redirect: '/no-subscription'
    }))
  }

  const handleCancelSubscription = () => {
    setModal(<CancelSubscriptionModal onCancelSubscription={handleConfirmCancel} onClose={closeModal} />)
  }

  return (
    <>
    { user.origin === 'shopify'
      ? <ShopifyBillingView subscription={subscription} />
      : <StripeBillingDetails auth={auth} subscription={subscription} invoices={invoices} payment={payment} onCancel={handleCancelSubscription} /> 
    }
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.item,
    subscription: state.subscription,
    payment: state.payment,
    invoices: state.invoices,
    auth: state.auth,
    coupon: state.coupon,
  }
}

export default connect(mapStateToProps)(BillingView)

