
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { SEND_EMAIL, sendEmailSuccess, sendEmailFail } from 'actions/misc'
import { INITIALIZE_APPLICATION, initializeApplicationSuccess } from 'actions/misc'
import { SHOW_GROWL, hideGrowl, showGrowl, setFlags } from 'actions/misc'
import { GET_INTEGRATIONS } from 'actions/integrations'
import { GET_WORLD, getWorldSuccess, getWorldFail } from 'actions/misc'
import { getUserSuccess } from 'actions/user'
import { getSubscriptionSuccess, getPlansSuccess } from 'actions/subscription'
import { getAnimationsSuccess } from 'actions/theme'
import { getQuotaSuccess } from 'actions/quota'
import { getApplicationsSuccess, setDefaultApplication } from 'actions/application'
import { logoutUser } from 'actions/auth'

// other sagas
import { getCreditCardSaga } from './payment'
import { setDefaultApplicationSaga } from './application'

export function* sendEmailSaga(action) {
  const state = yield select()
  const mail = action.args
  const response = yield call(Api.emailEmbedCode, state.auth.authToken, mail)

  if (response.metadata.success) {
    yield put(sendEmailSuccess(response))
    yield put(showGrowl('success', 'Email has been sent'))
  } else {
    yield put(sendEmailFail(response))
  }
}

export function* initializeApplicationSaga(action) {

  yield call(getWorldSaga)

  let state = yield select() // fetch new state

  // set a default app, if not already set
  if (!state.applications.selected) {

    // if saved in cookie data
    if (state.applications.selectedId) {
      let selectedApplication = state.applications.items.find(app => app.id == state.applications.selectedId)
      if (selectedApplication) {
        yield put(setDefaultApplication(selectedApplication))
      }
    }
    else if (state.applications.items.length > 0) {
      yield put(setDefaultApplication(state.applications.items[0]))
    }
  }

  yield put(initializeApplicationSuccess())
}

export function* showGrowlSaga(action) {
  const state = yield select()

  yield call(delay, action.timeout+500) // +500 to account for hide animation
  yield put(hideGrowl())
}

export function* getWorldSaga(action) {
  const state = yield select()
  const response = yield call(Api.getWorld, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getWorldSuccess(response))
    yield put(getUserSuccess({ data: response.data.user }))

    let activeSubscription = response.data.subscriptions.find(s => s.isActive)
    if (!activeSubscription) {
      activeSubscription = null
    }

    yield put(getSubscriptionSuccess({ data: activeSubscription }))
    yield put(getAnimationsSuccess({ data: response.data.animations}))
    yield put(getQuotaSuccess({ data: response.data.quota }))
    yield put(getApplicationsSuccess({ data: response.data.applications }))
    yield put(getPlansSuccess({ data: response.data.plans }))

    // see if we've started the shopify trial subscription
    if (response.data.subscriptions.length == 0 && response.data.user.origin == 'shopify') {
      yield put(setFlags({ pendingShopifyTrial: true }))
    }

  } else {
    // just log them out if auth is invalid
    if (response.metadata.status == 401) {
      yield put(logoutUser())
    }

    yield put(getWorldFail(response))
  }

}

export default {
  sendEmail: () => takeLatest(SEND_EMAIL.REQUEST, sendEmailSaga),
  initializeApplication: () => takeLatest(INITIALIZE_APPLICATION.REQUEST, initializeApplicationSaga),
  showGrowl: () => takeLatest(SHOW_GROWL, showGrowlSaga),
  getWorld: () => takeLatest(GET_WORLD.REQUEST, getWorldSaga),
}
