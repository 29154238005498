
import deepFreeze from 'deep-freeze'

import { GET_GOOGLE_ACCOUNT, UPDATE_GOOGLE_ACCOUNT } from 'actions/ga'
import { LOGOUT_USER } from 'actions/auth'

export const gaState = {
  isFetching: false,
  isFetched: false,
  item: {},
}

export const ga = (state=gaState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetching: false,
        isFetched: false,
        item: [],
      }
    }
    case GET_GOOGLE_ACCOUNT.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case GET_GOOGLE_ACCOUNT.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      })
    }
    case GET_GOOGLE_ACCOUNT.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        fetchError: action.response.metadata,
      })
    }
    case UPDATE_GOOGLE_ACCOUNT.REQUEST: {
      return Object.assign({}, state, {
        isUpdating: true,
        isUpdated: false,
      })
    }
    case UPDATE_GOOGLE_ACCOUNT.SUCCESS: {
      return Object.assign({}, state, {
        isUpdating: false,
        isUpdated: true,
        item: action.response.data,
      })
    }
    case UPDATE_GOOGLE_ACCOUNT.FAIL: {
      return Object.assign({}, state, {
        isUpdating: false,
        isUpdated: false,
        updateError: action.response.metadata,
      })
    }
  }

  return state
}
