
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { saveDefaultSelectedApplication } from 'utils/misc'
import { GET_APPLICATIONS, getApplications, getApplicationsSuccess, getApplicationsFail, setDefaultApplication } from 'actions/application'
import { CREATE_APPLICATION, createApplicationSuccess, createApplicationFail } from 'actions/application'
import { UPDATE_APPLICATION, updateApplicationSuccess, updateApplicationFail } from 'actions/application'
import { SET_DEFAULT_APPLICATION } from 'actions/application'
import { showGrowl } from 'actions/misc'
import { getQuota } from 'actions/quota'

// other sagas
import { getApplicationAnalyticsSaga } from './analytics'
import { getNotificationsSaga } from './notification'
import { getGoogleAccountSaga } from './google'
import { getThemesSaga } from './theme'
import { getIntegrationsSaga } from './integrations'

export function* getApplicationsSaga() {
  const state = yield select()
  const response = yield call(Api.getApplications, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getApplicationsSuccess(response))
  } else {
    yield put(getApplicationsFail(response))
  }
}

export function* createApplication(action) {
  const state = yield select()
  const response = yield call(Api.createApplication, {authToken: state.auth.authToken, data: action.args.data})

  if (response.metadata.success) {
    yield put(getQuota())
    yield put(createApplicationSuccess(response))
    yield call(getApplicationsSaga)
    yield put(setDefaultApplication(response.data))

    if (action.args.redirect) {
      yield put(push(action.args.redirect))
    }
    if (action.args.growl) {
      yield put(showGrowl('success', action.args.growl))
    }
  } else {
    yield put(createApplicationFail(response))
  }
}

export function* setDefaultApplicationSaga(action) {
  saveDefaultSelectedApplication(action.args.application)
  yield fork(getApplicationAnalyticsSaga, action)
  yield fork(getNotificationsSaga, action)
  yield fork(getGoogleAccountSaga, action)
  yield fork(getThemesSaga, action)
  yield fork(getIntegrationsSaga, action)
}

export function* updateApplicationSaga(action) {
  const state = yield select()
  const response = yield call(Api.updateApplication, state.auth.authToken, action.args.application)

  if (response.metadata.success) {
    yield put(updateApplicationSuccess(response))
    yield put(showGrowl('success', 'Application has been updated'))
  } else {
    yield put(updateApplicationFail(response))
  }
}

export default {
  getApplications: () => takeLatest(GET_APPLICATIONS.REQUEST, getApplicationsSaga),
  createApplication: () => takeLatest(CREATE_APPLICATION.REQUEST, createApplication),
  setDefaultApplication: () => takeLatest(SET_DEFAULT_APPLICATION, setDefaultApplicationSaga),
  updateApplication: () => takeLatest(UPDATE_APPLICATION.REQUEST, updateApplicationSaga),
}
