import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import ModalProvider from './Modal/ModalProvider'

class Root extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.element.isRequired,
    store: PropTypes.object.isRequired
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <ModalProvider>
          <div style={{ height: '100%' }}>
            <Router history={this.props.history}>
              {this.props.routes}
            </Router>
          </div>
        </ModalProvider>
      </Provider>
    )
  }
}

export default Root

