import PropTypes from 'prop-types';
import { Link } from 'react-router'
import React from 'react';
import styles from './LoginForm.css'
import { renderErrorForField } from 'utils/misc'
import classNames from 'classnames'

export default class LoginForm extends React.Component {

  static propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    validation: PropTypes.object,
    serverError: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    email: PropTypes.string,
  }

  state = {
    email: '',
    password: ''
  }

  componentWillMount() {
    if (this.props.email) {
      this.setState({ email: this.props.email })
    }
  }

  handleLoginClick = (evt) => {
    evt.preventDefault()
    this.props.onLoginClick({
      email: this.state.email,
      password: this.state.password
    })
  }

  handleEmailChange = (evt) => {
    this.setState({
      email: evt.target.value
    })
  }

  handlePasswordChange = (evt) => {
    this.setState({
      password: evt.target.value
    })
  }

  handleFormSubmit = (evt) => {
    evt.preventDefault()
    this.props.onLoginClick({
      email: this.state.email,
      password: this.state.password
    })
  }

  render() {
    let btnClassName = classNames({
      'btn': true,
      'btn-primary': true,
      'is-fullwidth': true,
      'is-large': true,
      'is-loading': this.props.loading,
    })
    return (
      <div className={styles.root}>
        <form className="form" onSubmit={this.handleFormSubmit}>
          <label className="label">Email</label>
          <div className="form-group">
            <input value={this.state.email} onChange={this.handleEmailChange} className="form-control" type="email" placeholder="Email" />
            {renderErrorForField('email', this.props.validation)}
          </div>
          <label className="label">Password</label>
          <div className="form-group">
            <input value={this.state.password} onChange={this.handlePasswordChange} className="form-control" type="password" placeholder="Your password" />
            {renderErrorForField('password', this.props.validation)}
            {renderErrorForField('general', this.props.serverError)}
          </div>
          <div className="form-group">
            <button className={btnClassName} type="submit" onClick={this.handleLoginClick}>Login</button> 
            <Link className="ml-2" to={'/forgot-password'}>Forgot password?</Link>
          </div>
        </form>
      </div>
    )
  }
}

