// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ForgotPassword__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.ForgotPassword__content {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.ForgotPassword__forgotPasswordContainer {\n    text-align: center;\n    margin-top: 1rem;\n}\n\n.ForgotPassword__forgotPassword {\n    padding-top: 1em;\n    font-size: small;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "ForgotPassword__root",
	"content": "ForgotPassword__content",
	"forgotPasswordContainer": "ForgotPassword__forgotPasswordContainer",
	"forgotPassword": "ForgotPassword__forgotPassword"
};
module.exports = exports;
