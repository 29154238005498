// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Billing__root {\n}\n\n.Billing__root .divider {\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n\n.Billing__subscriptionBlock .Billing__actions {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 1rem;\n}\n\n.Billing__paymentBlock {\n}\n\n.Billing__invoiceBlock {\n}\n\n.Billing__paymentInfo {\n    display: flex;\n    justify-content: space-between;\n}", ""]);
// Exports
exports.locals = {
	"root": "Billing__root",
	"subscriptionBlock": "Billing__subscriptionBlock",
	"actions": "Billing__actions",
	"paymentBlock": "Billing__paymentBlock",
	"invoiceBlock": "Billing__invoiceBlock",
	"paymentInfo": "Billing__paymentInfo"
};
module.exports = exports;
