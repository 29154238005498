import PropTypes from 'prop-types';
import React from 'react';
import styles from './ThemePreview.css'
import Handlebars from 'handlebars'

import bgImage from 'assets/transparent_bg.jpg'
import previewImage from 'assets/preview_image.png'

export default class ThemePreview extends React.Component {

  static propTypes = {
    html: PropTypes.string.isRequired,
    css: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }

  getHtmlFromTemplate = () => {
    let template = Handlebars.compile(this.props.html)

    let data = {
      image_url: previewImage,
      time_ago: '30 minutes ago',
      message: 'This is your notification message'
    }

    return template(data)
  }

  render() {
    let classes = [styles.notificationContainer]

    if (this.props.size == 'normal') {
      classes.push(styles.normal)
    } else {
      classes.push(styles.large)
    }

    if (this.props.position == 'left') {
      classes.push(styles.left)
    } else {
      classes.push(styles.right)
    }

    const inlineStyle = {
      backgroundImage: `url(${bgImage})`
    }

    return (
      <div className={styles.root} style={inlineStyle}>
        <style>
          {this.props.css}
        </style>
        <div className={classes.join(' ')} dangerouslySetInnerHTML={{ __html: this.getHtmlFromTemplate() }}>
        </div>
      </div>
    )
  }
}
