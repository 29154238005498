
import deepFreeze from 'deep-freeze'

import { GENERATE_NOTIFICATION_WORKSPACE } from 'actions/misc'
import { CREATE_IMAGE, GET_NOTIFICATIONS, DELETE_NOTIFICATION , DELETE_IMAGE } from 'actions/notification'
import { LOGOUT_USER } from 'actions/auth'

export const notificationsState = {
  isCreating: false,
  isFetching: false,
  isFetched: false,
  page: 1,
  total: 0,
  size: 25,
  items: [],
  workspaces: {}
}

export const notifications = (state=notificationsState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return Object.assign({}, state, notificationsState)
    }
    case GET_NOTIFICATIONS.SUCCESS: {
      deepFreeze(state)
      return Object.assign({}, state, {
        isFetched: true,
        isFetching: false,
        items: action.response.data,
        total: action.response.metadata.total,
        page: action.response.metadata.page,
      })
    }
    case GET_NOTIFICATIONS.FAIL: {
      return state
    }
    case GENERATE_NOTIFICATION_WORKSPACE: {
      deepFreeze(state)
      let workspaces = Object.assign({}, state.workspaces, {
        [action.workspace.id]: {
          isUploading: false,
          isUploaded: false,
          image: action.workspace.image,
        }
      })
      return Object.assign({}, state, {
        workspaces: workspaces
      })
    }
    case CREATE_IMAGE.REQUEST: {
      deepFreeze(state)
      let workspaces = Object.assign({}, state.workspaces, {
        [action.args.workspaceId]: {
          isUploading: true,
          isUploaded: false
        }
      })
      return Object.assign({}, state, {
        workspaces: workspaces
      })
    }
    case CREATE_IMAGE.SUCCESS: {
      deepFreeze(state)
      let workspaces = Object.assign({}, state.workspaces, {
        [action.args.workspaceId]: {
          isUploading: false,
          isUploaded: true,
          image: action.response.data
        }
      })
      return Object.assign({}, state, {
        workspaces: workspaces
      })
    }
    case CREATE_IMAGE.FAIL: {
      deepFreeze(state)
      let workspaces = Object.assign({}, state.workspaces, {
        [action.args.workspaceId]: {
          isUploading: false,
          isUploaded: false,
          image: null,
          uploadError: action.response.metadata,
        }
      })
      return Object.assign({}, state, {
        workspaces: workspaces,
      })
    }
    case DELETE_NOTIFICATION.SUCCESS: {
      deepFreeze(state)
      let items = state.items.filter(notification => notification.id != action.response.notification.id)
      return Object.assign({}, state, {
        items: items
      })
    }
    case DELETE_NOTIFICATION.FAIL: {
      return state
    }
    case DELETE_IMAGE.REQUEST: {
      deepFreeze(state)
      let workspaces = Object.assign({}, state.workspaces, {
        [action.args.workspaceId]: {
          image: null,
        }
      })
      return Object.assign({}, state, {
        workspaces: workspaces,
      })
    }
  }
  return state
}
