
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'
import { saveUserDataToBrowser } from 'utils/misc'

import { loginUserSuccess } from 'actions/auth'
import { RESET_PASSWORD, resetPasswordSuccess, resetPasswordFail } from 'actions/auth'
import { RESET_UPDATE_PASSWORD, resetUpdatePasswordSuccess, resetUpdatePasswordFail } from 'actions/auth'
import { initializeApplication } from 'actions/misc'
import { showGrowl } from 'actions/misc'

export function* resetPasswordSaga(action) {
  const state = yield select()
  const response = yield call(Api.forgotPassword, action.args.emailAddress)

  if (response.metadata.success) {
    yield put(resetPasswordSuccess(response))
  } else {
    yield put(resetPasswordFail(response))
  }
}

export function* resetUpdatePasswordSaga(action) {
  const state = yield select()
  const response = yield call(Api.resetPassword, action.args.token, action.args.password)

  if (response.metadata.success) {
    saveUserDataToBrowser(response.data)
    yield put(resetUpdatePasswordSuccess(response))
    yield put(loginUserSuccess(response))
    yield put(showGrowl('success', 'Your password has been reset'))
    yield put(initializeApplication())

    if (action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  } else {
    yield put(resetUpdatePasswordFail(response))
  }
}

export default {
  resetPassword: () => takeLatest(RESET_PASSWORD.REQUEST, resetPasswordSaga),
  resetUpdatePassword: () => takeLatest(RESET_UPDATE_PASSWORD.REQUEST, resetUpdatePasswordSaga),
}
