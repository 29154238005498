import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import styles from './ShopifySubscription.css'
import { getPlans, getSubscription } from 'actions/subscription'
import { currencyToSymbol } from 'utils/misc'
import className from 'classnames'
import { validateCreditCard } from 'utils/validation'
import { createShopifySubscription } from 'actions/subscription'

const RETURN_TO = '/account/billing?tab=2'

class ShopifySubscriptionView extends React.Component {

  static propTypes = {
    applications: PropTypes.object.isRequired,
    plans: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
  }

  state = {
    selectedPlan: null,
    isPaymentRequired: false,
  }

  componentWillMount() {
    this.props.dispatch(getPlans())

    if (!this.props.subscription.isFetched && !this.props.subscription.isFetching) {
      this.props.dispatch(getSubscription())
    }

    if (this.props.subscription.isFetched && this.props.subscription.item) {
      this.setState({
        selectedPlan: this.props.subscription.item.plan,
        isPaymentRequired: this.props.subscription.item.plan.amount > 0,
      })
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.subscription.isFetched && !this.state.selectedPlan && newProps.subscription.item) {
      this.setState({
        selectedPlan: newProps.subscription.item.plan,
        isPaymentRequired: newProps.subscription.item.plan.amount > 0,
      })
    }
  }

  handleChangePlan = (plan) => {
    this.setState({
      selectedPlan: plan,
      isPaymentRequired: plan.amount > 0,
    })
  }

  /**
   * Called when the big green button is clicked
   *
   */
  handleFormSubmit = (evt) => {
    let selectedApplication = this.props.applications.items.find(x => x.id == this.props.applications.selectedId)
    let data = {
      plan: this.state.selectedPlan,
      application: { // trim down the application object, cos it's big
        id: selectedApplication.id,
      },
    }

    this.props.dispatch(createShopifySubscription({ data, redirect: RETURN_TO}))
  }

  /**
   * Get text description of the plan that the user has selected
   *
   */
  getSubscriptionDescription = () => {
    let plan = this.state.selectedPlan
    let cs = currencyToSymbol(plan.currency)
    let description = ''

    if (plan.id == this.props.subscription.item.plan.id) {
      description = `This is the current plan you are subscribed to.`
    } else {
      description = `You are subscribing to the ${plan.name} plan, which is billed at ${cs}${plan.amount} per ${plan.billingInterval}.`
    }

    return description
  }

  /**
   * Render the list of subscription plans
   *
   */
  renderPlan = (plan) => {
    let selected = this.state.selectedPlan && plan.id == this.state.selectedPlan.id
    let classname = className({
      [styles.plan]: true,
      [styles.selected]: selected,
    })
    return (
      <div key={plan.id} className={classname} onClick={(evt) => this.handleChangePlan(plan)}>
        <div className={styles.container}>
          <div className={styles.name}>{plan.name}</div>
          { selected ? <p className={styles.description} dangerouslySetInnerHTML={{ __html: plan.description }}></p> : 'Click to view details.' }
        </div>
        <div className={styles.price}>
          <div className={styles.amount}>{ currencyToSymbol(plan.currency) }{ plan.amount }</div>
          <div className={styles.period}>per month</div>
        </div>
      </div>
    )
  }

  renderPlanSelectionPanel = () => {
    let plans = this.props.plans.items.map(this.renderPlan)
    return (
      <div className={styles.plans}>
        <h5 className="title is-5 has-text-centered">Choose your plan</h5>
        {this.props.plans.isFetching || this.props.subscription.isFetching ? 'Loading...' : plans}
      </div>
    )
  }

  render() {
    let classname = className({
      [styles.root]: true,
      [styles.single]: true,
    })
    let saveBtnClassName = className({
      'btn': true,
      'btn-primary': true,
      'btn-block': true,
      'is-loading': this.props.subscription.isCreating,
    })
    return (
      <div className={classname}>
        <h2 className="text-center">Change your subscription</h2>
        <p className="text-center">Upgrade or downgrade your account</p>
        <div className="box">
          {this.renderPlanSelectionPanel()}
          <p className="control">
            <button onClick={this.handleFormSubmit} type="button" className={saveBtnClassName}>Update subscription</button>
          </p>
          <p className="content has-text-centered">
            <center><Link to={RETURN_TO}>Cancel</Link></center>
          </p>
        </div>
        <div className="mt-3 text-center">
          <i className="fa fa-lock" /> This page and all of the Crowdlever site is secured with SSL.
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    subscription: state.subscription,
    plans: state.plans,
    payment: state.payment,
    applications: state.applications
  }
}

export default connect(mapStateToProps)(ShopifySubscriptionView)

