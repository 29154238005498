import PropTypes from 'prop-types';
import React from 'react';
import styles from './AccountForm.css'
import { renderErrorForField } from 'utils/misc'

export default class AccountForm extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    buttonName: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    validation: PropTypes.object
  }

  static defaultProps = {
    buttonName: 'Save',
    isSaving: false
  }

  state = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: ''
  }

  componentWillMount = () => {
    this.setState({
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
      emailAddress: this.props.user.emailAddress
    })
  }

  handleSave = (evt) => {
    this.props.onSave(this.state, evt)
  }

  handleFirstNameChange = (evt) => {
    this.setState({ firstName: evt.target.value })
  }

  handleLastNameChange = (evt) => {
    this.setState({ lastName: evt.target.value })
  }

  handleEmailAddressChange = (evt) => {
    this.setState({ emailAddress: evt.target.value })
  }

  handlePasswordChange = (evt) => {
    this.setState({ password: evt.target.value })
  }

  render() {
    let btnClassName = 'btn btn-primary'
    if (this.props.isSaving) {
      btnClassName += ' is-loading'
    }

    return (
      <form className="form">
        <label className="label">First name</label>
        <div className="form-group">
          <input onChange={this.handleFirstNameChange} value={this.state.firstName} className="form-control" type="text" placeholder="First name" />
          {renderErrorForField('firstName', this.props.validation)}
        </div>
        <label className="label">Last name</label>
        <div className="form-group">
          <input onChange={this.handleLastNameChange} value={this.state.lastName} className="form-control" type="text" placeholder="Last name" />
          {renderErrorForField('lastName', this.props.validation)}
        </div>
        <label className="label">Email address</label>
        <div className="form-group">
          <input onChange={this.handleEmailAddressChange} value={this.state.emailAddress} className="form-control" type="text" placeholder="santa@northpole.com" />
          {renderErrorForField('emailAddress', this.props.validation)}
        </div>
        <label className="label">Password</label>
        <div className="form-group">
          <input onChange={this.handlePasswordChange} value={this.state.password} className="form-control" type="password" />
          {renderErrorForField('emailAddress', this.props.validation)}
        </div>
        <div className="form-group">
          <button onClick={this.handleSave} className={btnClassName}>{this.props.buttonName}</button>
        </div>
      </form>
    )
  }
}

