
import {GET_PLANS } from 'actions/subscription'

export const plansState = {
  isFetching: false,
  isFetched: false,
  items: [],
}

export const plans = (state=plansState, action) => {
  switch (action.type) {
    case GET_PLANS.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
      })
    }
    case GET_PLANS.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        items: action.response.data,
      })
    }
    case GET_PLANS.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        serverError: action.response.metadata,
      })
    }
  }

  return state
}
