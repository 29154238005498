import PropTypes from 'prop-types';
import React from 'react';
import styles from './GAGoalSetup.css'
import Select from 'react-select'

export default class GAGoalSetup extends React.Component {

  static propTypes = {
    ga: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  state = {
    steps: ['account'],
    selectedAccount: null,
    selectedProperty: null,
    selectedProfile: null,
    selectedGoal: null,
  }

  handleSaveClick = (evt) => {
    evt.preventDefault()
    this.props.onSave({
      selectedAccount: this.state.selectedAccount,
      selectedProperty: this.state.selectedProperty,
      selectedProfile: this.state.selectedProfile,
      selectedGoal: this.state.selectedGoal
    })
  }

  handleAccountChange = (option) => {
    if (!option) {
      this.setState({
        steps: ['account'],
        selectedAccount: null,
        selectedProperty: null,
        selectedProfile: null,
        selectedGoal: null,
      })
    } else {
      this.setState({
        steps: ['account', 'property'],
        selectedAccount: option.obj,
        selectedProperty: null,
        selectedProfile: null,
        selectedGoal: null,
      })
    }
  }

  handlePropertyChange = (option) => {
    if (!option) {
      this.setState({
        steps: ['account', 'property'],
        selectedProperty: null,
        selectedProfile: null,
        selectedGoal: null,
      })
    } else {
      this.setState({
        steps: ['account', 'property', 'profile'],
        selectedProperty: option ? option.obj : null,
        selectedProfile: null,
        selectedGoal: null,
      })
    }
  }

  handleProfileChange = (option) => {
    if (!option) {
      this.setState({
        steps: ['account', 'property', 'profile'],
        selectedProfile: null,
        selectedGoal: null,
      })
    } else {
      this.setState({
        steps: ['account', 'property', 'profile', 'goals'],
        selectedProfile: option.obj,
        selectedGoal: null,
      })
    }
  }

  handleGoalChange = (option) => {
    if (!option) {
    } else {
      this.setState({
        selectedGoal: option.obj,
      })
    }
  }

  getChooseAccountPanel = () => {
    let accounts = []
    for (let account of this.props.ga.item.googleAnalyticsAccount) {
      accounts.push({
        label: account.name,
        value: account.id,
        obj: account,
      })
    }
    return (
      <div className={styles.selectContainer}>
        <label>Choose your Google analytics account</label>
        <Select
          value={this.state.selectedAccount ? this.state.selectedAccount.id : null}
          className={styles.select}
          options={accounts}
          onChange={this.handleAccountChange}
        />
      </div>
    )
  }

  getPropertyPanel = () => {
    let properties = []
    for (let prop of this.state.selectedAccount.webProperties) {
      properties.push({
        value: prop.id,
        label: prop.name,
        obj: prop,
      })
    }
    return (
      <div className={styles.selectContainer}>
        <label>Property</label>
        <Select
          value={this.state.selectedProperty ? this.state.selectedProperty.id : null }
          className={styles.select}
          options={properties}
          onChange={this.handlePropertyChange}
        />
      </div>
    )
  }

  getProfilePanel = () => {
    let profiles = []
    for (let profile of this.state.selectedProperty.profiles) {
      profiles.push({
        value: profile.id,
        label: profile.name,
        obj: profile,
      })
    }
    return (
      <div className={styles.selectContainer}>
        <label>Profile</label>
        <Select
          value={this.state.selectedProfile ? this.state.selectedProfile.id : null }
          className={styles.select}
          options={profiles}
          onChange={this.handleProfileChange}
        />
      </div>
    )
  }

  getGoalsPanel = () => {
    let goals = []
    for (let goal of this.state.selectedProfile.goals) {
      goals.push({
        value: goal.id,
        label: goal.name,
        obj: goal,
      })
    }
    return (
      <div className={styles.selectContainer}>
        <label>Goal</label>
        <Select
          value={this.state.selectedGoal ? this.state.selectedGoal.id : null}
          className={styles.select}
          options={goals}
          onChange={this.handleGoalChange}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={styles.root}>
        <h2>Goal tracking</h2>
        <h4 className="text-muted">Complete setup to enable conversion tracking</h4>
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="alert alert-success">
                <p><i className="fa fa-check"></i> Congratulations, your Google analytics account is now connected. </p>
                <p>Now use the form on the right to specify which conversion goal you want to track for your website.</p>
              </div>
            </div>
            <div className="col">
              { this.state.steps.indexOf('account') >= 0 ? this.getChooseAccountPanel() : null }
              { this.state.steps.indexOf('property') >= 0 ? this.getPropertyPanel() : null }
              { this.state.steps.indexOf('profile') >= 0 ? this.getProfilePanel() : null }
              { this.state.steps.indexOf('goals') >= 0 ? this.getGoalsPanel() : null }
              { this.state.steps.indexOf('goals') >= 0  && this.state.selectedGoal !== null
                ? <a href="#" onClick={this.handleSaveClick} className="btn btn-primary btn-block">Save</a>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
