
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const UPDATE_EVENT_TEMPLATE    = createRequestTypes('UPDATE_EVENT_TEMPLATE')
export const GET_INTEGRATIONS         = createRequestTypes('GET_INTEGRATIONS')
export const UPDATE_ECWID_INTEGRATION = createRequestTypes('UPDATE_ECWID_INTEGRATION')

export const [
  updateEventTemplate,
  updateEventTemplateSuccess,
  updateEventTemplateFail,
] = createAsyncRequestActions(UPDATE_EVENT_TEMPLATE)

export const [
  getIntegrations,
  getIntegrationsSuccess,
  getIntegrationsFail,
] = createAsyncRequestActions(GET_INTEGRATIONS)

export const [
  updateEcwidIntegration,
  updateEcwidIntegrationSuccess,
  updateEcwidIntegrationFail,
] = createAsyncRequestActions(UPDATE_ECWID_INTEGRATION)

