// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal-module__root {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  left: 0;\n  opacity: 1;\n  background: rgba(145, 145, 145, 0.6);\n  z-index: 999;\n}\n\n.Modal-module__content {\n  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, 0.1), 0 4px 80px -8px rgba(0, 20, 60, 0.2);\n  z-index: 9999;\n  background-color: #fff;\n  border-radius: 0.4rem;\n  width: 640px;\n}\n\n@media screen and (max-width: 600px) {\n  .Modal-module__content {\n    box-shadow: none;\n    width: 100%;\n    height: 100%;\n    min-width: initial;\n    border-radius: 0;\n  }    \n}", ""]);
// Exports
exports.locals = {
	"root": "Modal-module__root",
	"content": "Modal-module__content"
};
module.exports = exports;
