/* global GIT_VERSION */
import PropTypes from 'prop-types';
import React from 'react';
import fecha from 'fecha'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import styles from './DefaultLayout.css'

import SideMenu from 'components/SideMenu'
import Nav from 'components/Nav'
import Loading from 'components/Loading'
import Modal from 'components/Modal'

import { setDefaultApplication } from 'actions/application'
import { logoutUser } from 'actions/auth'
import { currencyToSymbol } from 'utils/misc'
import { useModal } from 'containers/Modal/hook'

const ShopifySubscriptionCreatedModal = ({ subscription, onClose }) => {
    let description = ''
    let plan = subscription.item.plan
    let symbol = currencyToSymbol(plan.currency)
    let nextBillingDate = subscription.item.periodEndAt
    let nextDate = fecha.format(new Date(nextBillingDate*1000), 'Do MMMM YYYY')

    const handleClickClose = evt => {
      evt.preventDefault()
      onClose()
    }

    if (subscription.item.status == 'trialing') {
      let trialEndDate = fecha.format(new Date(subscription.item.trialEndAt*1000), 'Do MMMM YYYY')
      description = (
        <div className="content">
          <p>You are now <b>trialing</b> the {subscription.item.plan.name} plan. The trial period will end on {trialEndDate}.</p>
          <p>Once your trial period ends you will be charged <b>{symbol}{plan.amount}</b> per <b>{plan.billingIntervalSingular}</b></p>
        </div>
      )
    } else {
      description = (
        <p className="content">
          You are now on the {subscription.item.plan.name} plan, billed at <b>{symbol}{plan.amount}</b> per <b>{plan.billingIntervalSingular}</b>.
          Your next payment will be on {nextDate}.
        </p>
      )
    }
    return (
      <Modal>
        <p>Subscription created</p>
        {description}
        <a href="#" className="btn btn-primary" onClick={handleClickClose}>Close</a>
      </Modal>
    )
  }

const DefaultLayoutView = ({ user, applications, system, quota, subscription, location, dispatch, integrations, children }) => {
  const { setModal, closeModal } = useModal()

  // redirect to /no-subscription/ view if there's no subscription
  React.useEffect(() => {
    if (system.ready && !subscription.item) {
      dispatch(push('/no-subscription'))
    }
  }, [system, subscription])

  // redirect to create application page if account doesn't have any apps setup
  React.useEffect(() => {
    const createAppPath = '/applications/create'
    if (system.ready && applications.items.length == 0 &&
        location.pathname != createAppPath) {
        dispatch(push(createAppPath))
    }
  }, [system, applications, location])


  React.useEffect(() => {
    if (location.query.ssc === '1' && system.ready) {
      setModal(
        <ShopifySubscriptionCreatedModal subscription={subscription} onClose={closeModal} />
      )
    }
  }, [location, system])

  const handleApplicationChange = (app) => {
    if (app.id !== applications.selectedId) {
      dispatch(setDefaultApplication(app))
      dispatch(push('/'))
    }
  }

  const handleLogout = () => {
    dispatch(logoutUser())
    dispatch(push('/login'))
  }

  if (!system.ready) {
    return (
      <Loading loading={true} text="Crowdlever is loading..." />
    )
  }

  return (
    <div className={styles.root}>
      <Nav user={user} onLogout={handleLogout} />
      <div className={styles.content}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-2">
              <SideMenu
                onApplicationChange={handleApplicationChange}
                applications={applications}
                integrations={integrations}
                quota={quota}
              />
            </div>
            <div className="col-10">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps)
  return {
    auth: state.auth,
    user: state.user,
    applications: state.applications,
    system: state.system,
    integrations: state.integrations,
    quota: state.quota,
    subscription: state.subscription,
    location: ownProps.location,
  }
}

export default connect(mapStateToProps)(DefaultLayoutView)

