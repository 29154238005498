
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { loginUser } from 'actions/auth'
import styles from './Account.css'
import AccountFrom from 'components/AccountForm'
import { updateUser } from 'actions/user'

export class AccountView extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired
  }

  handleSave = (data, evt) => {
    evt.preventDefault()
    this.props.dispatch(updateUser({ user: data }))
  }

  render() {
      return (
      <div className={styles.root}>
        <p className="mt-3">Update your user information &amp; security settings</p>
        <AccountFrom user={this.props.user.item} onSave={this.handleSave} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(AccountView)

