// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ApplicationSelect__root {\n    padding: 5px 10px;\n}\n\n#ApplicationSelect__change {\n    display: inline;\n    font-size: small;\n}\n\n#ApplicationSelect__change:hover {\n    background-color: none;\n}\n\n.ApplicationSelect__text {\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ApplicationSelect__root",
	"change": "ApplicationSelect__change",
	"text": "ApplicationSelect__text"
};
module.exports = exports;
