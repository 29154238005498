// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShopifySubscription__root {\n  max-width: 500px;\n  margin: 1rem auto;\n  padding: 1rem;\n}\n\n.ShopifySubscription__root p {\n  margin-bottom: 1rem;\n}\n\n.ShopifySubscription__title {\n  text-align: center;\n}\n\n.ShopifySubscription__intro {\n  text-align: center;\n}\n\n.ShopifySubscription__plansList {\n  margin-top: 1rem;\n}\n\n.ShopifySubscription__plan {\n  border: 1px solid var(--border-color);\n  padding: 1rem;\n  border-radius: 0.4rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.ShopifySubscription__plan .ShopifySubscription__name {\n  font-size: 1.2rem;\n  font-weight: bold;\n}\n\n.ShopifySubscription__plan .ShopifySubscription__price .ShopifySubscription__amount {\n  font-size: 1.2rem;\n}\n\n.ShopifySubscription__plan .ShopifySubscription__price .ShopifySubscription__period {\n  color: var(--gray);\n}\n\n.ShopifySubscription__confirm {\n  padding: 1rem;\n  border: 1px solid var(--border-color);\n  border-radius: 0.4rem;\n  margin: 0 auto;\n}\n\n.ShopifySubscription__confirm .ShopifySubscription__method {\n  padding: 1rem;\n  background-color: var(--background-color);\n  border-radius: 0.4rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.ShopifySubscription__confirm .ShopifySubscription__controls {\n  text-align: center;\n}\n\n.ShopifySubscription__paymentMethod {\n  border: 1px solid var(--border-color);\n  padding: 1rem;\n  border-radius: 0.4rem;\n}\n\n.ShopifySubscription__error {\n    color: red;\n    margin-top: 1rem;\n    text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ShopifySubscription__root",
	"title": "ShopifySubscription__title",
	"intro": "ShopifySubscription__intro",
	"plansList": "ShopifySubscription__plansList",
	"plan": "ShopifySubscription__plan",
	"name": "ShopifySubscription__name",
	"price": "ShopifySubscription__price",
	"amount": "ShopifySubscription__amount",
	"period": "ShopifySubscription__period",
	"confirm": "ShopifySubscription__confirm",
	"method": "ShopifySubscription__method",
	"controls": "ShopifySubscription__controls",
	"paymentMethod": "ShopifySubscription__paymentMethod",
	"error": "ShopifySubscription__error"
};
module.exports = exports;
