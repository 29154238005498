// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Settings__label {\n    vertical-align: middle;\n    font-weight: normal;\n    display: inline;\n    margin-left: 10px;\n}\n\n.Settings__labelAbs {\n    display: block;\n}\n\n.Settings__setting {\n    margin-bottom: 1em;\n    position: relative;\n}\n\n.Settings__setting > div {\n    vertical-align: middle;\n}\n\n.Settings__setting > .form-control {\n    width: 5rem !important;\n    display: inline-block;\n}\n\n.Settings__input {\n    width: 4em;\n    vertical-align: middle;\n}\n", ""]);
// Exports
exports.locals = {
	"label": "Settings__label",
	"labelAbs": "Settings__labelAbs",
	"setting": "Settings__setting",
	"input": "Settings__input"
};
module.exports = exports;
