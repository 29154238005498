// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GoogleConnect__root {\n    display: inline-block;\n    margin: 40px auto;\n    text-align: center;\n    width: 100%;\n}\n\n.GoogleConnect__root a {\n    margin-bottom: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "GoogleConnect__root"
};
module.exports = exports;
