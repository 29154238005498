import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import cx from 'classnames'
import Toggle from 'react-toggle'

import styles from './Settings.css'
import TimeDeltaPicker from 'components/TimeDeltaPicker'
import { updateSettings } from 'actions/settings'

class SettingsView extends React.Component {

  static propTypes = {
    settings: PropTypes.object.isRequired
  }

  state = {
    randomizeOrder: false,
    loopNotifications: false,
    hideOnMobile: false,
    notificationCloseable: true,
    randomizeDelay: true,
    openLinkInNewWindow: false,
    timeAgo: 12,
    timeAgoUnit: 'hour',
    hideAgo: 12,
    hideAgoUnit: 'hour',
    initialPopupDelay: 6,
    maxNotificationsPerPage: 5,
    displayTime: 4,
    delayBetweenNotifications: 15,
    enabled: true,
    iframeMode: false,
    wixWidget: false,
  }

  componentWillMount() {
    if (this.props.settings.isUpdated) {
      this.setState(this.props.settings.item)
    }
  }

  handleSaveSettings = (evt) => {
    evt.preventDefault()
    this.props.dispatch(updateSettings({ data: this.state }))
  }

  handleTimeAgoChange = (data) => {
    this.setState({
      timeAgo: data.value,
      timeAgoUnit: data.unit
    })
  }

  handleHideAgoChange = (data) => {
    this.setState({
      hideAgo: data.value,
      hideAgoUnit: data.unit
    })
  }

  createToggleHandler = (stateVarName) => {
    return (evt) => {
      this.setState({ [stateVarName]: evt.target.checked ? true : false })
    }
  }

  createTextInputHandler = (stateVarName) => {
    return (evt) => {
      this.setState({ [stateVarName]: evt.target.value })
    }
  }

  render() {
    let textInputClassName = cx('form-control', styles.input)
    let btnClassName = cx('btn btn-primary', {
      'is-loading': this.props.settings.isUpdating
    })

    return (
        <div className={styles.root}>
          <h2>Settings</h2>

          <div className="columns">
            <div className="row">
            <div className="col-6">

              <h5>Basic display settings</h5>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.enabled}
                  onChange={this.createToggleHandler('enabled')}
                /> <span className={styles.label}>Enable notifications</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.hideOnMobile}
                  onChange={this.createToggleHandler('hideOnMobile')}
                /> <span className={styles.label}>Hide notifications on mobile devices</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.loopNotifications}
                  onChange={this.createToggleHandler('loopNotifications')}
                /> <span className={styles.label}>Continuously loop notifications</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.randomizeOrder}
                  onChange={this.createToggleHandler('randomizeOrder')}
                /> <span className={styles.label}>Randomise the order of notifications</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.notificationCloseable}
                  onChange={this.createToggleHandler('notificationCloseable')}
                /> <span className={styles.label}>Make notifications closeable</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.randomizeDelay}
                  onChange={this.createToggleHandler('randomizeDelay')}
                /> <span className={styles.label}>Randomise delay between notifications</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.openLinkInNewWindow}
                  onChange={this.createToggleHandler('openLinkInNewWindow')}
                /> <span className={styles.label}>Open notification links in new window</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.iframeMode}
                  onChange={this.createToggleHandler('iframeMode')}
                /> <span className={styles.label}>Store embedded within iframe</span>
              </div>

              <div className={styles.setting}>
                <Toggle
                  defaultChecked={this.state.wixWidget}
                  onChange={this.createToggleHandler('wixWidget')}
                /> <span className={styles.label}>Embedded as a Wix widget</span>
              </div>
            </div>

            <div className="col-6">
              <h5>Time ago text</h5>
              <div className={styles.setting}>
                <div className="message">
                  <div className="message-body">
                    <span className={cx(styles.labelAbs)}>
                      Show the "time ago" text for notifications created within this period
                    </span>
                    <TimeDeltaPicker
                      className={styles.input}
                      value={this.state.timeAgo}
                      unit={this.state.timeAgoUnit}
                      onChange={this.handleTimeAgoChange}
                    />
                  </div>
                </div>
              </div>

              <h5>Filtering</h5>

              <div className={styles.setting}>
                <div className="message is-success">
                  <div className="message-body">
                    <span className={cx(styles.labelAbs)}>
                      Only show notifications created within this period
                    </span>
                    <TimeDeltaPicker
                      className={styles.input}
                      value={this.state.hideAgo}
                      unit={this.state.hideAgoUnit}
                      onChange={this.handleHideAgoChange}
                    />
                  </div>
                </div>
              </div>

            </div>
            </div>


          </div>

          <h2 className="mt-5">Notification timings</h2>

            <div className={styles.setting}>
              <input
                className={textInputClassName}
                type="text"
                value={this.state.initialPopupDelay}
                onChange={this.createTextInputHandler('initialPopupDelay')}
              /> <span className={styles.label}>Initial delay before first notification is shown</span>
            </div>

            <div className={styles.setting}>
              <input
                className={textInputClassName}
                type="text"
                value={this.state.maxNotificationsPerPage}
                onChange={this.createTextInputHandler('maxNotificationsPerPage')}
              /> <span className={styles.label}>Max notifications allowed per page</span>
            </div>

            <div className={styles.setting}>
              <input
                className={textInputClassName}
                type="text"
                value={this.state.displayTime}
                onChange={this.createTextInputHandler('displayTime')}
              /> <span className={styles.label}>Show notifications for this amount of time</span>
            </div>

            <div className={styles.setting}>
              <input
                className={textInputClassName}
                type="text"
                value={this.state.delayBetweenNotifications}
                onChange={this.createTextInputHandler('delayBetweenNotifications')}
              /> <span className={styles.label}>The time between each notification. <i>If randomise delay is selected, we'll randomly add upto 2 seconds to this value.</i></span>
            </div>

            <button onClick={this.handleSaveSettings} className={btnClassName}>Save changes</button>
        </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.settings
  }
}

export default connect(mapStateToProps)(SettingsView)


