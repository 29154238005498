
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

// reducers
import { authState, auth } from './auth'
import { userState, user } from './user'
import { applicationsState, applications } from './applications'
import { notificationsState, notifications } from './notifications'
import { themesState, themes } from './themes'
import { subscriptionState, subscription } from './subscription'
import { settingsState, settings } from './settings'
import { analyticsState, analytics } from './analytics'
import { paymentState, payment } from './payment'
import { gaState, ga } from './ga'
import { embedState, embed } from './embed'
import { plansState, plans } from './plans'
import { invoicesState, invoices } from './invoices'
import { growlState, growl } from './growl'
import { systemState, system } from './system'
import { integrationsState, integrations } from './integrations'
import { integrationRequestState, integrationRequest } from './integration_request'
import { animationsState, animations } from './animations'
import { quotaState, quota } from './quota'
import { passwordResetState, passwordReset } from './passwordReset'

export const initialState = {
  system: systemState,
  auth: authState,
  user: userState,
  applications: applicationsState,
  notifications: notificationsState,
  themes: themesState,
  subscription: subscriptionState,
  settings: settingsState,
  analytics: analyticsState,
  payment: paymentState,
  ga: gaState,
  embed: embedState,
  plans: plansState,
  invoices: invoicesState,
  growl: growlState,
  integrations: integrationsState,
  integrationRequest: integrationRequestState,
  animations: animationsState,
  quota: quotaState,
  passwordReset: passwordResetState,
}

// root reducer
const reducers = {
  routing: routerReducer,
  auth: auth,
  user: user,
  applications: applications,
  notifications: notifications,
  themes: themes,
  settings: settings,
  analytics: analytics,
  embed: embed,
  subscription: subscription,
  payment: payment,
  invoices: invoices,
  plans: plans,
  ga: ga,
  growl: growl,
  system: system,
  integrationRequest: integrationRequest,
  integrations: integrations,
  animations: animations,
  quota: quota,
  passwordReset: passwordReset,
}

export default combineReducers(reducers)

