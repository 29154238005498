// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResetPassword__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.ResetPassword__content {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.ResetPassword__forgotPasswordContainer {\n    text-align: center;\n}\n\n.ResetPassword__forgotPassword {\n    padding-top: 1em;\n    font-size: small;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "ResetPassword__root",
	"content": "ResetPassword__content",
	"forgotPasswordContainer": "ResetPassword__forgotPasswordContainer",
	"forgotPassword": "ResetPassword__forgotPassword"
};
module.exports = exports;
