
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const CONNECT_GOOGLE_ANALYTICS = createRequestTypes('CONNECT_GOOGLE_ANALYTICS')
export const GET_GOOGLE_ACCOUNT = createRequestTypes('GET_GOOGLE_ACCOUNT')
export const UPDATE_GOOGLE_ACCOUNT = createRequestTypes('UPDATE_GOOGLE_ACCOUNT')

export const [
  connectGoogleAnalytics,
  connectGoogleAnalyticsSuccess,
  connectGoogleAnalyticsFail,
] = createAsyncRequestActions(CONNECT_GOOGLE_ANALYTICS)

export const [
  getGoogleAccount,
  getGoogleAccountSuccess,
  getGoogleAccountFail,
] = createAsyncRequestActions(GET_GOOGLE_ACCOUNT)

export const [
  updateGoogleAccount,
  updateGoogleAccountSuccess,
  updateGoogleAccountFail,
] = createAsyncRequestActions(UPDATE_GOOGLE_ACCOUNT)

