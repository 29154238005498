
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ColorSwatch.css'
import { SketchPicker } from 'react-color'

// background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,

export default class ColorSwatch extends React.Component {

  static propTypes = {
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    color: '#fff'
  }

  state = {
    displayColorPicker: false,
    color: this.props.color // hex colour code
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({ color: nextProps.color })
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange = (color) => {
    this.setState({ color: color.hex })
    this.props.onChange(color)
  }

  render() {

    let colorStyle = {
      background: `${this.state.color}`,
    }

    return (
      <div className={this.props.className}>
        <div className={ styles.swatch } onClick={ this.handleClick }>
          <div style={colorStyle} className={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div className={ styles.popover }>
          <div className={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
        </div> : null }
      </div>
    )
  }
}
