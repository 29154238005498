/**
 * All app data validation is here, all functions should return an object
 * in the format:
 *
 * {
 *  ok: false,
 *  errors: [
 *    {
 *      message: 'A title is required',
 *      path: 'title'
 *    }
 *  ]
 * }
 */

import validator from 'validator';

// -- custom validators

validator.isNotEmpty = (str) => {
  let s = validator.trim('' + str);
  if (s.length > 0) {
    return true;
  }

  return false;
}


// a sanity check on the year to make sure it is within a
// certain range
validator.isValidExpiry = (str, options) => {
  let s = validator.trim(str)
  if (s.length == 0) {
    return false
  }

  let parts = s.split('/')
  if (parts.length != 2) {
    return false
  }

  let month = parseInt(parts[0])
  let year = parseInt(parts[1])

  // make sure month and year are valid
  if (!(month > 0) || !(year > 0)) {
    return false
  }

  // two digit year, convert to 4 digits
  if (year.toString().length == 2) {
    year = 2000 + year
  }

  if (year < options.min) {
    return false
  }

  if (year > options.max) {
    return false
  }

  return true
}

// -- wrapper around the validator library to support a schema based
// approach to validating objects

const v = (validatorFunc, errorMessage, options, extraOptions) => {
  return (value) => {
    let result = null;

    // If it's an optional validator - ie if there's data we'll run normal validation
    // otherwise we'll skip validation entirely
    if (extraOptions) {
      if (extraOptions.optional === true && validator.isNull(validator.trim(value))) {
        return {
          error: false,
          message: null
        }
      }
    }

    // If options are supplied, we'll pass that to the actual validator
    if (options) {
      result = validatorFunc(value, options);
    } else {
      result = validatorFunc(value);
    }

    if (!result) {
      return {
        error: true,
        message: errorMessage
      }
    } else {
      return {
        error: false,
        message: null
      }
    }
  }
}

const validateSchema = (schema, data) => {
  let results = []

  for (let fieldName in schema) {
    let fieldErrors = [];

    // run all validators against the field
    for (let validatorFunc of schema[fieldName]) {
      let validationResult = validatorFunc(data[fieldName]);
      if (validationResult.error) {
        fieldErrors.push({
          message: validationResult.message
        });
      }
    }

    if (fieldErrors.length > 0) {
      results.push({
        attribute: fieldName,
        message: fieldErrors[0].message // only add the first message
      })
    }
  }

  return results;
}

const validateSchemaAndReturn = (schema, data) => {
  const errors = validateSchema(schema, data)
  return {
    ok: errors.length > 0 ? false : true,
    errors: errors
  }
}

// -- validations for different app components are below

export const validateUser = (data) => {
  const schema = {
    firstName: [
      v(validator.isNotEmpty, 'First name is required')
    ],
    lastName: [
      v(validator.isNotEmpty, 'Last name is required')
    ],
    emailAddress: [
      v(validator.isNotEmpty, 'Your email address is required'),
      v(validator.isEmail, 'This must be a valid email address')
    ],
    password: [
      v(validator.isNotEmpty, 'A password is required for your account'),
      v(validator.isLength, 'Your password needs to be at least 6 characters long', { min: 6 })
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

/**
 * Used to check that all fields have some sort of data in them
 *
 */
export const validateUserNotEmpty = (data) => {
  const schema = {
    firstName: [
      v(validator.isNotEmpty, 'First name is required')
    ],
    lastName: [
      v(validator.isNotEmpty, 'Last name is required')
    ],
    email: [
      v(validator.isNotEmpty, 'Your email address is required'),
    ],
    password: [
      v(validator.isNotEmpty, 'A password is required for your account'),
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateCreditCard = (data) => {
  const now = new Date()
  const year = now.getFullYear()
  const schema = {
    name: [
      v(validator.isNotEmpty, 'Please enter the name that is on the card'),
    ],
    number: [
      v(validator.isCreditCard, 'This does not look like a credit/debit card number'),
    ],
    cvc: [
      v(validator.isNumeric, 'CVC must be a number'),
      v(validator.isLength, 'Your CVC Security code (last few digits) must be 3 to 4 numbers long', { min: 3, max: 4 }),
      v(validator.isNotEmpty, 'CVC cannot be empty'),
    ],
    expiry: [
      v(validator.isNotEmpty, 'Expiry cannot be empty'),
      v(validator.isValidExpiry, 'Expiry date does not look valid', { min: year-1, max: year+50 })
    ],
  }

  return validateSchemaAndReturn(schema, data)
}

/**
 * Used to check that all fields have some sort of data in them
 *
 */
export const validateCreditCardNotEmpty = (data) => {
  const schema = {
    number: [
      v(validator.isNotEmpty, 'Number cannot be empty')
    ],
    cvc: [
      v(validator.isNotEmpty, 'CVC cannot be empty')
    ],
    month: [
      v(validator.isNotEmpty, 'Month cannot be empty')
    ],
    year: [
      v(validator.isNotEmpty, 'Year cannot be empty'),
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateForgotPassword = (data) => {
  const schema = {
    emailAddress: [
      v(validator.isNotEmpty, 'Email cannot be empty'),
      v(validator.isEmail, 'That does not look like an email address')
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateApplicationForm = (data) => {
  const schema = {
    name: [
      v(validator.isNotEmpty, 'Application name cannot be empty')
    ],
    url: [
      v(validator.isURL, 'Please enter a valid url for this application/website')
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateNotificationForm = (data) => {
  const schema = {
    message: [
      v(validator.isNotEmpty, 'The message for this notification cannot be empty')
    ],
    url: [
      v(validator.isURL, 'Please enter a valid url for this notification', null, { optional: true })
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateLogin = (data) => {
  const schema = {
    email: [
      v(validator.isNotEmpty, 'The email address cannot be empty'),
      v(validator.isEmail, 'This must be an email address'),
    ],
    password: [
      v(validator.isNotEmpty, 'Your password cannot be empty')
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateEmailEmbedCode = (data) => {
  const schema = {
    email: [
      v(validator.isNotEmpty, 'Email address cannot be empty'),
      v(validator.isEmail, 'This must be an email address')
    ]
  }

  return validateSchemaAndReturn(schema, data)
}

export const validateResetPassword = (data) => {
  const schema = {
    password: [
      v(validator.isNotEmpty, 'A password is required for your account'),
      v(validator.isLength, 'Your password needs to be at least 6 characters long', { min: 6 })
    ]
  }

  return validateSchemaAndReturn(schema, data)
}
