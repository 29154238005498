
import deepFreeze from 'deep-freeze'

import { SET_DEFAULT_APPLICATION } from 'actions/application'
import { UPDATE_SETTINGS } from 'actions/settings'
import { LOGOUT_USER } from 'actions/auth'

export const settingsState = {
  isUpdating: false,
  isUpdated: false,
  item: {}
}

export const settings = (state=settingsState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isUpdating: false,
        isUpdated: false,
        item: {},
      }
    }
    case SET_DEFAULT_APPLICATION: {
      return Object.assign({}, state, {
        isUpdated: true,
        item: action.args.application.settings
      })
    }
    case UPDATE_SETTINGS.REQUEST: {
      return Object.assign({}, state, {
        isUpdating: true,
        isUpdated: false
      })
    }
    case UPDATE_SETTINGS.SUCCESS: {
      return Object.assign({}, state, {
        isUpdated: true,
        isUpdating: false,
        item: action.response.data.settings
      })
    }

  }

  return state
}
