
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import styles from './SubscriptionCancelled.css'
import { Link } from 'react-router'
import { createShopifySubscription } from 'actions/subscription'

class SubscriptionCancelledView extends React.Component {

  static propTypes = {
    system: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    plans: PropTypes.object.isRequired,
  }

  handleStartShopifyTrial = (evt) => {
    evt.preventDefault()

    let plan = this.props.plans.items.find(plan => plan.gatewayUid == 'solo_monthly_usd')
    if (!plan) {
      return
    }

    let data = {
      plan: plan,
      trial: true,
    }

    this.props.dispatch(createShopifySubscription({ data, returnTo: '/' }))

  }

  renderShopifyTrialStart = () => {
    return (
      <div className={styles.root}>
        <h2 className="text-center mb-5">Start your 30 day trial</h2>
        <div className={styles.content}>
          <div className="box">
            <p>Hi { this.props.user.item.firstName },</p>
            <p>Welcome to Crowdlever, we've successfully setup your Shopify integration and you're ready to go! All that is left is to start your trial</p>
            <p>Click the buton below to start your <b>30 day free trial</b> of our Solo plan</p>
            <br/>
            <p><a className="btn btn-primary" onClick={this.handleStartShopifyTrial}>Begin my 30 day trial</a></p>
          </div>
        </div>
      </div>
    )
  }

  renderTrialEnded = () => {
  }

  renderSubscriptionCancelled = () => {
    return (
      <div className={styles.root}>
        <h2 className="text-center mb-5">Your account is paused</h2>
        <div className={styles.content}>
          <div className="box has-text-left">
            <p>Hi { this.props.user.item.firstName },</p>
            <p>It looks like your subscription has run out, but don't worry all your data is still intact.</p>
            <p>This might be because your free trial is over, or you cancelled your subscription</p>
            <p>Don't worry though, you can pick up where you left off my simply subscribing to one of our plans</p>
            <br/>
            <p><Link className="btn btn-primary btn-block" to={{ pathname: '/subscription', query: { returnTo: '/' }}}>Subscribe</Link></p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.system.flags.pendingShopifyTrial) {
      return this.renderShopifyTrialStart()
    }

    return this.renderSubscriptionCancelled()
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    applications: state.applications,
    system: state.system,
    user: state.user,
    subscription: state.subscription,
    payment: state.payment,
    plans: state.plans,
  }
}

export default connect(mapStateToProps)(SubscriptionCancelledView)

