
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import styles from './NotificationCopy.css'
import { createNotification, createImage, deleteImage } from 'actions/notification'
import { generateNewNotificationWorkspace } from 'actions/misc'
import { validateNotificationForm } from 'utils/validation'
import { guid } from 'utils/misc'
import NotificationForm from 'components/NotificationForm'

export class NotificationCopyView extends React.Component {

  static propTypes = {
    isSaving: PropTypes.bool,
    workspaces: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
  }

  state = {
    id: null,
    formErrors: null
  }

  componentDidMount = () => {
    let workspace = {
      id: guid()
    }

    if (this.props.notification && this.props.notification.image) {
      workspace.image = this.props.notification.image
    }

    this.props.dispatch(generateNewNotificationWorkspace(workspace))
    this.setState({ id: workspace.id })
  }

  handleImageUpload = (files) => {
    this.props.dispatch(createImage({
      workspaceId: this.state.id,
      file: files[0]
    }))
  }

  handleImageDelete = () => {
    this.props.dispatch(deleteImage({ workspaceId: this.state.id }))
  }

  handleFormSave = (evt, data) => {
    evt.preventDefault()
    const results = validateNotificationForm(data)
    if (!results.ok) {
      this.setState({ formErrors: results })
      console.log(results)
    } else {
      let image = this.props.workspaces[this.state.id]['image']
      if (image) {
        data['image'] = image
      }
      data['applicationId'] = this.props.application.id
      this.props.dispatch(createNotification({ data }))
    }
  }

  render() {
    // workspace may not be available on first render
    const workspace = this.props.workspaces[this.state.id]
    let isUploading = false
    let isUploaded = false

    if (workspace) {
      isUploading = workspace.isUploading
      isUploaded = (workspace.isUploaded || this.props.notification.image) ? true : false
    }

    return (
      <div className={styles.root}>
        <h2 className="title is-2">Copy notification</h2>
        <NotificationForm
          notification={this.props.notification}
          onSave={this.handleFormSave}
          validation={this.state.formErrors}
          onImageUpload={this.handleImageUpload}
          onImageDelete={this.handleImageDelete}
          isUploading={isUploading}
          isUploaded={isUploaded}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let nId = parseInt(ownProps.params.notificationId)
  let notification = state.notifications.items.find(n => n.id == nId)

  return {
    isSaving: state.notifications.isUpdating,
    workspaces: state.notifications.workspaces,
    application: state.applications.selected,
    notificationId: nId,
    notification: notification,
  }
}

export default connect(mapStateToProps)(NotificationCopyView)

