/**
 * Places used:
 *  - Side menu
 *  - WeeblySetupView
 *
 */
import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router'
import Select from 'react-select'
import styles from './ApplicationSelect.css'

export default class ApplicationSelect extends React.Component {

  static propTypes = {
    applications: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    mode: PropTypes.string,
  }

  static defaultProps = {
    mode: 'inplace'
  }

  state = {
    editing: false
  }

  getApplicationOptions = () => {
    let options = []
    for (let app of this.props.applications.items) {
      options.push({ value: app.id, label: app.name })
    }

    return options
  }

  handleApplicationChange = (option) => {
    let app = this.props.applications.items.find(a => (a.id == option.value))
    this.setState({ editing: false })
    this.props.onChange(app)
  }

  handleEditClick = (evt) => {
    this.setState({ editing: !this.state.editing })
  }

  renderEditableControl() {
    let selectedAppId = this.props.applications.selected ? this.props.applications.selected.id : null

    return (
      <Select
        name="form-field"
        value={selectedAppId}
        options={this.getApplicationOptions()}
        onChange={this.handleApplicationChange}
        autoBlur={true}
      />
    )
  }

  renderReadonlyView = () => {
    let name = this.props.applications.selected ? this.props.applications.selected.name : 'No application selected'
    return (
        <div className={styles.root}>
          <span className={styles.text}>{name}</span> <a href="#select-app" id={styles.change} onClick={this.handleEditClick}>(Switch)</a>
        </div>
    )
  }

  render() {
    if (this.state.editing || this.props.mode != 'inplace') {
      return this.renderEditableControl()
    } else {
      return this.renderReadonlyView()
    }
  }
}
