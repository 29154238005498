
import deepFreeze from 'deep-freeze'

import { GET_SUBSCRIPTION, CREATE_SUBSCRIPTION, CREATE_SHOPIFY_SUBSCRIPTION, CANCEL_SUBSCRIPTION } from 'actions/subscription'
import { LOGOUT_USER } from 'actions/auth'

export const subscriptionState = {
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  item: null,
}

export const subscription = (state=subscriptionState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetching: false,
        isFetched: false,
        isUpdating: false,
        item: null,
      }
    }

    // get subscription
    case GET_SUBSCRIPTION.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    }
    case GET_SUBSCRIPTION.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      })
    }
    case GET_SUBSCRIPTION.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        serverError: action.response.metadata,
      })
    }

    // create subscription
    case CREATE_SUBSCRIPTION.REQUEST: {
      return Object.assign({}, state, {
        isCreating: true,
        serverError: null,
      })
    }
    case CREATE_SUBSCRIPTION.SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false,
        serverError: null,
        item: action.response.data,
      })
    }
    case CREATE_SUBSCRIPTION.FAIL: {
      return Object.assign({}, state, {
        isCreating: false,
        serverError: action.response.metadata,
        item: null,
      })
    }

    // shopify
    case CREATE_SHOPIFY_SUBSCRIPTION.REQUEST: {
      return Object.assign({}, state, {
        isCreating: true,
        serverError: null,
      })
    }
    case CREATE_SHOPIFY_SUBSCRIPTION.SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false,
        serverError: null,
        item: action.response.data,
      })
    }
    case CREATE_SHOPIFY_SUBSCRIPTION.FAIL: {
      return Object.assign({}, state, {
        isCreating: false,
        serverError: action.response.metadata,
        item: null,
      })
    }

    // cancel subscription
    case CANCEL_SUBSCRIPTION.REQUEST: {
      return Object.assign({}, state, {
        isCancelling: true,
        serverError: null,
      })
    }
    case CANCEL_SUBSCRIPTION.SUCCESS: {
      let sub = null
      // this is because the subscription might be cancelled at the end of the period
      // setting a blank subscription means there is no active subscription
      if (action.response.data.status != 'canceled') {
        sub = action.response.data
      }
      return Object.assign({}, state, {
        isCancelling: false,
        item: sub,
      })
    }
    case CANCEL_SUBSCRIPTION.FAIL: {
      return Object.assign({}, state, {
        serverError: action.response.metadata,
      })
    }
  }

  return state
}
