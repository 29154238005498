// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.Login__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.Login__forgotPasswordContainer {\n    text-align: center;\n    margin-top: 1rem;\n}\n\n.Login__forgotPassword {\n    padding-top: 1em;\n}\n\n.Login__registerLink {\n}\n\n\n", ""]);
// Exports
exports.locals = {
	"root": "Login__root",
	"forgotPasswordContainer": "Login__forgotPasswordContainer",
	"forgotPassword": "Login__forgotPassword",
	"registerLink": "Login__registerLink"
};
module.exports = exports;
