// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.GAGoalSetup__root {\n}\n\n.GAGoalSetup__select {\n}\n\n.GAGoalSetup__selectContainer {\n    margin-bottom: 10px;\n}\n\n.GAGoalSetup__selectContainer > label {\n    font-weight: bold;\n}\n\n.GAGoalSetup__selectRow {\n}\n", ""]);
// Exports
exports.locals = {
	"root": "GAGoalSetup__root",
	"select": "GAGoalSetup__select",
	"selectContainer": "GAGoalSetup__selectContainer",
	"selectRow": "GAGoalSetup__selectRow"
};
module.exports = exports;
