// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.EcwidSetup__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.EcwidSetup__buttonRow {\n    margin-top: 20px;\n}\n\n.EcwidSetup__connect {\n    text-align: center;\n    margin-bottom: 10px;\n}\n\n.EcwidSetup__vendor {\n    width: 64px;\n    display: inline-block;\n}\n\n.EcwidSetup__arrow {\n    display: inline-block;\n    width: 64px;\n    margin-left: 10px;\n}\n\n.EcwidSetup__logo {\n    margin-left: 10px;\n    display: inline-block;\n    width: 64px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "EcwidSetup__root",
	"buttonRow": "EcwidSetup__buttonRow",
	"connect": "EcwidSetup__connect",
	"vendor": "EcwidSetup__vendor",
	"arrow": "EcwidSetup__arrow",
	"logo": "EcwidSetup__logo"
};
module.exports = exports;
