
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Loading.css'

export default class Loading extends React.Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }

  showComponentUpdate(nextProps, nextState) {
    if (this.props.loading != nextProps.loading) {
      return true
    }
    return false
  }

  render() {
    if (this.props.loading) {
      return (
        <div className={styles.root}>
          <div className={styles.spinner}>
            <div className={styles.rect1}></div>
            <div className={styles.rect2}></div>
            <div className={styles.rect3}></div>
            <div className={styles.rect4}></div>
            <div className={styles.rect5}></div>
          </div>
          { this.props.text ? <div className={styles.text}>{this.props.text}</div> : null }
        </div>
      )
    }

    return this.props.children
  }
}
