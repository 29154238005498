import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import Loading from 'components/Loading'
import styles from './IntegrationCreate.css'

import weeblyLogo from 'assets/weebly.png'
import shopifyLogo from 'assets/shopify.png'
import ecwidLogo from 'assets/ecwid.png'

const applications = {
  weebly: {
    kind: 'weebly',
    url: 'https://www.weebly.com/app-center/crowdlever',
    image: weeblyLogo,
    description: 'Connect to your Weebly store',
  },
  ecwid: {
    kind: 'ecwid',
    url: 'https://www.ecwid.com/apps/featured/crowdlever',
    image: ecwidLogo,
    description: 'Connect with your Ecwid store',
  },
  shopify: {
    kind: 'shopify',
    url: 'https://apps.shopify.com/crowdlever',
    image: shopifyLogo,
    description: 'Connect with your Shopify store'
  }
}

class IntegrationCreateView extends React.Component {

  static propTypes = {
    integrations: PropTypes.object.isRequired,
  }

  getIntegration = (kind) => {
    let integration = this.props.integrations.items.find(i => i.kind == kind)
    return integration
  }

  renderCard = (integrationDesc) => {
    let integration = this.getIntegration(integrationDesc.kind)
    return (
      <div className={styles.card}>
        <div className={styles.image}>
          <img src={integrationDesc.image}  />
        </div>
        <div className={styles.description}>
          {integrationDesc.description}
        </div>
        <footer className={styles.controls}>
          { integration ?
              <span><i className="fa fa-check"></i> Connected</span>
            : <a className="btn btn-primary" href={integrationDesc.url}>Connect</a>
          }
        </footer>
      </div>
    )
  }

  render() {
    if (!this.props.integrations.isFetched) {
      return (
        <Loading loading={true}>Loading integrations</Loading>
      )
    }
    return (
      <div className={styles.root}>
        <h2>Integrations</h2>
        <div className={styles.integrations}>
          {this.renderCard(applications.weebly)}
          {this.renderCard(applications.ecwid)}
          {this.renderCard(applications.shopify)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    integrations: state.integrations,
  }
}

export default connect(mapStateToProps)(IntegrationCreateView)

