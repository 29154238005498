
import PropTypes from 'prop-types';
import React from 'react';
import styles from './ApplicationForm.css'
import { renderErrorForField } from 'utils/misc'
import classNames from 'classnames'

export default class ApplicationForm extends React.Component {

  static propTypes = {
    buttonName: PropTypes.string,
    buttonClassName: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    validation: PropTypes.object,
    application: PropTypes.object,
  }

  static defaultProps = {
    buttonClassName: null,
    buttonName: 'Save',
    isSaving: false
  }

  state = {
    name: '',
    url: '',
    gaMedium: 'crowdlever',
    gaSource: 'crowdlever',
  }

  componentWillMount() {
    if (this.props.application) {
      this.setState({
        name: this.props.application.name,
        url: this.props.application.url,
        gaMedium: this.props.application.gaMedium || '',
        gaSource: this.props.application.gaSource || '',
      })
    }
  }

  handleSave = (evt) => {
    const data = {
      name: this.state.name,
      url: this.state.url,
      gaSource: this.state.gaSource,
      gaMedium: this.state.gaMedium,
    }

    this.props.onSave(evt, data)
  }

  handleNameChange = (evt) => {
    this.setState({ name: evt.target.value })
  }

  handleUrlChange = (evt) => {
    this.setState({ url: evt.target.value })
  }

  handleGaSourceChange = (evt) => {
    this.setState({ gaSource: evt.target.value })
  }

  handleGaMediumChange = (evt) => {
    this.setState({ gaMedium: evt.target.value })
  }

  render() {
    let btnClassName = classNames({
      'btn': true,
      'btn-primary': true,
      'is-loading': this.props.isSaving,
      [this.props.buttonClassName]: this.props.buttonClassName ? true : false,
    })

    return (
      <form className="form">
        <div className="form-group">
          <label className="label">Website name</label>
          <input onChange={this.handleNameChange} value={this.state.name} className="form-control" type="text" placeholder="My widget store" />
          {renderErrorForField('name', this.props.validation)}
        </div>
        <div className="form-group">
          <label className="label">URL to website</label>
          <input onChange={this.handleUrlChange} value={this.state.url} className="form-control" type="text" placeholder="http://www.example.com" />
          {renderErrorForField('url', this.props.validation)}
        </div>
        <div className="form-group">
          <label className="label">Campaign source <span className={styles.optional}>(Optional)</span></label>
          <input onChange={this.handleGaSourceChange} value={this.state.gaSource} className="form-control" type="text" placeholder="Google analytics source" />
          {renderErrorForField('gaSource', this.props.validation)}
          <span className="help">
            E.g. google, bing, newsletter, twitter
          </span>
        </div>
        <div className="form-group">
          <label className="label">Campaign medium <span className={styles.optional}>(Optional)</span></label>
          <input onChange={this.handleGaMediumChange} value={this.state.gaMedium} className="form-control" type="text" placeholder="Google analytics medium" />
          {renderErrorForField('gaMedium', this.props.validation)}
          <span className="help">
            E.g. crowdlever-popup, notification
          </span>
        </div>
        <div className="form-group">
          <button onClick={this.handleSave} className={btnClassName}>{this.props.buttonName}</button>
        </div>
      </form>
    )
  }
}

