
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { GET_THEMES, getThemesSuccess, getThemesFail } from 'actions/theme'
import { UPDATE_THEME, updateThemeSuccess, updateThemeFail } from 'actions/theme'
import { GET_ANIMATIONS, getAnimationsSuccess, getAnimationsFail } from 'actions/theme'
import { showGrowl } from 'actions/misc'

export function* getThemesSaga(action) {
  const state = yield select()
  const response = yield call(Api.getThemes, state.auth.authToken, action.args.application)

  if (response.metadata.success) {
    yield put(getThemesSuccess(response))
  } else {
    yield put(getThemesFail(response))
  }
}

export function* updateThemeSaga(action) {
  const state = yield select()
  const response = yield call(Api.updateTheme, state.auth.authToken, action.args.id, action.args.data)

  if (response.metadata.success) {
    yield put(updateThemeSuccess(response))
    yield put(showGrowl('success', 'Your theme has been updated'))
  } else {
    yield put(updateThemeFail(response))
  }
}

export function* getAnimationsSaga(action) {
  const state = yield select()
  const response = yield call(Api.getAnimations, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getAnimationsSuccess(response))
  } else {
    yield put(getAnimationsFail(response))
  }
}

export default {
  getThemes: () => takeLatest(GET_THEMES.REQUEST, getThemesSaga),
  updateTheme: () => takeLatest(UPDATE_THEME.REQUEST, updateThemeSaga),
  getAnimations: () => takeLatest(GET_ANIMATIONS.REQUEST, getAnimationsSaga),
}
