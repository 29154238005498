// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.ColorSwatch__color {\n  width: 36px;\n  height: 20px;\n  border-radius: 2px;\n}\n\n.ColorSwatch__swatch {\n  padding: 5px;\n  background: #fff;\n  border-radius: 1px;\n  box-shadow: rgba(0, 0, 0, 0.0980392) 0px 0px 0px 1px;\n  display: inline-block;\n  cursor: pointer;\n  height: 30px;\n  vertical-align: middle;\n}\n\n.ColorSwatch__popover {\n  position: absolute;\n  z-index: 2;\n}\n\n.ColorSwatch__cover {\n  position: fixed;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"color": "ColorSwatch__color",
	"swatch": "ColorSwatch__swatch",
	"popover": "ColorSwatch__popover",
	"cover": "ColorSwatch__cover"
};
module.exports = exports;
