// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loading__root {\n  margin: 100px auto;\n  width: 150px;\n}\n\n.Loading__text {\n    text-align: center;\n}\n\n.Loading__spinner {\n  margin: auto auto;\n  width: 50px;\n  height: 40px;\n  text-align: center;\n  font-size: 10px;\n}\n\n.Loading__spinner > div {\n  background-color: var(--brand-color);\n  height: 100%;\n  width: 6px;\n  display: inline-block;\n  margin-right: 2px;\n  \n  -webkit-animation: Loading__sk-stretchdelay 1.2s infinite ease-in-out;\n  animation: Loading__sk-stretchdelay 1.2s infinite ease-in-out;\n}\n\n.Loading__spinner .Loading__rect2 {\n  -webkit-animation-delay: -1.1s;\n  animation-delay: -1.1s;\n}\n\n.Loading__spinner .Loading__rect3 {\n  -webkit-animation-delay: -1.0s;\n  animation-delay: -1.0s;\n}\n\n.Loading__spinner .Loading__rect4 {\n  -webkit-animation-delay: -0.9s;\n  animation-delay: -0.9s;\n}\n\n.Loading__spinner .Loading__rect5 {\n  -webkit-animation-delay: -0.8s;\n  animation-delay: -0.8s;\n}\n\n@-webkit-keyframes Loading__sk-stretchdelay {\n  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  \n  20% { -webkit-transform: scaleY(1.0) }\n}\n\n@keyframes Loading__sk-stretchdelay {\n  0%, 40%, 100% { \n    transform: scaleY(0.4);\n    -webkit-transform: scaleY(0.4);\n  }  20% { \n    transform: scaleY(1.0);\n    -webkit-transform: scaleY(1.0);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Loading__root",
	"text": "Loading__text",
	"spinner": "Loading__spinner",
	"sk-stretchdelay": "Loading__sk-stretchdelay",
	"rect2": "Loading__rect2",
	"rect3": "Loading__rect3",
	"rect4": "Loading__rect4",
	"rect5": "Loading__rect5"
};
module.exports = exports;
