// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InvoiceRow__root {\n}\n\n.InvoiceRow__root > div {\n    display: inline-block;\n}\n\n.InvoiceRow__amount {\n    font-weight: bold;\n    text-align: right;\n}\n\n.InvoiceRow__period {\n    color: var(--dark-gray);\n}\n\n.InvoiceRow__description {\n}\n\n.InvoiceRow__description ul {\n    margin: 0;\n}\n\n.InvoiceRow__description ul li {\n    margin: 0;\n}\n\n.InvoiceRow__number {\n    color: var(--dark-gray);\n}", ""]);
// Exports
exports.locals = {
	"root": "InvoiceRow__root",
	"amount": "InvoiceRow__amount",
	"period": "InvoiceRow__period",
	"description": "InvoiceRow__description",
	"number": "InvoiceRow__number"
};
module.exports = exports;
