// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.Register__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.Register__content {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.Register__forgotPasswordContainer {\n    text-align: center;\n    margin-top: 1rem;\n}\n\n\n\n", ""]);
// Exports
exports.locals = {
	"root": "Register__root",
	"content": "Register__content",
	"forgotPasswordContainer": "Register__forgotPasswordContainer"
};
module.exports = exports;
