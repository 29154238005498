import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import styles from './InvoiceRow.css'
import { currencyToSymbol } from 'utils/misc'
import moment from 'moment'

const DATE_FORMAT = 'Do MMM YYYY'

export default class InvoiceRow extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired
  }

  getBillingPeriodText = () => {
    let start = this.props.invoice.lines[0].period.start
    let end = this.props.invoice.lines[0].period.end
    let startStr = moment(new Date(start*1000)).format(DATE_FORMAT)
    let endStr = moment(new Date(end*1000)).format(DATE_FORMAT)
    return `${startStr} - ${endStr}`
  }

  getInvoiceDownloadUrl = () => {
    let url = AppConfig.SERVER + `/api/v1.0/invoice/pdf/${this.props.invoice.id}/?Authorization=${this.props.auth.authToken}`
    return url
  }

  render() {
    let invoice = this.props.invoice
    let date = moment(new Date(invoice.createdAt*1000)).format(DATE_FORMAT)
    let symbol = currencyToSymbol(invoice.currency)
    return (
        <tr className={styles.root}>
          <td className={styles.date}>
            {date}
            <div className={styles.number}>Invoice No: <span>{invoice.number}</span></div>
          </td>
          <td className={styles.description}>
            <ul>
              {invoice.lines.map(line => <li>{line.description}</li>)}
            </ul>
            <div className={styles.period}>Period: {this.getBillingPeriodText()}</div>
          </td>
          <td>
            { invoice.paid
              ? <span><i className="far fa-check-circle" /> Paid</span>
              : invoice.amountDue > 0 && invoice.status !== 'void'
                ? <Link className="btn btn-sm" to={'/invoice/confirm/' + invoice.id}>Pay</Link>
                : invoice.status === 'void'
                  ? 'Void'
                  : null
            }
          </td>
          <td className={styles.amount}>{symbol}{invoice.amountDue.toFixed(2)}</td>
          <td className={styles.pdf}><a href={this.getInvoiceDownloadUrl()}><i className="far fa-file-pdf" /> PDF</a></td>
        </tr>
    )
  }
}
