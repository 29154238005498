
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn, take } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { UPDATE_EVENT_TEMPLATE, updateEventTemplateSuccess, updateEventTemplateFail } from 'actions/integrations'
import { GET_INTEGRATIONS, getIntegrationsSuccess, getIntegrationsFail } from 'actions/integrations'
import { UPDATE_ECWID_INTEGRATION, updateEcwidIntegrationSuccess, updateEcwidIntegrationFail } from 'actions/integrations'
import { showGrowl } from 'actions/misc'
import Api from 'utils/Api'

export function* updateEventTemplateSaga(action) {
  const state = yield select()
  const response = yield call(
    Api.updateEventTemplate,
    state.auth.authToken,
    action.args.integration,
    action.args.eventTemplate,
    action.args.updateExisting,
  )

  if (response.metadata.success) {
    yield put(updateEventTemplateSuccess(response))
    yield put(showGrowl('success', 'The event template was saved'))
  } else {
    yield put(updateEventTemplateFail(response))
    yield put(showGrowl('fail', 'There was an error, please check the template: ' + response.metadata.message))
  }
}

export function* getIntegrationsSaga(action) {
  const state = yield select()
  const response = yield call(Api.getIntegrations, state.auth.authToken, action.args.application)

  if (response.metadata.success) {
    yield put(getIntegrationsSuccess(response))
  } else {
    yield put(getIntegrationsFail(response))
  }
}

export function* updateEcwidIntegrationSaga(action) {
  const state = yield select()
  const response = yield call(Api.updateEcwidIntegration, state.auth.authToken, action.args.data)

  if (response.metadata.success) {
    yield put(updateEcwidIntegrationSuccess(response))
    yield put(showGrowl('success', 'Your store url has been updated'))
  } else {
    yield put(updateEcwidIntegrationFail(response))
  }
}

export default {
  updateEventTemplate: () => takeLatest(UPDATE_EVENT_TEMPLATE.REQUEST, updateEventTemplateSaga),
  getIntegrations: () => takeLatest(GET_INTEGRATIONS.REQUEST, getIntegrationsSaga),
  updateEcwidIntegration: () => takeLatest(UPDATE_ECWID_INTEGRATION.REQUEST, updateEcwidIntegrationSaga),
}
