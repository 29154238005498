import PropTypes from 'prop-types';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';
import styles from './DatePicker.css'

/* eslint-disable-next-line import/no-webpack-loader-syntax */
import '!style-loader!css-loader!react-day-picker/lib/style.css';

const DATE_FORMAT = 'DD/MM/YYYY'

export default class DatePicker extends React.Component {

  static propTypes = {
    date: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  state = {
    showOverlay: false,
    value: '',
    selectedDay: null,
  }

  componentWillMount() {
    document.addEventListener('click', this.closeHandler)

    let n = this.props.date
    if (!n) {
      n = new Date()
    }

    this.setState({
      value: moment(n).format(DATE_FORMAT),
      selectedDay: n,
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeHandler)
  }

  closeHandler = (evt) => {
    this.closeIfClickedOutside(evt)
  }

  /**
   * Close this component if the evt originates from outside it
   *
   */
  closeIfClickedOutside = (evt) => {
    if (!this.state.showOverlay) {
      return
    }

    let outsideOverlay = this.clickedOutsideElement(this.refs.overlayDiv, evt)
    let outsideInput = this.clickedOutsideElement(this.refs.input, evt)

    if (outsideOverlay && outsideInput) {
      this.setState({
        showOverlay: false
      });
    }
  }

  /**
   * Returns true if the event occurred outside the element
   *
   */
  clickedOutsideElement = (element, event) => {
    let eventTarget = (event.target) ? event.target : event.srcElement;

    if (element.contains(eventTarget)) {
      return false
    }

    return true
  }

  handleInputFocus = () => {
    this.setState({
      showOverlay: true,
    }, () => {
      this.refs.daypicker.showMonth(this.state.selectedDay ? this.state.selectedDay : new Date())
    });
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    const momentDay = moment(value, DATE_FORMAT, true);
    if (momentDay.isValid()) {
      this.setState({
        selectedDay: momentDay.toDate(),
        value,
      }, () => {
        this.refs.daypicker.showMonth(this.state.selectedDay);
      });
      this.props.onChange(momentDay.toDate())
    } else {
      this.setState({ value, selectedDay: null });
    }
  }

  handleDayClick = (e, day) => {
    this.setState({
      value: moment(day).format(DATE_FORMAT),
      selectedDay: day,
      showOverlay: false,
    });
    this.refs.input.blur();
    this.props.onChange(day)
  }

  render() {
    return (
      <div className={styles.root}>
        <input
          className="form-control"
          type="text"
          ref="input"
          placeholder="DD/MM/YYYY"
          value={ this.state.value }
          onChange={ this.handleInputChange }
          onFocus={ this.handleInputFocus }
        />
        { this.state.showOverlay &&
          <div style={ { position: 'relative' } }>
            <div className={styles.overlay} ref="overlayDiv">
              <DayPicker
                ref="daypicker"
                onDayClick={ this.handleDayClick }
                selectedDays={ day => DateUtils.isSameDay(this.state.selectedDay, day) }
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
