
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GENERATE_NOTIFICATION_WORKSPACE = 'GENERATE_NOTIFICATION_WORKSPACE'
export const INITIALIZE_APPLICATION = createRequestTypes('INITIALIZE_APPLICATION')
export const SEND_EMAIL = createRequestTypes('SEND_EMAIL')
export const GET_WORLD = createRequestTypes('GET_WORLD')

export const SHOW_GROWL = 'SHOW_GROWL'
export const HIDE_GROWL = 'HIDE_GROWL'
export const SET_FLAGS  = 'SET_FLAGS'

export const setFlags = (flags) => {
  return {
    type: SET_FLAGS,
    flags: flags,
  }
}

export const generateNewNotificationWorkspace = (workspace) => {
  return {
    type: GENERATE_NOTIFICATION_WORKSPACE,
    workspace: workspace,
  }
}

export const showGrowl = (type, message, timeout=2000) => {
  return {
    type: SHOW_GROWL,
    message: message,
    timeout: timeout,
  }
}

export const hideGrowl = () => {
  return {
    type: HIDE_GROWL,
  }
}

export const [
  initializeApplication,
  initializeApplicationSuccess,
  initializeApplicationFail,
] = createAsyncRequestActions(INITIALIZE_APPLICATION)

export const [
  sendEmail,
  sendEmailSuccess,
  sendEmailFail
] = createAsyncRequestActions(SEND_EMAIL)

export const [
  getWorld,
  getWorldSuccess,
  getWorldFail,
] = createAsyncRequestActions(GET_WORLD)


