
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { UPDATE_SETTINGS, updateSettingsSuccess, updateSettingsFail } from 'actions/settings'
import { showGrowl } from 'actions/misc'

export function* updateSettings(action) {
  const state = yield select()
  const appId = state.applications.selected.id
  const response = yield call(Api.updateSettings, state.auth.authToken, appId, action.args.data)

  if (response.metadata.success) {
    yield put(updateSettingsSuccess(response))
    yield put(showGrowl('success', 'Settings have been updated'))
  } else {
    yield put(updateSettingsFail(response))
  }
}

export default {
  updateSettings: () => takeLatest(UPDATE_SETTINGS.REQUEST, updateSettings),
}
