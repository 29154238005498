// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StripeSubscription__root {\n  max-width: 500px;\n  margin: 1rem auto;\n  padding: 1rem;\n}\n\n.StripeSubscription__root p {\n  margin-bottom: 1rem;\n}\n\n.StripeSubscription__title {\n  text-align: center;\n}\n\n.StripeSubscription__intro {\n  text-align: center;\n}\n\n.StripeSubscription__plansList {\n  margin-top: 1rem;\n}\n\n.StripeSubscription__plan {\n  border: 1px solid var(--border-color);\n  padding: 1rem;\n  border-radius: 0.4rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.StripeSubscription__plan .StripeSubscription__name {\n  font-size: 1.2rem;\n  font-weight: bold;\n}\n\n.StripeSubscription__plan .StripeSubscription__price .StripeSubscription__amount {\n  font-size: 1.2rem;\n}\n\n.StripeSubscription__plan .StripeSubscription__price .StripeSubscription__period {\n  color: var(--gray);\n}\n\n.StripeSubscription__confirm {\n  padding: 1rem;\n  border: 1px solid var(--border-color);\n  border-radius: 0.4rem;\n  margin: 0 auto;\n}\n\n.StripeSubscription__confirm .StripeSubscription__method {\n  padding: 1rem;\n  background-color: var(--background-color);\n  border-radius: 0.4rem;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.StripeSubscription__confirm .StripeSubscription__controls {\n  text-align: center;\n}\n\n.StripeSubscription__paymentMethod {\n  border: 1px solid var(--border-color);\n  padding: 1rem;\n  border-radius: 0.4rem;\n}\n\n.StripeSubscription__error {\n    color: red;\n    margin-top: 1rem;\n    text-align: center;\n}", ""]);
// Exports
exports.locals = {
	"root": "StripeSubscription__root",
	"title": "StripeSubscription__title",
	"intro": "StripeSubscription__intro",
	"plansList": "StripeSubscription__plansList",
	"plan": "StripeSubscription__plan",
	"name": "StripeSubscription__name",
	"price": "StripeSubscription__price",
	"amount": "StripeSubscription__amount",
	"period": "StripeSubscription__period",
	"confirm": "StripeSubscription__confirm",
	"method": "StripeSubscription__method",
	"controls": "StripeSubscription__controls",
	"paymentMethod": "StripeSubscription__paymentMethod",
	"error": "StripeSubscription__error"
};
module.exports = exports;
