import { call, put, fork, select } from 'redux-saga/effects'

import subscriptionSagas from './subscription'
import googleSagas from './google'
import analyticsSagas from './analytics'
import notificationSagas from './notification'
import applicationSagas from './application'
import userSagas from './user'
import themeSagas from './theme'
import settingsSaga from './settings'
import miscSagas from './misc'
import paymentSagas from './payment'
import integrationsSagas from './integrations'
import integrationRequestSagas from './integration_request'
import quotaSagas from './quota'
import authSagas from './auth'

export default function* root () {
  yield [

    // theme
    themeSagas.getThemes(),
    themeSagas.updateTheme(),

    // user
    userSagas.getUser(),
    userSagas.createUser(),
    userSagas.updateUser(),
    userSagas.loginUser(),
    userSagas.logoutUser(),
    userSagas.loginSSO(),

    // application
    applicationSagas.getApplications(),
    applicationSagas.createApplication(),
    applicationSagas.setDefaultApplication(),
    applicationSagas.updateApplication(),

    // notifications
    notificationSagas.getNotifications(),
    notificationSagas.createNotification(),
    notificationSagas.deleteNotification(),
    notificationSagas.updateNotification(),
    notificationSagas.createImage(),

    // subscriptions
    subscriptionSagas.createSubscription(),
    subscriptionSagas.getSubscription(),
    subscriptionSagas.getInvoices(),
    subscriptionSagas.getPlans(),
    subscriptionSagas.createShopifySubscription(),
    subscriptionSagas.cancelSubscription(),

    // google analytics
    googleSagas.connectGoogleAnalytics(),
    googleSagas.getGoogleAccount(),
    googleSagas.updateGoogleAccount(),

    // analytics
    analyticsSagas.getApplicationAnalytics(),
    analyticsSagas.getApplicationAnalyticsSummary(),

    // settings
    settingsSaga.updateSettings(),

    // misc,
    miscSagas.sendEmail(),
    miscSagas.initializeApplication(),
    miscSagas.showGrowl(),

    // credit card
    paymentSagas.getCreditCard(),
    paymentSagas.createCreditCard(),

    // integration requests
    integrationRequestSagas.getIntegrationRequest(),
    integrationRequestSagas.bindIntegration(),

    // integrations
    integrationsSagas.updateEventTemplate(),
    integrationsSagas.getIntegrations(),
    integrationsSagas.updateEcwidIntegration(),

    // quota
    quotaSagas.getQuota(),

    // auth
    authSagas.resetPassword(),
    authSagas.resetUpdatePassword(),
  ]
}

