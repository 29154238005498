import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { loginUser } from 'actions/auth'
import { push } from 'react-router-redux'
import LoginForm from 'components/LoginForm'
import { validateLogin } from 'utils/validation'
import styles from './Login.css'

export class LoginView extends React.Component {

  static propTypes = {
    loginError: PropTypes.object,
    loading: PropTypes.bool,
    auth: PropTypes.object,
  }

  state = {
    validationResult: null
  }

  componentWillMount() {
    if (this.props.auth.isAuthedViaCookie) {
      this.props.dispatch(push('/'))
    }
  }

  /**
   * Called when the login button is clicked
   *
   * @param {object} loginData The login creds that will be used
   *
   */
  handleLoginClick = (loginData) => {
    const validationResult = validateLogin(loginData)

    this.setState({
      validationResult: validationResult
    })

    if (validationResult.ok) {
      this.props.dispatch(loginUser({
        email: loginData.email,
        password: loginData.password,
        redirect: '/'
      }))
    }
  }

  render = () => {
    return (
      <div className={styles.root}>
        <h3 className="text-center mb-5">Login into your account</h3>
        <div className="box">
          <LoginForm
            validation={this.state.validationResult}
            serverError={this.props.loginError}
            onLoginClick={this.handleLoginClick}
            loading={this.props.loading}
          />
        </div>
        <div className={styles.forgotPasswordContainer}>
          <Link to={'/signup'} className={styles.registerLink}>Create an account</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loginError: state.auth.loginError,
    loading: state.auth.isLoggingIn || state.system.isLoading,
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(LoginView)

