import React from 'react'
import styles from './CreditCardForm.css'
import classNames from 'classnames'

import { injectStripe, CardElement } from 'react-stripe-elements'

const createOptions = () => {
  return {
    style: {
      base: {
        fontWeight: '400',
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
        fontSize: '16px',
        color: '#3b4351',
        '::placeholder': {
          color: '#bcc3ce',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

const CreditCardForm = ({ stripe, onSave, isSaving, apiErrorMessage, buttonText='Add credit card' }) => {
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [cardElement, setCardElement] = React.useState(null)
  const [name, setName] = React.useState('')

  const handleNameChange = evt => {
    setName(evt.target.value)
  }

  const handleChange = ({error}) => {
    if (error) {
      setErrorMessage(error.message)
    }
  }

  const handleReady = (element) => {
    setCardElement(element)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    setErrorMessage(null)

    if (!stripe) {
      return
    }

    onSave(cardElement, name)
  }
  const btnClass = classNames('btn', 'btn-primary', {
    'is-loading': isSaving
  })

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="label">Name</label>
          <input className="form-control" type="text" value={name} onChange={handleNameChange} placeholder='Name on the card' />
        </div>
        <div className="form-group">
          <label className="label">Card details</label>
          <div className="control">
            {stripe ? (
              <CardElement
                onReady={handleReady}
                onChange={handleChange}
                {...createOptions()}
              />
            ) : (
              <div className="StripeElement loading" />
            )}
          </div>
        </div>
        { errorMessage || apiErrorMessage 
          ? <div className={styles.error} role="alert">
              {errorMessage ? errorMessage : null}
              {apiErrorMessage ? apiErrorMessage : null}
            </div>
          : null
        }
        <div className={styles.controls}>
          <div>
            <button className={btnClass} disabled={!stripe || isSaving}><i className="far fa-fw fa-check" /> {buttonText}</button>
          </div>
          <div className={styles.secure}>
            <i className="fa fa-fw fa-lock" /> This website is secured with SSL
          </div>
        </div>
      </form>
    </div>
  )
}

export default injectStripe(CreditCardForm);
