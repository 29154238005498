import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { initializeApplication } from 'actions/misc'
import styles from './App.css'
import { showGrowl, hideGrowl } from 'actions/misc'
import Growl from 'components/Growl'
import icon from 'assets/icon.png'

class App extends React.Component {

  static propTypes = {
    growl: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    applications: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  componentWillMount() {
    let loginRoute = '/login'
    let authOptionalRoutes = [
      '/integration/weebly/setup',
      '/integration/ecwid/setup',
      '/integration/shopify/setup',
      '/login',
      '/signup',
      '/sso',
      '/forgot-password',
      '/reset',
    ]

    // redirect to login if we're unauthed within the main /app route and not in one of the optional auth
    // routes
    if (!this.props.auth.loggedIn && authOptionalRoutes.indexOf(this.props.location.pathname) == -1) {
      return this.props.dispatch(push(loginRoute))
    }

    // load up app data if we've got a saved auth token
    if (this.props.auth.isAuthedViaCookie) {
      this.props.dispatch(initializeApplication())
    }
  }

  handleGrowlClose = () => {
    this.props.dispatch(hideGrowl())
  }

  render() {
    return (
      <>
        <Helmet>
          <link rel="icon" type="image/png" href={icon} />
        </Helmet>
        <div className={styles.root}>
          { this.props.growl.isShowing ?
            <Growl
              onClose={this.handleGrowlClose}
              message={this.props.growl.message}
              type={this.props.growl.type}
              timeout={this.props.growl.timeout}
            />
            : null
          }
          {this.props.children}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    user: state.user,
    applications: state.applications,
    growl: state.growl,
    location: ownProps.location,
  }
}

export default connect(mapStateToProps)(App)
