
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_THEMES = createRequestTypes('GET_THEMES')
export const UPDATE_THEME = createRequestTypes('UPDATE_THEME')
export const GET_ANIMATIONS = createRequestTypes('GET_ANIMATIONS')

export const [
 getThemes,
 getThemesSuccess,
 getThemesFail
] = createAsyncRequestActions(GET_THEMES)

export const [
  updateTheme,
  updateThemeSuccess,
  updateThemeFail
] = createAsyncRequestActions(UPDATE_THEME)

export const [
  getAnimations,
  getAnimationsSuccess,
  getAnimationsFail,
] = createAsyncRequestActions(GET_ANIMATIONS)
