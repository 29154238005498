import PropTypes from 'prop-types';
import React from 'react';
import styles from './UserForm.css'
import { renderErrorForField } from 'utils/misc'
import cx from 'classnames'

export default class UserForm extends React.Component {

  static propTypes = {
    onRegisterClick: PropTypes.func.isRequired,
    validation: PropTypes.object,
    serverError: PropTypes.object,
    user: PropTypes.object,
    loading: PropTypes.bool,
  }

  state = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: ''
  }

  componentWillMount = () => {
    if (this.props.user) {
      this.setState({
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        emailAddress: this.props.user.emailAddress,
      })
    }
  }

  handleRegisterClick = (evt) => {
    this.props.onRegisterClick({
      emailAddress: this.state.emailAddress,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName
    })
  }

  handleFormSubmit = (evt) => {
    evt.preventDefault()
  }

  createInputHandler = (fieldName) => {
    return (evt) => {
      this.setState({ [fieldName]: evt.target.value })
    }
  }

  render() {
    let buttonClassName = cx('btn', 'btn-primary', 'btn-xl', 'btn-block', {
      'is-loading': this.props.loading,
    })

    return (
      <div className={styles.root}>
        <form className="form" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <label className="label">First name</label>
            <input value={this.state.firstName} onChange={this.createInputHandler('firstName')} className="form-control" placeholder="First name" />
            {renderErrorForField('firstName', this.props.validation)}
            {renderErrorForField('firstName', this.props.serverError)}
          </div>
          <div className="form-group">
            <label className="label">Last name</label>
            <input value={this.state.lastName} onChange={this.createInputHandler('lastName')} className="form-control" placeholder="Last name" />
            {renderErrorForField('lastName', this.props.validation)}
            {renderErrorForField('lastName', this.props.serverError)}
          </div>
          <div className="form-group">
            <label className="label">Email</label>
            <input value={this.state.emailAddress} onChange={this.createInputHandler('emailAddress')} className="form-control" type="email" placeholder="Email" />
            {renderErrorForField('emailAddress', this.props.validation)}
            {renderErrorForField('emailAddress', this.props.serverError)}
          </div>
          <div className="form-group">
            <label className="label">Password</label>
            <input value={this.state.password} onChange={this.createInputHandler('password')} className="form-control" type="password" placeholder="Your password" />
            {renderErrorForField('password', this.props.validation)}
            {renderErrorForField('password', this.props.serverError)}
          </div>
          <div className="form-group">
            <button className={buttonClassName} type="submit" onClick={this.handleRegisterClick}>Create my account</button>
          </div>
        </form>
      </div>
    )
  }
}

