import React from 'react'
import PropTypes from 'prop-types'
import styles from './InvoiceList.css'
import InvoiceRow from 'components/InvoiceRow'

export default class InvoiceList extends React.Component {
  static propTypes = {
    invoices: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
  }

  render() {
    if (this.props.invoices.length === 0) {
      return (
          <div className={styles.root}>
            <p className="content">You have no invoices</p>
          </div>
      )
    }

    let rows = this.props.invoices.map((invoice) => {
      return (
          <InvoiceRow key={invoice.id} invoice={invoice} auth={this.props.auth} />
      )
    })

    return (
        <div className={styles.root}>
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
    )
  }
}
