
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_APPLICATIONS   = createRequestTypes('GET_APPLICATIONS')
export const CREATE_APPLICATION = createRequestTypes('CREATE_APPLICATION')
export const GET_APPLICATION_ANALYTICS = createRequestTypes('GET_APPLICATION_ANALYTICS')
export const GET_APPLICATION_ANALYTICS_SUMMARY = createRequestTypes('GET_APPLICATION_ANALYTICS_SUMMARY')
export const SET_DEFAULT_APPLICATION = 'SET_DEFAULT_APPLICATION'
export const UPDATE_APPLICATION = createRequestTypes('UPDATE_APPLICATION')

export const [
  getApplicationAnalyticsSummary,
  getApplicationAnalyticsSummarySuccess,
  getApplicationAnalyticsSummaryFail,
] = createAsyncRequestActions(GET_APPLICATION_ANALYTICS_SUMMARY)

export const [
  createApplication,
  createApplicationSuccess,
  createApplicationFail
] = createAsyncRequestActions(CREATE_APPLICATION)

export const [
  getApplications,
  getApplicationsSuccess,
  getApplicationsFail
] = createAsyncRequestActions(GET_APPLICATIONS)

export const [
  getApplicationAnalytics,
  getApplicationAnalyticsSuccess,
  getApplicationAnalyticsFail
] = createAsyncRequestActions(GET_APPLICATION_ANALYTICS)

export const [
  updateApplication,
  updateApplicationSuccess,
  updateApplicationFail
] = createAsyncRequestActions(UPDATE_APPLICATION)

export const setDefaultApplication = (application) => {
  return {
    type: SET_DEFAULT_APPLICATION,
    args: {
      application: application
    }
  }
}

