import React from 'react'
import ModalContext from './ModalContext'

export const useModal = () => {
  const { setModal, modal, closeModal } = React.useContext(ModalContext)

  React.useEffect(() => {
    return () => closeModal()
  }, []) 

  return {
    setModal,
    modal,
    closeModal
  }
}
