import { UPDATE_EVENT_TEMPLATE, GET_INTEGRATIONS, UPDATE_ECWID_INTEGRATION } from 'actions/integrations'
import deepFreeze from 'deep-freeze'

export const integrationsState = {
  isFetching: false,
  isFetched: false,
  isUpdatingTemplate: false,
  isUpdatedTemplate: false,
  updateTemplateError: null,
  updateEcwidIntegrationError: null,
  items: [],
}

export const integrations = (state=integrationsState, action) => {
  switch (action.type) {

    // get integrations
    case GET_INTEGRATIONS.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
      })
    }
    case GET_INTEGRATIONS.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        items: action.response.data,
      })
    }
    case GET_INTEGRATIONS.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false
      })
    }

    // update event template
    case UPDATE_EVENT_TEMPLATE.REQUEST: {
      return Object.assign({}, state, {
        isUpdatingTemplate: true,
      })
    }
    case UPDATE_EVENT_TEMPLATE.SUCCESS: {
      deepFreeze(state)

      let items = state.items.map(integration => {
        let changeTemplate = integration.eventTemplates.find(t => t.id == action.response.data.id)
        if (changeTemplate) {
          return Object.assign({}, integration, {
            eventTemplates: [action.response.data],
          })
        }
        return integration
      })

      return Object.assign({}, state, {
        isUpdatedTemplate: true,
        isUpdatingTemplate: false,
        items: items
      })
    }
    case UPDATE_EVENT_TEMPLATE.FAIL: {
      return Object.assign({}, state, {
        updateTemplateError: action.response.metadata,
      })
    }

    // ecwid
    case UPDATE_ECWID_INTEGRATION.SUCCESS: {
      deepFreeze(state)
      let items = state.items.map(integration => {
        if (integration.kind == 'ecwid' && integration.ecwid.id == action.response.data.id) {
          return Object.assign({}, integration, {
            ecwid: action.response.data,
          })
        } else {
          return integration
        }
      })

      return Object.assign({}, state, {
        items: items,
        updateEcwidIntegrationError: null,
      })
    }
    case UPDATE_ECWID_INTEGRATION.FAIL: {
      return Object.assign({
        updateEcwidIntegrationError: action.response.metadata,
      })
    }

  }
  return state
}

