
import deepFreeze from 'deep-freeze'

import { LOGOUT_USER } from 'actions/auth'
import {
  GET_APPLICATIONS,
  CREATE_APPLICATION,
  SET_DEFAULT_APPLICATION,
  UPDATE_APPLICATION
} from 'actions/application'

export const applicationsState = {
  isFetched: false,
  isFetching: false,
  isCreating: false,
  selected: null,
  selectedId: null, // stored in cookies
  items: []
}

export const applications = (state=applicationsState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetched: false,
        isFetching: false,
        isCreating: false,
        selected: null,
        selectedId: null,
        items: [],
      }
    }
    case GET_APPLICATIONS.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        items: []
      })
    }
    case GET_APPLICATIONS.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        items: action.response.data
      })
    }
    case GET_APPLICATIONS.FAIL: {
      return Object.assign({}, state, {
        isFetched: false,
        isFetching: false,
        isFetchError: action.response
      })
    }
    case CREATE_APPLICATION.REQUEST: {
      return Object.assign({}, state, {
        isCreating: true
      })
    }
    case CREATE_APPLICATION.SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false
      })
    }
    case CREATE_APPLICATION.FAIL: {
      return Object.assign({}, state, {
        isCreating: false
      })
    }
    case SET_DEFAULT_APPLICATION: {
      return Object.assign({}, state, {
        selected: action.args.application,
        selectedId: action.args.application.id,
      })
    }
    case UPDATE_APPLICATION.REQUEST: {
      return Object.assign({}, state, {
        isUpdating: true,
        isUpdated: false,
      })
    }
    case UPDATE_APPLICATION.SUCCESS: {
      deepFreeze(state)
      let items = state.items.map(app => {
        if (app.id == action.response.data.id) {
          return action.response.data
        } else {
          return app
        }
      })

      return Object.assign({}, state, {
        isUpdated: true,
        isUpdating: false,
        items: items,
      })
    }
    case UPDATE_APPLICATION.FAIL: {
      return Object.assign({}, state, {
        isUpdating: false,
        isUpdated: false,
        updatedError: action.response.metadata,
      })
    }
  }

  return state
}
