import PropTypes from 'prop-types';
import React from 'react';
import { connectGoogleAnalytics } from 'actions/misc'
import { openPopup } from 'utils/popup'
import styles from './GoogleConnect.css'

export default class GoogleConnect extends React.Component {

  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    text: 'Connect to Google analytics',
  }

  handleClick = (evt) => {
    evt.preventDefault()

    // we have to open the popup here where it's done in response to the user
    // click (ie. during the processing of the onclick event) otherwise the
    // browser will block the popup
    let popup = openPopup('google', AppConfig.FRONTEND + '/oauth-loading/', 'Google')

    this.props.onClick(popup)
  }

  render() {
    return (
      <div className={styles.root}>
        <a className="button is-info is-large" href="#" onClick={this.handleClick}>
          <span className="icon">
            <i className="fa fa-plug"></i>
          </span> &nbsp; {this.props.text}
        </a>
        <p className="content">Get insights into conversion rates your for goals in google analytics</p>
      </div>
    )
  }
}
