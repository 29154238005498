
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router'
import UserForm from 'components/UserForm'
import { validateUser, validateUserNotEmpty } from 'utils/validation'
import { createUser } from 'actions/user'

import styles from './Register.css'

class RegisterView extends React.Component {

  static propTypes = {
    createError: PropTypes.object,
  }

  state = {
    isCreating: false,
    validation: null
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createError) {
      this.setState({ isCreating: false })
    }
  }

  handleRegister = (userData) => {
    const validationResult = validateUser(userData)

    if (validationResult.ok) {
      this.props.dispatch(createUser({ user: userData, redirect: '/applications/create' }))
      this.setState({ validation: null, isCreating: true })
    } else {
      this.setState({ validation: validationResult, isCreating: false })
    }
  }

  render = () => {
    return (
      <div className={styles.root}>
        <h3 className="text-center">Register new account</h3>
        <div className="text-center text-muted mb-5">Get up and running in seconds</div>
        <div className="box">
          <UserForm
            serverError={this.props.createError}
            validation={this.state.validation}
            onRegisterClick={this.handleRegister}
            loading={this.state.isCreating}
          />
        </div>
        <div className={styles.forgotPasswordContainer}>
          <Link to={'/login'}>Already have an account? Login here</Link>
        </div>
      </div>
    )

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createError: state.user.createError,
  }
}

export default connect(mapStateToProps)(RegisterView)

