
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_INTEGRATION_REQUEST = createRequestTypes('GET_INTEGRATION_REQUEST')
export const BIND_INTEGRATION = createRequestTypes('BIND_INTEGRATION')

export const [
  getIntegrationRequest,
  getIntegrationRequestSuccess,
  getIntegrationRequestFail,
] = createAsyncRequestActions(GET_INTEGRATION_REQUEST)

export const [
  bindIntegration,
  bindIntegrationSuccess,
  bindIntegrationFail,
] = createAsyncRequestActions(BIND_INTEGRATION)

