import React from 'react'
import styles from './Payment.css'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { push } from 'react-router-redux'

import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements'
import CreditCardForm from 'components/CreditCardForm'

import Api from 'utils/Api'
import { showGrowl } from 'actions/misc';
import { insertStripeJsLibrary } from 'utils/misc'


const PaymentView = ({ user, auth, dispatch }) => {
  const [stripe, setStripe] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isSaving, setIsSaving] = React.useState(false)

  React.useEffect(() => {
    insertStripeJsLibrary(setStripe)
  }, [])

  const handleSave = async (cardElement, name) => {
    let intent = null

    setIsSaving(true)

    // 1. create a SetupIntent with stripe
    const setupIntentResponse = await Api.createStripeSetupIntent(auth.authToken)
    if (!setupIntentResponse.metadata.success) {
      setErrorMessage('Unable to create a SetupIntent - please ping support@makerkit.co')
      return null
    } else {
      intent = setupIntentResponse.data
    }

    // 2. send card details to stripe using the SetupIntent above
    const cardSetupResponse = await stripe.handleCardSetup(
      intent.secret, cardElement, {
        payment_method_data: {
          billing_details: {
            name: name,
          }
        }
      }
    )

    if (cardSetupResponse.error) {
      setErrorMessage(cardSetupResponse.error.message)
      setIsSaving(false)
      return
    }

    // 3. create a new payment method on the backend using the SetupIntent above
    const response = await Api.attachStripePaymentMethod(auth.authToken, cardSetupResponse.setupIntent.payment_method)
   
    if (!response.metadata.success) {
      setErrorMessage('Unable to create payment method - please ping support@makerkit.co')
      setIsSaving(false)
      return
    }

    setIsSaving(false)

    dispatch(showGrowl('success', 'Added new payment method'))
    dispatch(push('/account/billing?tab=2'))
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2 className="text-center mb-5">Update payment method</h2>
        <div className="box">
          <StripeProvider stripe={stripe}>
            <Elements>
              <CreditCardForm
                apiErrorMessage={errorMessage}
                isSaving={isSaving}
                onSave={handleSave}
                buttonText='Update credit card'
              />
            </Elements>
          </StripeProvider>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    user: state.user.item,
    payment: state.payment,
  }
}

export default connect(mapStateToProps)(PaymentView)

