
import deepFreeze from 'deep-freeze'
import { INITIALIZE_APPLICATION, SET_FLAGS } from 'actions/misc'

export const systemState = {
  ready: false,
  isLoading: false,
  flags: {
  }
}

export const system = (state=systemState, action) => {
  switch (action.type) {
    case INITIALIZE_APPLICATION.REQUEST: {
      return Object.assign({}, state, {
        ready: false,
        isLoading: true,
      })
    }
    case INITIALIZE_APPLICATION.SUCCESS: {
      return Object.assign({}, state, {
        ready: true,
        isLoading: false,
      })
    }
    case INITIALIZE_APPLICATION.FAIL: {
      return state
    }
    case SET_FLAGS: {
      let newFlags = Object.assign({}, state.flags, action.flags)
      return Object.assign({}, state, {
        flags: newFlags,
      })
    }
  }

  return state
}

