
import PropTypes from 'prop-types';
import React from 'react';
import styles from './VisualThemeEditor.css'
import ThemePreview from 'components/ThemePreview'
import ThemeStyler from 'components/ThemeStyler'
import Handlebars from 'handlebars'

export default class VisualThemeEditor extends React.Component {

  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    borderWidth: PropTypes.number.isRequired,
    borderColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    linkColor: PropTypes.string.isRequired,
    showAnimation: PropTypes.string.isRequired,
    hideAnimation: PropTypes.string.isRequired,
    cssTemplate: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
    animations: PropTypes.array.isRequired,
  }

  getCss = () => {
    let template = Handlebars.compile(this.props.cssTemplate)
    let variables = {
      backgroundColor: this.props.backgroundColor,
      borderWidth: this.props.borderWidth,
      borderColor: this.props.borderColor,
      textColor: this.props.textColor,
      linkColor: this.props.linkColor
    }

    return template(variables)
  }

  handleSave = (evt) => {
    this.props.onSave(evt, this.getCss())
  }

  render() {
    return (
      <div className={styles.root}>

        <ThemePreview
          html={this.props.html}
          css={this.getCss()}
          position={this.props.position}
          size={this.props.size}
        />

        <ThemeStyler
          size={this.props.size}
          position={this.props.position}
          backgroundColor={this.props.backgroundColor}
          borderWidth={this.props.borderWidth}
          borderColor={this.props.borderColor}
          textColor={this.props.textColor}
          linkColor={this.props.linkColor}
          showAnimation={this.props.showAnimation}
          hideAnimation={this.props.hideAnimation}
          animations={this.props.animations}
          onChange={this.props.onChange}
          onSave={this.handleSave}
        />

      </div>
    )
  }
}
