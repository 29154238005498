
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { GET_APPLICATION_ANALYTICS, getApplicationAnalyticsSuccess, getApplicationAnalyticsFail } from 'actions/application'
import { GET_APPLICATION_ANALYTICS_SUMMARY, getApplicationAnalyticsSummarySuccess, getApplicationAnalyticsSummaryFail } from 'actions/application'

export function* getApplicationAnalyticsSummarySaga(action) {
  const state = yield select()
  const response = yield call(Api.getApplicationAnalyticsSummary, state.auth.authToken, action.args.application)

  if (response.metadata.success) {
    yield put(getApplicationAnalyticsSummarySuccess(response))
  } else {
    yield put(getApplicationAnalyticsSummaryFail(response))
  }
}

export function* getApplicationAnalyticsSaga(action) {
  const state = yield select()
  const response = yield call(Api.getApplicationAnalytics, state.auth.authToken, action.args.application)

  if (response.metadata.success) {
    yield put(getApplicationAnalyticsSuccess(response))
  } else {
    yield put(getApplicationAnalyticsFail(response))
  }
}

export default {
  getApplicationAnalytics: () => takeLatest(GET_APPLICATION_ANALYTICS.REQUEST, getApplicationAnalyticsSaga),
  getApplicationAnalyticsSummary: () => takeLatest(GET_APPLICATION_ANALYTICS_SUMMARY.REQUEST, getApplicationAnalyticsSummarySaga),
}

