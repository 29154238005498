// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.Dashboard__root {\n}\n\n.Dashboard__summaryContainer {\n    margin-top: 2em;\n}\n\n.Dashboard__summaryContainer .level {\n    padding: 1em;\n}\n\n\n.Dashboard__goalsSetupContainer {\n    margin-top: 2em;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Dashboard__root",
	"summaryContainer": "Dashboard__summaryContainer",
	"goalsSetupContainer": "Dashboard__goalsSetupContainer"
};
module.exports = exports;
