// this is for integration requests

import { GET_INTEGRATION_REQUEST } from 'actions/integration_request'

export const integrationRequestState = {
  isFetching: false,
  isFetched: false,
  item: {},
}

export const integrationRequest = (state=integrationRequestState, action) => {
  switch (action.type) {
    case GET_INTEGRATION_REQUEST.REQUEST: {
      return {
        isFetching: true
      }
    }
    case GET_INTEGRATION_REQUEST.SUCCESS: {
      return {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      }
    }
    case GET_INTEGRATION_REQUEST.FAIL: {
      return {
        isFetching: false,
        isFetched: false,
        serverError: action.response.metadata,
      }
    }
  }
  return state
}
