
import PropTypes from 'prop-types';
import React from 'react';
import styles from './AnalyticsGraph.css'
import Chart from 'chart.js'

export default class AnalyticsGraph extends React.Component {

  static propTypes = {
    data: PropTypes.object
  }

  componentDidMount = () => {
    this.chart = this.createChart(this.props)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.data.createdAt == this.props.data.createdAt) {
      return false
    }
    return true
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (this.chart) {
      this.chart.destroy()
    }
    this.chart = this.createChart(nextProps)
  }

  createChart = (props) => {
    let ctx = this.refs.chart
    // chart.js screws around the with data, so we deep clone the chart data
    // to prevent the redux state from being fucked with
    let chartData = JSON.parse(JSON.stringify(props.data))
    let chart = new Chart(ctx, {
      type: 'line',
      data: chartData,
      options: {
        title: { display: false },
        scales: {
          xAxes: [{
            type: 'time',
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
            }
         }]
        }
      }
    })

    return chart
  }

  render() {
    return (
      <div className={styles.root}>
        <canvas ref="chart">
        </canvas>
      </div>
    )
  }
}
