// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ThemeStyler__root {\n    position: absolute;\n    right: 0;\n    top: 0;\n    background-color: #434A54;\n    border-left: 1px solid #656D78;\n    color: #fff;\n    padding: 5px;\n    height: 100%;\n}\n\n.ThemeStyler__save {\n    margin-top: 15px;\n}\n\n.ThemeStyler__control {\n    position: relative;\n    margin-bottom: 5px;\n}\n\n.ThemeStyler__control > label {\n}\n\n.ThemeStyler__content input[type=\"text\"] {\n    width: 90px;\n    padding: 5px;\n    display: inline-block;\n    vertical-align: middle;\n}\n\n.ThemeStyler__richControl {\n    display: inline-block;\n    vertical-align: middle;\n    width: 100px;\n    margin-left: 10px;\n}\n\n.ThemeStyler__control label.radio:hover {\n    color: #fff !important;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ThemeStyler__root",
	"save": "ThemeStyler__save",
	"control": "ThemeStyler__control",
	"content": "ThemeStyler__content",
	"richControl": "ThemeStyler__richControl"
};
module.exports = exports;
