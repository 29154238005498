
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const UPDATE_SETTINGS = createRequestTypes('UPDATE_SETTINGS')

export const [
  updateSettings,
  updateSettingsSuccess,
  UpdateSettingsFail
] = createAsyncRequestActions(UPDATE_SETTINGS)

