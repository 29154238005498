import React from 'react'
import styles from './Help.css'
import { connect } from 'react-redux'

export class HelpView extends React.Component {
  render() {
    return (
      <div className={styles.root}>
        <h3 className="title is-3">Help</h3>
        <p>If you need any help in setting up your account, or questions about Crowdlever please email
          us at <a href="mailto:support@crowdlever.io">support@crowdlever.io</a></p>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps)(HelpView)
