
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ApplicationForm from 'components/ApplicationForm'
import styles from './ApplicationCreate.css'
import { createApplication } from 'actions/application'
import { validateApplicationForm } from 'utils/validation'

export class ApplicationCreateView extends React.Component {

  state = {
    formErrors: null
  }

  handleFormSave = (evt, data) => {
    evt.preventDefault()
    const results = validateApplicationForm(data)
    if (!results.ok) {
      this.setState({ formErrors: results })
    } else {
      this.props.dispatch(createApplication({
        data: data,
        redirect: '/',
        growl: 'New website has been created',
      }))
    }
  }

  render() {
    return (
      <div className={styles.root}>
        <h2 className="title is-2">Create new website</h2>
        <p className="content">
          Tell us about the website where your store is hosted
        </p>
        <ApplicationForm validation={this.state.formErrors} onSave={this.handleFormSave} isSaving={this.props.isSaving} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSaving: state.applications.isCreating
  }
}

export default connect(mapStateToProps)(ApplicationCreateView)

