
import { GET_INVOICES } from 'actions/subscription'
import { LOGOUT_USER } from 'actions/auth'

export const invoicesState = {
  isFetching: false,
  isFetched: false,
  items: [],
}

export const invoices = (state=invoicesState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetching: false,
        isFetched: false,
        items: [],
      }
    }
    case GET_INVOICES.REQUEST: {
      return Object.assign({
        isFetching: true,
        isFetched: false,
        items: [],
        serverError: null,
      })
    }
    case GET_INVOICES.SUCCESS: {
      return Object.assign({
        isFetching: false,
        isFetched: true,
        items: action.response.data,
        serverError: null,
      })
    }
    case GET_INVOICES.FAIL: {
      return Object.assign({
        isFetching: false,
        isFetched: false,
        items: [],
        serverError: action.response.metadata,
      })
    }
  }
  return state
}
