
import Cookies from 'cookies-js'
import Api from 'utils/Api'
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_USER = createRequestTypes('GET_USER')
export const UPDATE_USER = createRequestTypes('UPDATE_USER')
export const CREATE_USER = createRequestTypes('CREATE_USER')

export const [
  updateUser,
  updateUserSuccess,
  updateUserFail
] = createAsyncRequestActions(UPDATE_USER)

export const [
  createUser,
  createUserSuccess,
  createUserFail
] = createAsyncRequestActions(CREATE_USER)

export const [
  getUser,
  getUserSuccess,
  getUserFail,
] = createAsyncRequestActions(GET_USER)


