import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { renderErrorForField } from 'utils/misc'
import { resetUpdatePassword } from 'actions/auth'
import { validateResetPassword } from 'utils/validation'
import cx from 'classnames'
import styles from './ResetPassword.css'

export class ResetPasswordView extends React.Component {

  static propTypes = {
    passwordReset: PropTypes.object.isRequired,
    serverError: PropTypes.object,
    location: PropTypes.object,
  }

  state = {
    password: '',
    validation: null,
  }

  handleSubmit = (evt) => {
    evt.preventDefault()

    let data = {
      password: this.state.password,
      token: this.props.location.query.token,
      redirect: '/'
    }

    let validation = validateResetPassword(data)
    if (validation.ok) {
      this.props.dispatch(resetUpdatePassword(data))
    } else {
      this.setState({
        validation: validation,
      })
    }
  }

  handlePasswordChange = (evt) => {
    this.setState({
      password: evt.target.value
    })
  }

  render = () => {
    const btnClassName = cx('btn', 'btn-primary', 'btn-xl', 'btn-block', {
      'is-loading': this.props.passwordReset.isRequesting
    })

    return (
      <div className={styles.root}>
        <h3 className="text-center mb-5">Change your password</h3>
        <div className="box">
          <div className="content">
            <p>Enter your new password below, is must be <b>at least 6 characters long</b></p>
          </div>
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label className="label">New password</label>
              <input value={this.state.password} onChange={this.handlePasswordChange} className="form-control" type="password" placeholder="Your new password" />
              {renderErrorForField('password', this.state.validation)}
              {renderErrorForField('password', this.props.serverError)}
              {renderErrorForField('api_error', this.props.serverError)}
            </div>
            <div className="form-group">
              <button className={btnClassName} type="submit" onClick={this.handleSubmit}>Reset password</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    passwordReset: state.passwordReset,
    location: ownProps.location,
    serverError: state.passwordReset.serverError,
  }
}

export default connect(mapStateToProps)(ResetPasswordView)

