// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.EmbedCode__root {\n}\n\n.EmbedCode__code {\n    margin-bottom: 20px;\n}\n\n.EmbedCode__noSetupRequired {\n    padding: 3em;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "EmbedCode__root",
	"code": "EmbedCode__code",
	"noSetupRequired": "EmbedCode__noSetupRequired"
};
module.exports = exports;
