import PropTypes from 'prop-types';
import React from 'react';
import { pad } from 'utils/misc'
import styles from './TimePicker.css'
import fecha from 'fecha'
import { parseTime } from 'utils/time'

export default class TimePicker extends React.Component {

  static propTypes = {
    date: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    placeholder: '12:30 PM'
  }

  state = {
    value: ''
  }

  componentWillMount() {
    this.setState({
      value: fecha.format(this.props.date, 'HH:mm')
    })
  }

  parseTimeText = (value) => {
    return parseTime(value)
  }

  handleInputChange = (evt) => {
    let { hour, minute } = this.parseTimeText(evt.target.value)
    this.setState({ value: evt.target.value })
    this.props.onChange(hour, minute)
  }

  render() {
    return (
        <div className={styles.root}>
          <input
            className="form-control"
            type="text"
            value={this.state.value}
            placeholder={this.props.placeholder}
            onChange={this.handleInputChange}
          />
        </div>
    )
  }
}
