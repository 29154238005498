/* eslint-disable-next-line import/no-webpack-loader-syntax */
import '!style-loader!css-loader!rc-slider/assets/index.css';

import React from 'react';
import styles from './ThemeStyler.css'
import ColorSwatch from 'components/ColorSwatch'
import PropTypes from 'prop-types';

import Slider from 'rc-slider'

export default class ThemeStyler extends React.Component {

  static propTypes = {
    size: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    borderWidth: PropTypes.number,
    borderColor: PropTypes.string,
    textColor: PropTypes.string,
    linkColor: PropTypes.string,
    showAnimation: PropTypes.string,
    hideAnimation: PropTypes.string,
    animations: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  handleBackgroundColorChange = (color) => {
    this.props.onChange({ backgroundColor: color.hex })
  }

  handleBorderWidthChange = (width) => {
    // clamp value
    if (width < 0) {
      width = 0;
    }
    if (width > 10) {
      width = 10
    }
    this.props.onChange({ borderWidth: width })
  }

  handleBorderColorChange = (color) => {
    this.props.onChange({ borderColor: color.hex })
  }

  handleTextColorChange = (color) => {
    this.props.onChange({ textColor: color.hex })
  }

  handleLinkColorChange = (color) => {
    this.props.onChange({ linkColor: color.hex })
  }

  handleSizeChange = (evt, size) => {
    this.props.onChange({ size: size })
  }

  handlePositionChange = (evt, position) => {
    this.props.onChange({ position: position })
  }

  handleShowAnimationChange = (evt) => {
    this.props.onChange({ showAnimation: evt.target.value })
  }

  handleHideAnimationChange = (evt) => {
    this.props.onChange({ hideAnimation: evt.target.value })
  }

  getAnimationOptions = (kind) => {
    let animations = this.props.animations.filter(i => i.kind == kind)
    let options = []
    for (let animation of animations) {
      options.push(
        <option key={animation.name} value={animation.class}>{animation.name}</option>
      )
    }
    return options
  }

  render() {
    return (
        <div className={styles.root}>
              <div className={styles.control}>
                <label>Background color</label>
                <div className={styles.content}>
                  <input type="text" value={this.props.backgroundColor} />
                  <ColorSwatch className={styles.richControl} color={this.props.backgroundColor} onChange={this.handleBackgroundColorChange} />
                </div>
              </div>

              <div className={styles.control}>
                <label>Border width</label>
                <div className={styles.content}>
                  <input type="text" value={this.props.borderWidth} onChange={(evt) => this.handleBorderWidthChange(evt.target.value)} />
                  <Slider className={styles.richControl} min={0} max={10} value={this.props.borderWidth} onChange={this.handleBorderWidthChange} />
                </div>
              </div>

              <div className={styles.control}>
                <label>Border color</label>
                <div className={styles.content}>
                  <input type="text" value={this.props.borderColor} />
                  <ColorSwatch className={styles.richControl} color={this.props.borderColor} onChange={this.handleBorderColorChange} />
                </div>
              </div>
              <div className={styles.control}>
                <label>Text color</label>
                <div className={styles.content}>
                  <input type="text" value={this.props.textColor} />
                  <ColorSwatch className={styles.richControl} color={this.props.textColor} onChange={this.handleTextColorChange} />
                </div>
              </div>

              <div className={styles.control}>
                <label>Link color</label>
                <div className={styles.content}>
                  <input type="text" value={this.props.linkColor} />
                  <ColorSwatch className={styles.richControl} color={this.props.linkColor} onChange={this.handleLinkColorChange} />
                </div>
              </div>

              <div className={styles.control}>
                <label>Size</label>
                <p className="control">
                  <label className="radio">
                    <input type="radio" name="size" checked={this.props.size == 'normal'} onChange={(evt) => this.handleSizeChange(evt, 'normal')} /> Normal
                  </label>

                  <label className="radio">
                    <input type="radio" name="size" checked={this.props.size == 'large'} onChange={(evt) => this.handleSizeChange(evt, 'large')} /> Large
                  </label>
                </p>
              </div>

              <div className={styles.control}>
                <label>Position</label>
                <p className="control">
                  <label className="radio">
                    <input type="radio" name="position" checked={this.props.position == 'left'} onChange={(evt) => this.handlePositionChange(evt, 'left')} /> Left
                  </label>
                  <label className="radio">
                    <input type="radio" name="position" checked={this.props.position == 'right'} onChange={(evt) => this.handlePositionChange(evt, 'right')} /> Right
                  </label>
                </p>
              </div>

              <div className={styles.control}>
                <label>Show animation</label>
                <p className="control">
                   <span className="select">
                      <select onChange={this.handleShowAnimationChange} value={this.props.showAnimation}>
                        {this.getAnimationOptions('show')}
                      </select>
                    </span>
                </p>
              </div>

              <div className={styles.control}>
                <label>Hide animation</label>
                <p className="control">
                   <span className="select">
                      <select onChange={this.handleHideAnimationChange} value={this.props.hideAnimation}>
                        {this.getAnimationOptions('hide')}
                      </select>
                    </span>
                </p>
              </div>

              <div className={styles.save}>
                <a onClick={this.props.onSave} className="btn btn-primary">Save</a>
              </div>
        </div>
    )
  }

}
