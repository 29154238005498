
import Cookies from 'cookies-js'
import Api from 'utils/Api'
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_QUOTA = createRequestTypes('GET_QUOTA')

export const [
  getQuota,
  getQuotaSuccess,
  getQuotaFail,
] = createAsyncRequestActions(GET_QUOTA)

