import PropTypes from 'prop-types';
import React from 'react';
import styles from './Modal.css'

export default class Modal extends React.Component {

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
