// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InvoiceConfirmView__root {\n}\n\n.InvoiceConfirmView__invoiceContainer {\n  border: 1px solid var(--border-color);\n  width: 600px;  \n  border-radius: 0.4rem;\n  margin: auto;\n}\n\n.InvoiceConfirmView__confirm {\n  text-align: center;\n  padding: 1rem;\n  background-color: var(--background-color);\n}\n\n.InvoiceConfirmView__confirm .InvoiceConfirmView__title {\n  font-size: 1.2rem;\n  margin-bottom: 1rem;\n}\n\n.InvoiceConfirmView__paymentMethod {\n  padding: 1rem;\n  background-color: var(--background-color);\n}\n\n.InvoiceConfirmView__paymentMethod .InvoiceConfirmView__title {\n  font-size: 1.2rem;\n  margin-bottom: 1rem;\n}\n\n.InvoiceConfirmView__invoice {\n  padding: 1rem;\n}\n\n.InvoiceConfirmView__itemsHeader {\n  font-weight: bold;\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 0.5rem;\n  border-bottom: 1px solid var(--border-color);\n  margin-bottom: 0.5rem;\n}\n\n.InvoiceConfirmView__item {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.5rem;\n}\n\n.InvoiceConfirmView__totals {\n  margin-top: 1rem;\n}\n\n.InvoiceConfirmView__due, .InvoiceConfirmView__total, .InvoiceConfirmView__balance {\n  text-align: right;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.InvoiceConfirmView__due {\n  font-weight: bold;\n}\n\n.InvoiceConfirmView__value {\n  width: 100px;\n}\n\n.InvoiceConfirmView__paid {\n  font-size: 1rem;\n  text-align: center;\n  margin: 0 0 1rem 0;\n  color: green;\n}\n\n.InvoiceConfirmView__error {\n  color: red;\n}", ""]);
// Exports
exports.locals = {
	"root": "InvoiceConfirmView__root",
	"invoiceContainer": "InvoiceConfirmView__invoiceContainer",
	"confirm": "InvoiceConfirmView__confirm",
	"title": "InvoiceConfirmView__title",
	"paymentMethod": "InvoiceConfirmView__paymentMethod",
	"invoice": "InvoiceConfirmView__invoice",
	"itemsHeader": "InvoiceConfirmView__itemsHeader",
	"item": "InvoiceConfirmView__item",
	"totals": "InvoiceConfirmView__totals",
	"due": "InvoiceConfirmView__due",
	"total": "InvoiceConfirmView__total",
	"balance": "InvoiceConfirmView__balance",
	"value": "InvoiceConfirmView__value",
	"paid": "InvoiceConfirmView__paid",
	"error": "InvoiceConfirmView__error"
};
module.exports = exports;
