import React from 'react'
import { connect } from 'react-redux'
import styles from './StripeSubscription.css'
import classNames from 'classnames'
import { Link } from 'react-router'
import { push } from 'react-router-redux'

import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements'
import CreditCardForm from 'components/CreditCardForm'

import { getSubscription } from 'actions/subscription'
import { currencyToSymbol, insertStripeJsLibrary } from 'utils/misc'
import Api from 'utils/Api'
import { showGrowl } from 'actions/misc';

const SubscriptionView = ({ user, dispatch, auth }) => {
  const [plans, setPlans] = React.useState({
    isFetching: false,
    isFetched: false,
    items: [],
    error: null,
  })
  const [subscription, setSubscription] = React.useState({
    isFetched: false,
    isFetching: false,
    item: null,
    error: null
  })
  const [step, setStep] = React.useState('choose-plan')
  const [selectedPlan, setSelectedPlan] = React.useState(null)
  const [stripe, setStripe] = React.useState(null)

  const fetchPlans = async () => {
    const response = await Api.getPlans(auth.authToken)
    if (!response.metadata.success) {
      setPlans({
        ...plans,
        isFetching: false,
        isFetched: false,
        error: response.metadata,
      })
      return
    }

    setPlans({
      isFetched: true,
      isFetching: false,
      error: null,
      items: response.data
    })
  }

  const fetchSubscription = async () => {
    const response = await Api.getSubscription(auth.authToken)
    if (!response.metadata.success) {
      setSubscription({
        ...subscription,
        isFetched: false,
        isFetching: false,
        error: response.metadata.success,
      })
      return
    }

    setSubscription({
      isFetched: true,
      isFetching: false,
      error: null,
      item: response.data,
    })
  }

  const handleChoosePlan = plan => {
    setStep('choose-payment')
    setSelectedPlan(plan)
  }

  const handleChangePlanSuccess = () => {
    dispatch(getSubscription({
      redirect: '/account/billing?tab=2'
    }))
    dispatch(showGrowl('success', `Subscribed to ${selectedPlan.name} plan`))
  }

  React.useEffect(() => {
    fetchPlans()
    fetchSubscription()
    insertStripeJsLibrary(setStripe)
  }, [])


  if (!plans.isFetched || !subscription.isFetched) {
    return 'Getting your subscription details...'
  }

  return (
    <div className={styles.root}>
      { step === 'choose-plan'
        ? <ChoosePlanStep
            plans={plans}
            subscription={subscription.item}
            onChoosePlan={handleChoosePlan}
          />
        : null
      }
      { step === 'choose-payment'
        ? <ChoosePaymentStep
            plan={selectedPlan}
            user={user}
            auth={auth}
            onChangePlanSuccess={handleChangePlanSuccess}
            stripe={stripe}
          />
        : null
      }
    </div>
  )
}

const ChoosePlanStep = ({ plans, onChoosePlan, subscription }) => {
  return (
    <>
      <h2 className={classNames(styles.title, 'title', 'is-h2')}>Choose a plan</h2>
      <div className="box">
        <div className={styles.intro}>
        { subscription
          ? <p>You are currently on the {subscription.plan.name} plan</p>
          : <p>You do not have a subscription</p>
          }
        </div>
        <PlansList plans={plans.items} subscription={subscription} onChoosePlan={onChoosePlan} />
      </div>
    </>
  )
}

const ChoosePaymentStep = ({ user, auth, plan, onChangePlanSuccess, stripe }) => {
  const [paymentMethod, setPaymentMethod] = React.useState({
    isFetching: false,
    isFetched: false,
    error: null,
    item: null
  })

  const fetchPaymentMethod = async () => {
    const response = await Api.getCreditCard(auth.authToken)
    if (!response.metadata.success) {
      setPaymentMethod({
        isFetched: false,
        isFetching: false,
        error: response.metadata,
      })
      return
    }

    setPaymentMethod({
      isFetched: true,
      isFetching: false,
      error: null,
      item: response.data,
    })
  }

  React.useEffect(() => {
    fetchPaymentMethod()
  }, [])

  const handlePaymentMethodCreated = () => {
    fetchPaymentMethod()
  }

  if (!paymentMethod.isFetched) {
    return 'Getting your payment method...'
  }

  return (
    <>
      <h2 className={classNames(styles.title, 'title', 'is-h2')}>Subscribe to the {plan.name} plan</h2>
      <div className="box">
        <div className={styles.step2}>
        { !paymentMethod.item 
          ? <AddPaymentMethod user={user} auth={auth} onPaymentAdded={handlePaymentMethodCreated} stripe={stripe} />
          : <ConfirmPayment
              auth={auth}
              user={user}
              paymentMethod={paymentMethod.item}
              plan={plan}
              onChangePlanSuccess={onChangePlanSuccess}
              stripe={stripe}
            />
        }
        </div>
      </div>
    </>
  )
}

const ConfirmPayment = ({ user, auth, paymentMethod, plan, onChangePlanSuccess, stripe }) => {
  const [isSubscribing, setIsSubscribing] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const btnClass = classNames('btn', 'btn-primary', {
    "is-loading": isSubscribing
  })

  const createSubscription = async () => {
    setIsSubscribing(true)
    const response = await Api.createSubscription(auth.authToken, plan)
    if (!response.metadata.success) {
      setErrorMessage(response.metadata.message)
      setIsSubscribing(false)
      return
    }
    setIsSubscribing(false)

    // check if the latest invoice requires SCA verification
    if (response.data.scaRequirements.requires_action) {
      const { paymentIntent, error } = await stripe.handleCardPayment(
        response.data.scaRequirements.client_secret
      )

      if (error) {
        setIsSubscribing(false)
        let additionalErrorMessage = null

        if (error.type == 'card_error') {
          const invoiceId = response.data.scaRequirements.invoice_id
          additionalErrorMessage = <span>Please go to the <Link className="primary-link" to={'/invoice/confirm/' + invoiceId}>invoice page</Link> to reattempt payment</span>
        }

        let fullErrorMessage = (
          <div>
            <div>{error.message}</div>
            { additionalErrorMessage ? <div>{additionalErrorMessage}</div> : null }
          </div>
        )
        setErrorMessage(fullErrorMessage)
        return
      }
    }

    onChangePlanSuccess()
  }

  const handleClickSubscribe = evt => {
    evt.preventDefault()
    createSubscription()
  }

  return (
    <div className={styles.confirm}>
      <p>Your payment method</p>
      <div className={styles.method}>
        <div>
          <i className="far fa-credit-card" /> <b>{paymentMethod.brand}</b> **** **** **** {paymentMethod.last4}
        </div>
        <div>
          <b>Expires:</b> {paymentMethod.month}/{paymentMethod.year}
        </div>
      </div>
      <p>You are subscribing to the {plan.name} which is billed at { currencyToSymbol(plan.currency) }{plan.amount}/per month</p>
      <div className={styles.controls}>
        <button className={btnClass} onClick={handleClickSubscribe}><i className="far fa-check" /> Confirm subscription</button>
      </div>
      { errorMessage
        ? <div className={styles.error}>{errorMessage}</div>
        : null
      }
    </div>
  )
}

const AddPaymentMethod = ({ onPaymentAdded, user, auth, stripe }) => {
  const [isSaving, setIsSaving] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const handleSave = async (cardElement, name) => {
    // save payment method to backend
    let intent = null

    setIsSaving(true)

    // 1. create a SetupIntent with stripe
    const setupIntentResponse = await Api.createStripeSetupIntent(auth.authToken)
    if (!setupIntentResponse.metadata.success) {
      setErrorMessage('Unable to create a SetupIntent - please ping support@makerkit.co')
      setIsSaving(false)
      return
    } else {
      intent = setupIntentResponse.data
    }

    // 2. send card details to stripe using the SetupIntent above
    const cardSetupResponse = await stripe.handleCardSetup(
      intent.secret, cardElement, {
        payment_method_data: {
          billing_details: {
            name: name,
          }
        }
      }
    )

    if (cardSetupResponse.error) {
      setErrorMessage(cardSetupResponse.error.message)
      setIsSaving(false)
      return
    }

    // 3. create a new payment method on the backend using the SetupIntent above
    const response = await Api.attachStripePaymentMethod(auth.authToken, cardSetupResponse.setupIntent.payment_method)
   
    if (!response.metadata.success) {
      setErrorMessage('Unable to create payment method - please ping support@makerkit.co')
      setIsSaving(false)
      return
    }

    setIsSaving(false)
    setErrorMessage(false)
    onPaymentAdded()
  }

  return (
    <div className={styles.paymentMethod}>
      <p>It looks like you don't have a payment method on file. Please add one here</p>
      <StripeProvider stripe={stripe}>
        <Elements>
          <CreditCardForm isSaving={isSaving} onSave={handleSave} apiErrorMessage={errorMessage} />
        </Elements>
      </StripeProvider>
    </div>
  )
}

const PlansList = ({ plans, subscription, onChoosePlan }) => {
  return (
    <div className={styles.plansList}>
      {plans.map(plan => <Plan key={plan.name} plan={plan} isCurrentPlan={subscription && plan.id === subscription.plan.id} onChoosePlan={onChoosePlan} />)}
    </div>
  )
}

const Plan = ({ plan, isCurrentPlan, onChoosePlan }) => {
  const handleClick = evt => {
    evt.preventDefault()
    onChoosePlan(plan)
  }

  return (
    <div className={styles.plan}>
      <div className={styles.container}>
        <div className={styles.name}>{plan.name}</div>
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: plan.description }}></p>
        <button onClick={handleClick} disabled={isCurrentPlan} className="btn btn-primary">Choose plan</button>
      </div>
      <div className={styles.price}>
        <div className={styles.amount}>{ currencyToSymbol(plan.currency) }{ plan.amount }</div>
        <div className={styles.period}>per month</div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.item,
    subscription: state.subscription,
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(SubscriptionView)

