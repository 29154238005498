import React from 'react'
import ModalContext from './ModalContext'
import ModalRoot from './ModalRoot'

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = React.useState(null)
  const closeModal = () => setModal(null)

  const value = {
    modal,
    setModal,
    closeModal,
  }


  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalRoot modal={modal} />
    </ModalContext.Provider>
  ) 
} 

export default ModalProvider