
import { takeEvery, takeLatest } from 'redux-saga'
import { call, put, fork, select } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { getQuota } from 'actions/quota'
import { GET_SUBSCRIPTION, getSubscriptionSuccess, getSubscriptionFail } from 'actions/subscription'
import { CREATE_SUBSCRIPTION, createSubscriptionSuccess, createSubscriptionFail } from 'actions/subscription'
import { createCreditCardFail, createCreditCardSuccess } from 'actions/payment'
import { GET_INVOICES, getInvoicesSuccess, getInvoicesFail } from 'actions/subscription'
import { GET_PLANS, getPlansSuccess, getPlansFail } from 'actions/subscription'
import { CREATE_SHOPIFY_SUBSCRIPTION, createShopifySubscriptionSuccess, createShopifySubscriptionFail } from 'actions/subscription'
import { CANCEL_SUBSCRIPTION, cancelSubscriptionSuccess, cancelSubscriptionFail } from 'actions/subscription'

export function* getPlansSaga() {
  const state = yield select()
  const response = yield call(Api.getPlans, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getPlansSuccess(response))
  } else {
    yield put(getPlansFail(response))
  }
}

export function* getInvoicesSaga(action) {
  const state = yield select()
  const response = yield call(Api.getInvoices, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getInvoicesSuccess(response))
  } else {
    yield put(getInvoicesFail(response))
  }
}

export function* getSubscriptionSaga(action) {
  const state = yield select()
  const response = yield call(Api.getSubscription, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getSubscriptionSuccess(response))
    if (action.args && action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  } else {
    yield put(getSubscriptionFail(response))
  }
}

export function* createSubscriptionSaga(action) {
  const state = yield select()

  // if a new payment method has been specified, create it on the backend
  if (action.args.card) {
    const response = yield call(Api.createCreditCard, state.auth.authToken, action.args.card)
    if (!response.metadata.success) {
      yield put(createCreditCardFail(response))
      yield put(createSubscriptionFail(response))
      return // oh noe
    } else {
      yield put(createCreditCardSuccess(response))
    }
  }

  // finally create the new subscription
  const createSubResponse = yield call(Api.createSubscription, state.auth.authToken, action.args.plan)
  if (createSubResponse.metadata.success) {
    yield put(createSubscriptionSuccess(createSubResponse))
    yield put(getQuota())
  } else {
    yield put(createSubscriptionFail(createSubResponse))
    return
  }

  if (action.args.redirect) {
    yield put(push(action.args.redirect))
  }
}

export function *createShopifySubscriptionSaga(action) {
  const state = yield select()
  const response = yield call(Api.createShopifySubscription, state.auth.authToken, action.args.data)

  if (response.metadata.success) {
    yield put(createShopifySubscriptionSuccess(response))
    yield put(getQuota())
    if (response.data.url) {
      window.location = response.data.url
      return
    }
  } else {
    yield put(createShopifySubscriptionFail(response))
  }

  if (action.args.redirect) {
    yield put(push(action.args.redirect))
  }
}

export function* cancelSubscriptionSaga(action) {
  const state = yield select()
  const response = yield call(Api.cancelSubscription, state.auth.authToken)

  if (response.metadata.success) {
    yield put(cancelSubscriptionSuccess(response))
    if (action.args && action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  } else {
    yield put(cancelSubscriptionFail(response))
  }
}

export default {
  createSubscription: () => takeLatest(CREATE_SUBSCRIPTION.REQUEST, createSubscriptionSaga),
  getSubscription: () => takeLatest(GET_SUBSCRIPTION.REQUEST, getSubscriptionSaga),
  getInvoices: () => takeLatest(GET_INVOICES.REQUEST, getInvoicesSaga),
  getPlans: () => takeLatest(GET_PLANS.REQUEST, getPlansSaga),
  createShopifySubscription: () => takeLatest(CREATE_SHOPIFY_SUBSCRIPTION.REQUEST, createShopifySubscriptionSaga),
  cancelSubscription: () => takeLatest(CANCEL_SUBSCRIPTION.REQUEST, cancelSubscriptionSaga),
}

