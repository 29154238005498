// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.AnalyticsSummary__root {\n}\n\n.AnalyticsSummary__level {\n    margin-bottom: 20px;\n}\n\n.AnalyticsSummary__stat {\n    width: 150px;\n    background-color: var(--brand-color);\n    padding: 10px;\n    height: 100px;\n    vertical-align: top;\n    margin-right: 1rem;\n    color: #fff;\n    display: inline-block;\n    position: relative;\n    border-radius: 0.4rem;\n}\n\n.AnalyticsSummary__upgrade {\n    background-color: #E5F2FF;\n    color: #666;\n    width: 300px;\n}\n\n.AnalyticsSummary__upgrade a {\n    color: #fff;\n}\n\n.AnalyticsSummary__upgrade a:hover {\n    text-decoration: underline;\n}\n\n.AnalyticsSummary__heading {\n    font-size: 11px;\n    letter-spacing: 1px;\n    margin-bottom: 5px;\n    text-transform: uppercase;\n}\n\n.AnalyticsSummary__text {\n    font-size: 28px;\n    position: absolute;\n    left: 10px;\n    bottom: 10px;\n    line-height: 1em;\n}\n\n.AnalyticsSummary__textSmall {\n    position: absolute;\n    left: 10px;\n    bottom: 10px;\n    line-height: 1em;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "AnalyticsSummary__root",
	"level": "AnalyticsSummary__level",
	"stat": "AnalyticsSummary__stat",
	"upgrade": "AnalyticsSummary__upgrade",
	"heading": "AnalyticsSummary__heading",
	"text": "AnalyticsSummary__text",
	"textSmall": "AnalyticsSummary__textSmall"
};
module.exports = exports;
