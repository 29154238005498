import React from 'react'
import styles from './InvoiceConfirmView.css'
import { connect } from 'react-redux'
import { push } from 'react-router'
import moment from 'moment'

import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements'
import CreditCardForm from 'components/CreditCardForm'

import { currencyToSymbol, insertStripeJsLibrary } from 'utils/misc'
import Api from 'utils/Api'
import { showGrowl } from 'actions/misc';

const DATE_FORMAT = 'Do MMM YYYY'

const InvoiceConfirmView = ({ id, dispatch, user, auth, push }) => {
  const [stripe, setStripe] = React.useState(null)
  const [invoice, setInvoice] = React.useState({
    item: null,
    isFetching: false,
    isFetched: false,
    error: null
  })

  const fetchInvoice = async () => {
    setInvoice({
      ...invoice,
      isFetching: true,
    })
    const response = await Api.getInvoice(auth.authToken, id)
    if (!response.metadata.success) {
      setInvoice({
        ...invoice,
        isFetched: false,
        isFetching: false,
        error: response.metadata,
      })
    }

    setInvoice({
      ...invoice,
      isFetched: true,
      isFetching: false,
      item: response.data,
    })
  }

  const handleAddedPaymentMethod = () => {
    fetchInvoice()
    dispatch(showGrowl('success', 'Thank you, your payment has been taken.'))
  }

  const handleConfirmedPaymentMethod = () => {
    fetchInvoice()
    dispatch(showGrowl('success', 'Thank you, your payment information has been verified'))
  }

  const handleConfirmError = (error) => {
    if (error.code == 'payment_intent_authentication_failure') {
      dispatch(showGrowl('error', error.message))
      fetchInvoice()
    }
  }

  React.useEffect(() => {
    insertStripeJsLibrary(setStripe)
    fetchInvoice()    
  }, [])

  if (!invoice.isFetched) {
    return 'Loading invoice...'
  }

  return (
    <div className={styles.root}>
      <div className="viewroot">
        <div className={styles.invoiceContainer}>
          { invoice.item.paymentIntent && invoice.item.paymentIntent.status === 'requires_action'
            ? <ConfirmPayment stripe={stripe} invoice={invoice.item} onSuccess={handleConfirmedPaymentMethod} onError={handleConfirmError} />
            : null
          }
          { invoice.item.paymentIntent && invoice.item.paymentIntent.status === 'requires_payment_method'
            ? <AddPaymentMethod stripe={stripe} user={user} auth={auth} invoice={invoice.item} onSuccess={handleAddedPaymentMethod} />
            : null
          }
          <Invoice invoice={invoice.item} />
        </div>

      </div>
    </div>
  )
}

const Invoice = ({ invoice }) => {
  const appliedBalance = invoice.total != invoice.amountDue 
    ? invoice.amountDue - invoice.total
    : null

  return (
    <div className={styles.invoice}>
      { invoice.paid ? <div className={styles.paid}><i className="far fa-check-circle" /> Invoice Paid </div> : null }
      <div className={styles.items}>
        <div className={styles.itemsHeader}>
          <div>Description</div>
          <div>Total</div>
        </div>
        {invoice.lines.map(item => <InvoiceItem item={item} />)}
      </div>
      <div className={styles.totals}>
        <div className={styles.total}>
          <div className={styles.label}>Total:</div> <div className={styles.value}>{currencyToSymbol(invoice.currency)}{invoice.total.toFixed(2)}</div>
        </div>
        { appliedBalance !== null
          ? <div className={styles.balance}>
              <div className={styles.label}>Applied balance:</div> <div className={styles.value}>{currencyToSymbol(invoice.currency)}{appliedBalance.toFixed(2)}</div>
            </div>
          : null
        }
        <div className={styles.due}>
          <div className={styles.label}>Amount due:</div> <div className={styles.value}>{currencyToSymbol(invoice.currency)}{invoice.amountDue.toFixed(2)}</div>
        </div>
      </div>
      
    </div>
  )
}

const InvoiceItem = ({ item }) => {
  return (
    <div className={styles.item}>
      <div>
        {item.description}
      </div>
      <div className={styles.amount}>
        {currencyToSymbol(item.currency)}{item.amount.toFixed(2)}
      </div>
    </div>
  )
}

const ConfirmPayment = ({ stripe, invoice, onSuccess, onError }) => {
  const [errorMessage, setErrorMessage] = React.useState(null)
  const handleCardPayment = async () => {
    const { paymentIntent, error } = await stripe.handleCardPayment(invoice.paymentIntent.client_secret)
    if (error) {
      setErrorMessage(error.message)
      onError(error)
      return
    }
    onSuccess()
  }


  const handleClickConfirm = (evt) => {
    evt.preventDefault()
    handleCardPayment()
  }

  return (
    <div className={styles.confirm}>
      <div className={styles.title}>Confirm {currencyToSymbol(invoice.currency)}{invoice.total.toFixed(2)} payment</div>
      <p>You'll be asked to verify your identity with your bank</p>
      <button onClick={handleClickConfirm} className="btn btn-primary">Confirm payment</button>
      { errorMessage ? <div className={styles.error}>{errorMessage}</div> : null }
    </div>
  )
}

const AddPaymentMethod = ({ stripe, user, auth, invoice, onSuccess }) => {
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isSaving, setIsSaving] = React.useState(false)

  const handleSave = async (cardElement, name) => {
    setIsSaving(true)
    setErrorMessage(null)
    const { paymentIntent, error } = await stripe.handleCardPayment(
      invoice.paymentIntent.client_secret, cardElement, {
        payment_method_data: {
          billing_details: {
            name: name,
          }
        }
      }
    )
    if (error) {
      setErrorMessage(error.message)
      setIsSaving(false)
      return
    }

    const response = await Api.attachStripePaymentMethod(auth.authToken, paymentIntent.payment_method)
   
    if (!response.metadata.success) {
      setErrorMessage('Unable to set this as the default payment method')
      setIsSaving(false)
      return
    }

    setIsSaving(false)
    onSuccess()
  }
  
  let date = moment(new Date(invoice.createdAt*1000)).format(DATE_FORMAT)

  return (
    <div className={styles.paymentMethod}>
      <div className={styles.title}>{currencyToSymbol(invoice.currency)}{invoice.total.toFixed(2)} due {date}</div>
      <StripeProvider stripe={stripe}>
        <Elements>
          <CreditCardForm
            apiErrorMessage={errorMessage}
            isSaving={isSaving}
            onSave={handleSave}
            buttonText='Pay invoice'
          />
        </Elements>
      </StripeProvider>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.params.id,
    user: state.user.item,
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(InvoiceConfirmView)
