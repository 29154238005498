
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn, take } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { GET_INTEGRATION_REQUEST, getIntegrationRequestSuccess, getIntegrationRequestFail } from 'actions/integration_request'
import { BIND_INTEGRATION, bindIntegrationSuccess, bindIntegrationFail } from 'actions/integration_request'
import { CREATE_APPLICATION } from 'actions/application'
import { showGrowl } from 'actions/misc'
import Api from 'utils/Api'

function* getIntegrationSaga(action) {
  const state = yield select()
  const response = yield call(Api.getIntegrationRequest, state.auth.authToken, action.args.id)

  if (response.metadata.success) {
    yield put(getIntegrationRequestSuccess(response))
  } else {
    yield put(getIntegrationRequestFail(response))
  }
}

function* bindIntegrationSaga(action) {
  let application = null

  if (!action.args.application) {
    // wait for the application to be created
    const createApplicationAction = yield take(CREATE_APPLICATION.SUCCESS)
    application = createApplicationAction.response.data
  } else {
    application = action.args.application
  }

  const state = yield select()

  const bindResponse = yield call(Api.bindIntegration,
    state.auth.authToken,
    application,
    action.args.integrationRequestUUID
  )

  if (bindResponse.metadata.success) {
    yield put(bindIntegrationSuccess(bindResponse))
  } else {
    yield put(bindIntegrationFail(bindResponse))
  }

  if (action.args.growl) {
    yield put(showGrowl('success', action.args.growl))
  }

  if (action.args.redirect) {
    yield put(push(action.args.redirect))
  }
}

export default {
  getIntegrationRequest: () => takeLatest(GET_INTEGRATION_REQUEST.REQUEST, getIntegrationSaga),
  bindIntegration: () => takeLatest(BIND_INTEGRATION.REQUEST, bindIntegrationSaga),
}
