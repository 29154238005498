import React from 'react';
import styles from './NotificationRow.css'
import Modal from 'components/Modal'
import fecha from 'fecha'
import cx from 'classnames'
import { useModal } from 'containers/Modal/hook'

const NotificationRow = ({ notification, onDelete, onEdit, onCopy }) => {
  const { setModal, closeModal } = useModal()
  const imageNode = notification.image
    ? <img src={notification.image.url} alt="Notification image" />
    : null

  const handleDeleteClick = (evt) => {
    evt.preventDefault()
    setModal(
      <Modal>
        <p>Are you sure you want to delete this notification?</p>
        <div>
          <a href="#delete" className="btn btn-danger" onClick={handleDeleteConfirmClick}>Delete this notification</a>
          <a href="#cancel" className="btn btn-link" onClick={closeModal}>Cancel</a>
        </div>
      </Modal>
    )
  }

  const handleDeleteConfirmClick = (evt) => {
    evt.preventDefault()
    onDelete(notification)
  }

  const handleCopyClick = (evt) => {
    evt.preventDefault()
    onCopy(notification)
  }

  const handleEditClick = (evt) => {
    evt.preventDefault()
    onEdit(notification)
  }

  return (
    <div className="box mb-3">
      <div className={styles.root}>
        <div className={styles.image}>
          {imageNode}
        </div>
        <div className={styles.right}>
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: notification.message }}>
          </div>
          <div className={styles.footer}>
            <div className={cx(styles.created, 'text-muted')}>
              <small>Created: {fecha.format(new Date(notification.publishedAt * 1000), 'Do MMMM YYYY hh:mm A')}</small>
            </div>
            <div className={styles.controls}>
              <a href="#copy" title="Copy notification" onClick={handleCopyClick}><i className="fa fa-fw fa-clone fa-fw"></i></a>
              <a href="#edit" title="Edit notification" onClick={handleEditClick}><i className="fa fa-fw fa-pencil-square-o fa-fw"></i></a>
              <a href="#delete" title="Delete notification" onClick={handleDeleteClick}><i className="fa fa-fw fa-trash-o fa-fw "></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationRow