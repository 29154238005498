// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationForm__root {\n    display: flex;\n}\n\n.NotificationForm__left {\n    width: 200px;\n    margin-right: 1rem;\n    overflow: hidden;\n}\n\n.NotificationForm__left img {\n    width: 100%;\n}\n\n.NotificationForm__right {\n    width: 100%;;\n}\n\n.NotificationForm__dropzone {\n    width: 100%;\n    height: 200px;\n    border: 1px dashed #ccc;\n}\n\n.NotificationForm__dropzone  p{\n    padding: 1em;\n    text-align: center;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "NotificationForm__root",
	"left": "NotificationForm__left",
	"right": "NotificationForm__right",
	"dropzone": "NotificationForm__dropzone"
};
module.exports = exports;
