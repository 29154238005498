// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.ApplicationForm__optional {\n    font-weight: normal;\n    font-size: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"optional": "ApplicationForm__optional"
};
module.exports = exports;
