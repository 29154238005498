
import deepFreeze from 'deep-freeze'
import { SHOW_GROWL, HIDE_GROWL } from 'actions/misc'

export const growlState = {
  isShowing: false,
  message: null,
  type: null,
}

export const growl = (state=growlState, action) => {
  switch (action.type) {
    case SHOW_GROWL: {
      return Object.assign({}, state, {
        isShowing: true,
        message: action.message,
        type: action.type,
        timeout: action.timeout,
      })
    }
    case HIDE_GROWL: {
      return Object.assign({}, state, {
        isShowing: false,
        message: null,
        type: null,
      })
    }
  }

  return state
}
