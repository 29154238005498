
import { RESET_PASSWORD, RESET_PASSWORD_CLEAR } from 'actions/auth'
import { RESET_UPDATE_PASSWORD } from 'actions/auth'

export const passwordResetState = {
  isRequesting: false,
  isRequested: false,
  isPasswordChangeRequesting: false,
  isPasswordChanged: false,
  serverError: null,
}

export const passwordReset = (state=passwordResetState, action) => {
  switch (action.type) {

    // change the actual password using token from email
    case RESET_UPDATE_PASSWORD.REQUEST: {
      return Object.assign({}, state, {
        isPasswordChangeRequesting: true,
      })
    }
    case RESET_UPDATE_PASSWORD.SUCCESS: {
      return Object.assign({}, state, {
        isPasswordChangeRequesting: false,
        isPasswordChanged: true,
      })
    }
    case RESET_UPDATE_PASSWORD.FAIL: {
      return Object.assign({}, state, {
        isPasswordChangeRequesting: false,
        isPasswordChanged: false,
        serverError: action.response.metadata,
      })
    }
    // send the reset password email
    case RESET_PASSWORD.REQUEST: {
      return Object.assign({}, state, {
        isRequesting: true,
      })
    }
    case RESET_PASSWORD.SUCCESS: {
      return Object.assign({}, state, {
        isRequesting: false,
        isRequested: true,
      })
    }
    case RESET_PASSWORD.FAIL: {
      return Object.assign({}, state, {
        isRequesting: false,
        isRequested: false,
        serverError: action.response.metadata,
      })
    }
    case RESET_PASSWORD_CLEAR: {
      return {
        isRequesting: false,
        isRequested: false,
        serverError: null,
      }
    }
  }

  return state
}

