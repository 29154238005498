
import { GET_ANIMATIONS } from 'actions/theme'

export const animationsState = {
  isFetching: false,
  isFetched: false,
  items: []
}

export const animations = (state=animationsState, action) => {
  switch (action.type) {
    case GET_ANIMATIONS.REQUEST: {
      return Object.assign({}, state, {
        isFetched: false,
        isFetching: true,
      })
    }
    case GET_ANIMATIONS.SUCCESS: {
      return Object.assign({}, state, {
        isFetched: true,
        isFetching: false,
        items: action.response.data,
      })
    }
    case GET_ANIMATIONS.FAIL: {
      return Object.assign({}, state, {
        isFetched: false,
        isFetching: false,
        fetchError: action.response.metadata,
      })
    }
  }

  return state
}
