// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Payment__root {\n    padding: 1rem;\n}\n\n.Payment__container {\n    margin: 100px auto;\n    width: 500px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "Payment__root",
	"container": "Payment__container"
};
module.exports = exports;
