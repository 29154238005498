import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import styles from './Themes.css'
import { updateTheme } from 'actions/theme'
import VisualThemeEditor from 'components/VisualThemeEditor'

export class ThemesView extends React.Component {

  static propTypes = {
    themes: PropTypes.object.isRequired,
    animations: PropTypes.object.isRequired,
  }

  state = {
    loading: true,
    cssTemplate: '',
    html: '',
    position: 'left',
    size: 'normal',
    backgroundColor: '',
    borderWidth: 1,
    borderColor: '',
    textColor: '',
    linkColor: '',
    showAnimation: '',
    hideAnimation: '',
  }

  componentDidMount() {
    if (this.props.themes.isFetched) {
      this.updateStateData(this.props)
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.themes.isFetched) {
      this.updateStateData(nextProps)
    }
  }

  updateStateData = (props) => {
    const defaultTheme = props.themes.items.find(theme => theme.default == true)
    this.setState({
      html: defaultTheme.html,
      cssTemplate: defaultTheme.baseTheme.cssTemplate,
      ...defaultTheme.variables,
    })
  }

  /**
   * Called when the options within the theme styler are changed
   *
   */
  handleThemeOptionsChange = (data) => {
    this.setState(data)
  }

  /**
   * Called when the position radio checkbox is changed
   *
   */
  handlePositionChange = (evt, position) => {
    this.setState({
      position: position
    })
  }

  /**
   * Called when the size checkbox is changed
   */
  handleSizeChange = (evt, size) => {
    this.setState({
      size: size
    })
  }

  /**
   * Called when the save button is clicked
   *
   */
  handleThemeSave = (evt, css) => {
    const defaultTheme = this.props.themes.items.find(theme => theme.default == true)
    const variables = {
      position: this.state.position,
      size: this.state.size,
      backgroundColor: this.state.backgroundColor,
      borderWidth: this.state.borderWidth,
      borderColor: this.state.borderColor,
      textColor: this.state.textColor,
      linkColor: this.state.linkColor,
      showAnimation: this.state.showAnimation,
      hideAnimation: this.state.hideAnimation,
      css: css,
      html: this.state.html,
    }

    this.props.dispatch(updateTheme({id: defaultTheme.id, data: variables}))
  }

  render() {
    return (
      <div className={styles.root}>
        <h2>Theme editor</h2>

        <VisualThemeEditor
          onChange={this.handleThemeOptionsChange}
          onSave={this.handleThemeSave}
          animations={this.props.animations.items}
          {...this.state}
        />

      </div>
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  return {
    themes: state.themes,
    animations: state.animations,
  }
}

export default connect(mapStateToProps)(ThemesView)
