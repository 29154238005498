
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { GET_QUOTA, getQuotaSuccess, getQuotaFail } from 'actions/quota'
import { showGrowl } from 'actions/misc'

export function* getQuotaSaga() {
  const state = yield select()
  const response = yield call(Api.getQuota, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getQuotaSuccess(response))
  } else {
    yield put(getQuotaFail(response))
  }
}

export default {
  getQuota: () => takeLatest(GET_QUOTA.REQUEST, getQuotaSaga),
}
