import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_SUBSCRIPTION = createRequestTypes('GET_SUBSCRIPTION')
export const GET_INVOICES = createRequestTypes('GET_INVOICES')
export const GET_PLANS = createRequestTypes('GET_PLANS')
export const CREATE_SUBSCRIPTION = createRequestTypes('CREATE_SUBSCRIPTION')
export const CREATE_SHOPIFY_SUBSCRIPTION = createRequestTypes('CREATE_SHOPIFY_SUBSCRIPTION')
export const CANCEL_SUBSCRIPTION = createRequestTypes('CANCEL_SUBSCRIPTION')

export const [
  getSubscription,
  getSubscriptionSuccess,
  getSubscriptionFail
] = createAsyncRequestActions(GET_SUBSCRIPTION)

export const [
  getInvoices,
  getInvoicesSuccess,
  getInvoicesFail
] = createAsyncRequestActions(GET_INVOICES)

export const [
  getPlans,
  getPlansSuccess,
  getPlansFail
] = createAsyncRequestActions(GET_PLANS)

export const [
  createSubscription,
  createSubscriptionSuccess,
  createSubscriptionFail,
] = createAsyncRequestActions(CREATE_SUBSCRIPTION)

export const [
  createShopifySubscription,
  createShopifySubscriptionSuccess,
  createShopifySubscriptionFail,
] = createAsyncRequestActions(CREATE_SHOPIFY_SUBSCRIPTION)

export const [
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFail,
] = createAsyncRequestActions(CANCEL_SUBSCRIPTION)

