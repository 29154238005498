
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import styles from './EmbedCode.css'
import Tabs from 'components/Tabs'
import SyntaxHighlighter, { registerLanguage } from "react-syntax-highlighter/dist/light"
import js from 'highlight.js/lib/languages/javascript';
import html from 'highlight.js/lib/languages/xml';
import docco from 'react-syntax-highlighter/dist/styles/docco';
import EmailCodeForm from 'components/EmailCodeForm'
import { sendEmail } from 'actions/misc'
import { validateEmailEmbedCode } from 'utils/validation'
import copy from 'copy-to-clipboard'

registerLanguage('javascript', js);
registerLanguage('html', html);

export class EmbedCodeView extends React.Component {

  static propTypes = {
    application: PropTypes.object,
    isSendingEmail: PropTypes.bool.isRequired,
    tab: PropTypes.string,
  }

  state = {
    selectedTab: null,
    selectedIntegration: '',
  }

  getTabOptions = () => {
    let options = [
      { id: 1, label: 'Embed code', icon: <i className="fa fa-code"></i> },
      { id: 2, label: 'Send to developer', icon: <i className="fa fa-envelope"></i> }
    ]

    return options
  }

  componentWillMount() {
    let tab = null
    if (this.props.tab) {
      tab = this.getTabOptions().find(t => t.id == this.props.tab ? true : false )
    } else {
      tab = this.getTabOptions()[0]
    }

    // use the first tab
    this.setState({ selectedTab: tab })
  }

  /**
   * Get the actual embed code
   *
   */
  getEmbedCode = () => {
    let code = `<!-- start of crowdlever embed code -->
<script async id="crowdlever-script" src="https://platform.crowdlever.io/embed/v1/embed.js" data-id="${this.props.application.uuid}"></script>
<!-- end of crowdlever embed code -->`
    return code
  }

  handleCopyToClipboard = (evt) => {
    copy(this.getEmbedCode())
  }

  /**
   * Html for the embed code
   *
   */
  renderEmbedCodeBlock = () => {
    let customStyle = {
      padding: '1rem',
      width: '100%',
      margin: '0 auto',
      border: '1px solid #ccc'
    }
    let scriptText = this.getEmbedCode()

    return (
      <div className="mt-3">
        <p>Paste your code snippet (unaltered, in its entirety) before the closing {'</head>'} tag on every web page on your site you wish to show notifications.</p>

        <div className={styles.code}>
          <SyntaxHighlighter
            language='html'
            style={docco}
            customStyle={customStyle}>
            {scriptText}
          </SyntaxHighlighter>
        </div>

        <div className="text-center">
          <a href="#copy" className="btn btn-primary" onClick={this.handleCopyToClipboard}>Copy to clipboard</a>
        </div>

      </div>
    )
  }

  /**
   * Html for the email form
   *
   */
  renderEmailForm = () => {
    return (
      <div className="mt-3">
        <p>To have your developer embed the code, use the form below to send them instructions</p>
        <EmailCodeForm
          validation={this.state.validation}
          isSending={this.props.isSendingEmail}
          onSend={this.handleEmailSend}
          embedCode={this.getEmbedCode()}
          onSend={this.handleEmailSend}
          user={this.props.user}
        />
      </div>
    )
  }

  handleEmailSend = (to, message) => {
    let data = {
      email: to,
      message: message
    }

    let results = validateEmailEmbedCode(data)
    if (results.ok) {
      this.props.dispatch(sendEmail({ to: to, message: message }))
    } else {
      this.setState({ validation: results })
    }
  }

  /**
   * When tab is clicked
   *
   */
  handleTabSelect = (tab) => {
    this.setState({ selectedTab: tab })
    this.props.dispatch(push({ pathname: '/embed', query: { 'tab': tab.id }}))
  }

  /**
   * When radio is clicked
   *
   */
  handleIntegrationClick = (evt) => {
    this.setState({
      selectedIntegration: evt.target.value,
    })
  }

  renderManualInstall = () => {
    return (
      <div className="mt-5">
        <Tabs
          selectedTab={this.state.selectedTab}
          onClick={this.handleTabSelect}
          tabs={this.getTabOptions()}
        />
        { this.state.selectedTab.label == 'Embed code' ? this.renderEmbedCodeBlock() : null }
        { this.state.selectedTab.label == 'Send to developer' ? this.renderEmailForm() : null }
      </div>
    )
  }

  renderOtherInstall = () => {
    if (this.state.selectedIntegration == '') {
      return
    }

    return (
      <div className={styles.noSetupRequired}>
        <p>There is no need to install this embed code on your site. It has already been automatically setup for you!</p>
      </div>
    )
  }

  render() {

    if (!this.props.application) {
      return (
          <div className={styles.root}>
            Loading...
          </div>
      )
    }

    return (
      <div className={styles.root}>
          <h2>Embed into your website</h2>
          <p>Choose which platform you're using:</p>
          <div className="form-check">
            <input className="form-check-input" type="radio" value="shopify" checked={this.state.selectedIntegration == 'shopify' } onClick={this.handleIntegrationClick} />
            <label className="form-check-label">Shopify</label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="radio" value="ecwid" checked={this.state.selectedIntegration == 'ecwid' } onClick={this.handleIntegrationClick} />
            <label className="form-check-label">Ecwid</label>
          </div>

          <div className="form-check">
              <input className="form-check-input" type="radio" value="weebly" checked={this.state.selectedIntegration == 'weebly' } onClick={this.handleIntegrationClick} />
              <label className="form-check-label">Weebly</label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="radio" value="manual" checked={this.state.selectedIntegration == 'manual' } onClick={this.handleIntegrationClick} />
            <label className="form-check-label">No platform, manual install</label>
          </div>

          { this.state.selectedIntegration == 'manual' ? this.renderManualInstall() : this.renderOtherInstall() }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let tab = ownProps.location.query.tab

  return {
    application: state.applications.selected,
    isSendingEmail: state.embed.isSendingEmail,
    user: state.user,
    tab: tab,
  }
}

export default connect(mapStateToProps)(EmbedCodeView)

