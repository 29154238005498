// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.WeeblySetup__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.WeeblySetup__buttonRow {\n    margin-top: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "WeeblySetup__root",
	"buttonRow": "WeeblySetup__buttonRow"
};
module.exports = exports;
