import React from 'react'
import styles from './Modal.module.css'


const Modal = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
)

export default Modal