
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'
import { saveUserDataToBrowser, clearUserDataFromBrowser } from 'utils/misc'

import { GET_USER, getUserSuccess, getUserFail } from 'actions/user'
import { CREATE_USER, createUserSuccess, createUserFail } from 'actions/user'
import { UPDATE_USER, updateUserSuccess, updateUserFail } from 'actions/user'
import { LOGIN_USER, loginUserSuccess, loginUserFail, LOGOUT_USER } from 'actions/auth'
import { LOGIN_SSO, loginSSOSuccess, loginSSOFail } from 'actions/auth'
import { showGrowl } from 'actions/misc'
import { initializeApplication } from 'actions/misc'

export function* getUserSaga() {
  const state = yield select()
  const response = yield call(Api.getUser, state.auth.authToken)
  if (response.metadata.success) {
    yield put(getUserSuccess(response))
  } else {
    yield put(getUserFail(response))
  }
}

export function* createUserSaga(action) {
  const state = yield select()
  const response = yield call(Api.createUser, action.args.user)

  if (response.metadata.success) {
    saveUserDataToBrowser(response.data)
    yield put(createUserSuccess(response))
    yield put(initializeApplication())

    if (action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  } else {
    yield put(createUserFail(response))
  }
}

export function* updateUserSaga(action) {
  const state = yield select()
  const response = yield call(Api.updateUser, state.auth.authToken, action.args.user)

  if (response.metadata.success) {
    yield put(updateUserSuccess(response))
    yield put(showGrowl('success', 'Your user information has been updated'))
  } else {
    yield put(updateUserFail(response))
  }
}

export function* loginUserSaga(action) {
  const response = yield call(Api.authenticateUser, action.args.email, action.args.password);
  if (response.metadata.success) {
    saveUserDataToBrowser(response.data)
    yield put(loginUserSuccess(response))
    yield put(initializeApplication())

    if (action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  } else {
    yield put(loginUserFail(response))
  }
}

export function* logoutUserSaga() {
  clearUserDataFromBrowser()
}

export function* loginSSOSaga(action) {
  const response = yield call(Api.getUser, action.args.authToken);

  if (response.metadata.success) {
    saveUserDataToBrowser(response.data)
    yield put(loginUserSuccess(response))
    yield put(initializeApplication())

    if (action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  } else {
    yield put(loginUserFail(response))
  }
}

export default {
  getUser: () => takeLatest(GET_USER.REQUEST, getUserSaga),
  createUser: () => takeLatest(CREATE_USER.REQUEST, createUserSaga),
  updateUser: () => takeLatest(UPDATE_USER.REQUEST, updateUserSaga),
  loginUser: () => takeLatest(LOGIN_USER.REQUEST, loginUserSaga),
  logoutUser: () => takeLatest(LOGOUT_USER, logoutUserSaga),
  loginSSO: () => takeLatest(LOGIN_SSO.REQUEST, loginSSOSaga),
}
