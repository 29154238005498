
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { renderErrorForField } from 'utils/misc'
import { resetPassword, resetPasswordClear } from 'actions/auth'
import { validateForgotPassword } from 'utils/validation'
import cx from 'classnames'
import styles from './ForgotPassword.css'

export class ForgotPasswordView extends React.Component {

  static propTypes = {
    passwordReset: PropTypes.object.isRequired,
    serverError: PropTypes.object,
  }

  state = {
    emailAddress: '',
    validation: null,
  }

  componentWillMount = () => {
    this.props.dispatch(resetPasswordClear())
  }

  handleEmailChange = (evt) => {
    this.setState({ emailAddress: evt.target.value })
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    let data = {
      emailAddress: this.state.emailAddress
    }

    let validation = validateForgotPassword(data)
    if (validation.ok) {
      this.props.dispatch(resetPassword(data))
    } else {
      this.setState({ validation: validation })
    }
  }

  renderForm = () => {
    const btnClassName = cx('btn', 'btn-primary', 'btn-xl', 'btn-block', {
      'is-loading': this.props.passwordReset.isRequesting
    })

    return (
      <div className={styles.root}>
        <h3 className="text-center mb-5">Reset your password</h3>
        <div className="box">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label className="label">Email</label>
              <input value={this.state.emailAddress} onChange={this.handleEmailChange} className="form-control" type="email" placeholder="Email" />
              {renderErrorForField('emailAddress', this.state.validation)}
              {renderErrorForField('emailAddress', this.props.serverError)}
            </div>
            <div className="form-group">
              <button className={btnClassName} type="submit" onClick={this.handleSubmit}>Reset password</button>
            </div>
          </form>
        </div>
        <div className={styles.forgotPasswordContainer}>
          <Link to={'/login'} className={styles.registerLink}>Back to login</Link>
        </div>
      </div>
    )
  }

  renderSuccess = () => {
    return (
      <div className={styles.root}>
        <h1 className="title has-text-centered">Email sent!</h1>
        <div className="box">
          <p>An email has been sent to <b>{this.state.emailAddress}</b> with instructions on how to reset your password</p>
        </div>
        <div className={styles.forgotPasswordContainer}>
          <Link to={'/login'} className={styles.registerLink}>Remember your password? Login in here</Link>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.passwordReset.isRequested) {
      return this.renderSuccess()
    } else {
      return this.renderForm()
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    passwordReset: state.passwordReset,
  }
}

export default connect(mapStateToProps)(ForgotPasswordView)

