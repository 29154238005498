
import PropTypes from 'prop-types';
import React from 'react';
import styles from './DateTimePicker.css'
import DatePicker from 'components/DatePicker'
import TimePicker from 'components/TimePicker'

export default class DateTimePicker extends React.Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  state = {
    date: null,
    time: { minutes: null, hours: null},
  }

  componentWillMount() {
    this.setState({
      date: this.props.date,
    })
  }

  createNewDate = (date, hours, minutes) => {
    let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)
    return newDate
  }

  handleTimeChange = (hours, minutes) => {
    let newDate = this.createNewDate(this.state.date, hours, minutes)
    this.setState({
      date: newDate,
      time: { hours, minutes }
    })
    this.props.onChange(newDate)
  }

  handleDateChange = (date) => {
    let newDate = this.createNewDate(date, this.state.time.hours, this.state.time.minutes)
    this.setState({
      date: newDate,
    })
    this.props.onChange(newDate)
  }

  render() {
    return (
        <div className={styles.root}>
          <DatePicker date={this.props.date} onChange={this.handleDateChange} />
          <TimePicker date={this.props.date} onChange={this.handleTimeChange} />
        </div>
    )
  }
}
