
import deepFreeze from 'deep-freeze'

import { LOGOUT_USER } from 'actions/auth'
import { GET_USER, CREATE_USER, UPDATE_USER } from 'actions/user'

export const userState = {
  fetchError: null,
  createError: null,
  isFetching: false,
  isFetched: false,
  isCreating: false,
  isCreated: false,
  isUpdating: false,
  item: {}
}

export const user = (state=userState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        fetchError: null,
        createError: null,
        isFetched: false,
        isFetching: false,
        item: {},
      }
    }
    case GET_USER.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true
      })
    }
    case GET_USER.SUCCESS: {
      return {
        isFetched: true,
        isFetching: false,
        item: {
          firstName: action.response.data.firstName,
          lastName: action.response.data.lastName,
          emailAddress: action.response.data.emailAddress,
          origin: action.response.data.origin,
          lastLoginAt: action.response.data.lastLoginAt,
        }
      }
    }
    case GET_USER.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        fetchError: action.response
      })
    }
    case CREATE_USER.REQUEST: {
      return Object.assign({}, state, {
        isCreating: true,
        isCreated: false,
      })
    }
    case CREATE_USER.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        isCreating: false,
        isCreated: true,
        item: {
          firstName: action.response.data.firstName,
          lastName: action.response.data.lastName,
          emailAddress: action.response.data.emailAddress,
          origin: action.response.data.origin,
        }
      })
    }
    case CREATE_USER.FAIL: {
      return Object.assign({}, state, {
        isCreating: false,
        isCreated: false,
        createError: action.response.metadata,
      })
    }
    case UPDATE_USER.REQUEST: {
      return Object.assign({}, state, {
        isUpdating: true
      })
    }
    case UPDATE_USER.SUCCESS: {
      return Object.assign({}, state, {
        isUpdating: false,
        item: action.response.data
      })
    }
  }

  return state
}
