// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.SSO__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.SSO__forgotPasswordContainer {\n    text-align: center;\n}\n\n.SSO__forgotPassword {\n    padding-top: 1em;\n    font-size: small;\n}\n\n.SSO__registerLink {\n}\n\n\n", ""]);
// Exports
exports.locals = {
	"root": "SSO__root",
	"forgotPasswordContainer": "SSO__forgotPasswordContainer",
	"forgotPassword": "SSO__forgotPassword",
	"registerLink": "SSO__registerLink"
};
module.exports = exports;
