
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import styles from './Integration.css'
import Loading from 'components/Loading'

// the views that implement integrations
import WeeblyIntegrationView from 'views/WeeblyIntegration'
import EcwidIntegrationView from 'views/EcwidIntegration'
import ShopifyIntegrationView from 'views/ShopifyIntegration'

class IntegrationView extends React.Component {
  static propTypes = {
    integrations: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  }

  getSelectedIntegration = () => {
    for (let cIntegration of this.props.integrations.items) {
      if (cIntegration.id == this.props.params.integrationId) {
        return cIntegration
      }
    }
  }

  getIntegrationImplementationView = () => {
    let subView = null
    let integration = this.getSelectedIntegration()

    switch (integration.kind) {
      case 'weebly': {
        subView = <WeeblyIntegrationView integration={integration} />
        break
      }
      case 'ecwid': {
        subView = <EcwidIntegrationView integration={integration} />
        break
      }
      case 'shopify': {
        subView = <ShopifyIntegrationView integration={integration} />
      }
    }

    return subView
  }

  render() {
    if (!this.props.integrations.isFetched) {
      return <Loading loading={true} text="Loading integrations"></Loading>
    }

    let view = this.getIntegrationImplementationView()
    if (!view) {
      return (
          <div>There is no implementation for this integration type</div>
      )
    }

    return view
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    integrations: state.integrations,
    params: ownProps.params,
  }
}

export default connect(mapStateToProps)(IntegrationView)
