
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { loginUser } from 'actions/auth'
import AnalyticsGraph from 'components/AnalyticsGraph'
import GoogleConnect from 'components/GoogleConnect'
import Loading from 'components/Loading'
import GAGoalSetup from 'components/GAGoalSetup'
import AnalyticsSummary from 'components/AnalyticsSummary'
import styles from './Dashboard.css'
import { getApplicationAnalytics } from 'actions/application'
import { connectGoogleAnalytics, updateGoogleAccount } from 'actions/ga'

export class DashboardView extends React.Component {

  static propTypes = {
    ga: PropTypes.object.isRequired,
    application: PropTypes.object,  // state.applications.selected
    analytics: PropTypes.object,
    quota: PropTypes.object,
    subscription: PropTypes.object,
  }

  handleUpgradeClick = (evt) => {
    evt.preventDefault()
    this.props.dispatch(push({ pathname: '/subscription', query: { returnTo: '/'} }))
  }

  handleConnectGoogleAnalytics = (popup) => {
    this.props.dispatch(connectGoogleAnalytics({ application: this.props.application, popup: popup }))
  }

  handleGASetup = (data) => {
    this.props.dispatch(updateGoogleAccount({
      application: this.props.application,
      data: data
    }))
  }

  isDataLoading = () => {
    if (  this.props.application == null ||
          !this.props.analytics.isFetched ||
          !this.props.subscription.isFetched ||
          !this.props.quota.isFetched
        ) {
      return true
    }
    return false
  }

  getGAConnect = () => {
    if (!this.props.subscription.item) {
      return
    }

    // free account
    if (this.props.subscription.item.plan.kind == 'free_plan') {
      return null
    }

    // account not connected
    if (!this.props.ga.item.oauth.connected) {
      return <GoogleConnect onClick={this.handleConnectGoogleAnalytics} />
    }

    // no goals defined
    if (!this.props.ga.item.isSetup) {
      return (
        <div className={styles.goalsSetupContainer}>
          <GAGoalSetup ga={this.props.ga} onSave={this.handleGASetup} />
        </div>
      )
    }

    // ga is connected and setup
    return null
  }

  render() {
    return (
      <div className={styles.root}>
        <h2>Analytics</h2>
        <h3 className="text-muted">Views and clicks</h3>

        <Loading loading={this.isDataLoading()} text="Loading chart">
          <AnalyticsGraph data={this.props.analytics.item.chartData} />
        </Loading>

        <h3>Summary</h3>

        <Loading loading={this.isDataLoading()} text="Loading analytics summary">
          <AnalyticsSummary
            onUpgradeClick={this.handleUpgradeClick}
            quota={this.props.quota}
            summary={this.props.analytics.item.summary}
            subscription={this.props.subscription}
          />
        </Loading>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    application: state.applications.selected,
    analytics: state.analytics,
    ga: state.ga,
    quota: state.quota,
    subscription: state.subscription,
  }
}

export default connect(mapStateToProps)(DashboardView)
