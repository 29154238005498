
import { SEND_EMAIL } from 'actions/misc'

export const embedState = {
  isSendingEmail: false,
  isSent: false
}

export const embed = (state=embedState, action) => {
  switch (action.type) {
    case SEND_EMAIL.REQUEST: {
      return {
        isSendingEmail: true
      }
    }
    case SEND_EMAIL.SUCCESS: {
      return {
        isSendingEmail: false,
        isSent: true
      }
    }
    case SEND_EMAIL.FAIL: {
      return {
        isSendingEmail: false,
        isSent: false
      }
    }
  }
  return state
}
