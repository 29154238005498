/* global Stripe */
// Naming scheme: verbResourceName

import fetch from 'isomorphic-fetch'
import buildUrl from 'build-url'

const handleResponse = (response) => {
  if (response.ok) {
    return response.json()
  }

  if (response.status >= 300 && response.status < 500) {
    return response.json()
  }

  return Promise.reject(response.statusText)
}

const handleError = (errorMessage) => {
  return {
    metadata: {
      success: false,
      message: errorMessage
    }
  }
}

export const createHeaders = (authToken=null) => {
  let headers = {
    'Content-Type': 'application/json'
  }

  if (authToken) {
    headers['Authorization'] = authToken
  }

  return headers
}

export const createUser = (userData) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/user/',
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(userData)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const authenticateUser = (email, password) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/auth/'
  })

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      emailAddress: email,
      password: password,
    })
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getUser = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/user/'
  })

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    }
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getApplications = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/application/'
  })

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    }
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getNotifications = (authToken, applicationId, page=1, size=25) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${applicationId}/notifications/?page=${page}&size=${size}`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const createNotification = (authToken, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/notification/'
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify(data)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const createApplication = ({authToken, data}) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/application/'
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify(data)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const createImage = (authToken, file) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/file/'
  })

  let formData = new FormData()
  formData.append('image', file)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: authToken
    },
    body: formData
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getThemes = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/themes/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateTheme  = (authToken, themeId, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/theme/${themeId}/`
  })

  return fetch(url, {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify(data)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateSettings = (authToken, applicationId, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${applicationId}/settings/`
  })

  return fetch(url, {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify(data)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getApplicationAnalytics = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/analytics/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const emailEmbedCode = (authToken, mail) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/email/embed/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify(mail)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const deleteNotification = (authToken, id) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/notification/${id}/`
  })

  return fetch(url, {
    method: 'DELETE',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateUser = (authToken, user) => {
  const  url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/user/`
  })

  return fetch(url, {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify(user)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateNotification = (authToken, notification, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/notification/${notification.id}/`
  })

  return fetch(url, {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify(data),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getCreditCard = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/payment-method/default/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getSubscription = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/subscription/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getInvoices = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/invoice/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getPlans = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/subscription/plans/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const createSubscription = (authToken, plan) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/subscription/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify({ plan: plan }),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getGoogleAnalyticsOAuthRedirectUrl = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/connect/google/analytics/?applicationId=${application.id}`,
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getGoogleAccount = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/ga-account/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateGoogleAccount = (authToken, application, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/ga-account/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify({
      accountId: data.selectedAccount.id,
      propertyId: data.selectedProperty.id,
      profileId: data.selectedProfile.id,
      goalId: data.selectedGoal.id,
    })
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getApplicationAnalyticsSummary = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/analytics/summary/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getIntegrationRequest = (authToken, id) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/integration/${id}/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const bindIntegration = (authToken, application, integrationRequestUUID) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/integration/bind/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify({
      applicationId: application.id,
      integrationRequestUUID: integrationRequestUUID,
    })
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateEventTemplate = (authToken, integration, eventTemplate, updateExisting) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/integration/${integration.id}/event_template/${eventTemplate.id}/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify({
      templateText: eventTemplate.templateText,
      updateExisting: updateExisting,
    })
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getIntegrations = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/integrations/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateApplication = (authToken, application) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/application/${application.id}/`
  })

  return fetch(url, {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify(application),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getAnimations = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/theme/animations/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getQuota = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/user/quota/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken)
  })
  .then(handleResponse)
  .catch(handleError)
}

export const forgotPassword = (emailAddress) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/auth/forgot-password/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify({ emailAddress: emailAddress }),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const resetPassword = (token, password) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/auth/reset-password/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify({
      token: token,
      password: password,
    })
  })
  .then(handleResponse)
  .catch(handleError)
}

export const updateEcwidIntegration = (token, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/ecwid/integration/${data.id}/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(token),
    body: JSON.stringify(data),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const createShopifySubscription = (token, data) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/shopify/subscription/`
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(token),
    body: JSON.stringify(data),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getWorld = (token) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/world/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(token),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const cancelSubscription = (token) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/subscription/`
  })

  return fetch(url, {
    method: 'DELETE',
    headers: createHeaders(token),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const createStripeSetupIntent = (authToken) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/payment-method/setup-intent/'
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}

export const attachStripePaymentMethod = (authToken, intentId) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: 'api/v1.0/payment-method/attach/'
  })

  return fetch(url, {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify({
      id: intentId
    })
  })
  .then(handleResponse)
  .catch(handleError)
}

export const getInvoice = (authToken, id) => {
  const url = buildUrl(AppConfig.SERVER, {
    path: `api/v1.0/invoice/${id}/`
  })

  return fetch(url, {
    method: 'GET',
    headers: createHeaders(authToken),
  })
  .then(handleResponse)
  .catch(handleError)
}


export default {
  createUser,
  authenticateUser,
  getUser,
  getApplications,
  getNotifications,
  createNotification,
  createApplication,
  createImage,
  getThemes,
  updateTheme,
  updateSettings,
  getApplicationAnalytics,
  emailEmbedCode,
  deleteNotification,
  updateUser,
  updateNotification,
  getCreditCard,
  getSubscription,
  getInvoices,
  getPlans,
  createSubscription,
  getGoogleAnalyticsOAuthRedirectUrl,
  getGoogleAccount,
  updateGoogleAccount,
  getApplicationAnalyticsSummary,
  getIntegrationRequest,
  bindIntegration,
  updateEventTemplate,
  getIntegrations,
  updateApplication,
  getAnimations,
  getQuota,
  forgotPassword,
  resetPassword,
  updateEcwidIntegration,
  createShopifySubscription,
  getWorld,
  cancelSubscription,
  attachStripePaymentMethod,
  createStripeSetupIntent,
  getInvoice,
}

