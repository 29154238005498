// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal__root {\n\n}\n\n.Modal__content {\n  padding: 1rem;\n}", ""]);
// Exports
exports.locals = {
	"root": "Modal__root",
	"content": "Modal__content"
};
module.exports = exports;
