
import deepFreeze from 'deep-freeze'

import { LOGOUT_USER } from 'actions/auth'
import { GET_APPLICATION_ANALYTICS } from 'actions/application'
import { GET_APPLICATION_ANALYTICS_SUMMARY } from 'actions/application'

export const analyticsState = {
  isFetching: false,
  isFetched: false,
  item: {}
}

export const analytics = (state=analyticsState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetching: false,
        isFetched: false,
        item: {},
      }
    }
    case GET_APPLICATION_ANALYTICS.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        item: {}
      })
    }
    case GET_APPLICATION_ANALYTICS.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
      })
    }
    case GET_APPLICATION_ANALYTICS.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        error: action.response
      })
    }
    case GET_APPLICATION_ANALYTICS_SUMMARY.REQUEST: {
      return state
    }
    case GET_APPLICATION_ANALYTICS_SUMMARY.SUCCESS: {
      deepFreeze(state)
      return Object.assign({}, state, {
        isSummaryFetching: false,
        isSummaryFetched: true,
        item: {
          summary: action.response.data,
          chartData: state.item.chartData,
        }
      })
    }
    case GET_APPLICATION_ANALYTICS_SUMMARY.FAIL: {
      return state
    }
  }

  return state
}
