
import PropTypes from 'prop-types';
import React from 'react';
import styles from './EmailCodeForm.css'
import { renderErrorForField } from 'utils/misc'
import classNames from 'classnames'

export default class EmailCodeForm extends React.Component {

  static propTypes = {
    onSend: PropTypes.func.isRequired,
    validation: PropTypes.object,
    isSending: PropTypes.bool.isRequired,
    embedCode: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
  }

  state = {
    to: '',
    message: ''
  }

  componentWillMount() {
    this.setState({ message: this.getDefaultMessageText() })
  }

  getDefaultMessageText = () => {
    let text = `Hey,

I've signed up for Crowdlever (crowdlever.io) and would like to get it embedded into our site.
Could you add the snippet below just before the closing </head> tag across our entire site

Code:

${this.props.embedCode}

Thanks,

${this.props.user.item.firstName}

`
    return text
  }

  handleSend = (evt) => {
    this.props.onSend(this.state.to, this.state.message)
  }

  handleToChange = (evt) => {
    this.setState({ to: evt.target.value })
  }

  handleMessageChange = (evt) => {
    this.setState({ message: evt.target.value })
  }

  render() {
    let btnClassName = classNames('btn', 'btn-primary', {
      'is-loading': this.props.isSending
    })

    return (
      <div className={styles.root}>
        <div className="form-group">
          <label className="label">Your developer's email address</label>
          <input type="text" onChange={this.handleToChange} value={this.state.to} className="form-control" placeholder="developer@widgetinc.com" />
          { renderErrorForField('email', this.props.validation) }
        </div>
        <div className="form-group">
          <label className="label">Message</label>
          <textarea onChange={this.handleMessageChange} value={this.state.message} className="form-control" />
        </div>
        <div className="form-group">
          <button onClick={this.handleSend} className={btnClassName}>Send email</button>
        </div>
      </div>
    )
  }

}
