/* global require */
/* global GIT_VERSION:true */
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'
import Cookies from 'cookies-js'
import Root from './containers/Root'
import rootReducer, { initialState } from './reducers'
import routes from './routes'
import mySaga from './sagas'
import { promiseMiddlware } from 'utils/middleware'
import * as Sentry from '@sentry/browser'

/* eslint-disable-next-line import/no-webpack-loader-syntax */
import '!style-loader!css-loader!react-select/dist/react-select.css';
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import '!style-loader!css-loader!react-toggle/style.css';
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import '!style-loader!css-loader!./base.css';
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import '!style-loader!css-loader!./main.css';

Sentry.init({
  dsn: "https://1238831622534863ad3c937bd90ad485@sentry.io/1563293",
  environment: __DEV__ ? 'development' : 'production',
  release: __DEV__ ? 'frontend@development' : `frontend@${GIT_VERSION}`
})

class CrowdLeverApp {

  startApplication = (elementId, options) => {
    const defaults = {}
    const opts = Object.assign({}, defaults, options)
    const element = document.getElementById(elementId)

    if (!element) {
      throw new Error('Could not find ' + elementId + ' to attach application to')
    }

    let { history, store, routes } = this.setupReactReduxApplication(opts)

    return ReactDOM.render(
      <Root history={history} routes={routes} store={store} />, element
    )
  }

  /**
   * Merge in data from options and any set cookies into the app's state
   *
   * @param {object} initialState The initial state
   * @param {object} options The options passed into the app by the user
   */
  updateInitialState = (initialState, options) => {
    let authData = Cookies.get(AppConfig.COOKIE_AUTH_KEY)
    let defaultApplication = Cookies.get(AppConfig.DEFAULT_APPLICATION)

    // set login/auth information
    if (authData !== undefined) {
      authData = JSON.parse(authData)
      initialState = Object.assign({}, initialState, {
        auth: {
          loggedIn: true,
          isLoggingIn: false,
          authToken: authData.authToken,
          isAuthedViaCookie: true
        }
      })
    }

    // set the default selected application
    if (defaultApplication !== undefined) {
      defaultApplication = JSON.parse(defaultApplication)
      initialState = Object.assign({}, initialState, {
        applications: Object.assign({}, initialState.applications, {
          selectedId: defaultApplication.applicationId,
        })
      })
    }

    return initialState
  }

  setupReactReduxApplication = (options) => {
    const sagaMiddleware = createSagaMiddleware()

    let coreMiddlewares = [
      routerMiddleware(browserHistory),
      thunkMiddleware,
      sagaMiddleware,
      promiseMiddlware
    ]

    if (__DEV__) {
      // require() the library here, so on production builds the source code is not
      // included in the generated bundle
      const createLogger = require('redux-logger')
      coreMiddlewares.push(createLogger())
    }

    const newInitialState = this.updateInitialState(initialState, options)
    const createStoreWithMiddleware = applyMiddleware(...coreMiddlewares)(createStore)
    const store = createStoreWithMiddleware(rootReducer, newInitialState)
    const history = syncHistoryWithStore(browserHistory, store)

    sagaMiddleware.run(mySaga)

    if (window.ga) {
      history.listen( (loc) => {
        window.ga('set', 'page', loc.pathname)
        window.ga('send', 'pageview')
      })
    }

    return {
      history,
      store,
      routes
    }
  }

}

// export to DOM window
window.CrowdLeverApp = new CrowdLeverApp()
