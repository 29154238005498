
import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import DefaultLayoutView      from 'views/DefaultLayout'
import App                    from 'containers/App'
import AccountLayoutView      from 'views/AccountLayout'

import RootView               from 'views/Root'
import DashboardView          from 'views/Dashboard'
import LoginView              from 'views/Login'
import NotificationsView      from 'views/Notifications'
import ApplicationCreateView  from 'views/ApplicationCreate'
import NotificationCreateView from 'views/NotificationCreate'
import NotificationEditView   from 'views/NotificationEdit'
import NotificationCopyView   from 'views/NotificationCopy'
import ThemesView             from 'views/Themes'
import SettingsView           from 'views/Settings'
import RegisterView           from 'views/Register'
import EmbedCodeView          from 'views/EmbedCode'
import AccountView            from 'views/Account'
import BillingView            from 'views/Billing'
import WeeblySetupView        from 'views/WeeblySetup'
import IntegrationView        from 'views/Integration'
import ManageApplicationsView from 'views/ManageApplications'
import IntegrationCreateView  from 'views/IntegrationCreate'
import SSOView                from 'views/SSO'
import ForgotPasswordView     from 'views/ForgotPassword'
import ResetPasswordView      from 'views/ResetPassword'
import EcwidSetupView         from 'views/EcwidSetup'
import ShopifySetupView       from 'views/ShopifySetup'
import PaymentView            from 'views/Payment'
import HelpView               from 'views/Help'

// billing
import SubscriptionView       from 'views/Subscription'
import InvoiceConfirmView     from 'views/InvoiceConfirmView'
import SubscriptionCancelledView from 'views/SubscriptionCancelled'

export default (
  <Route path='/' component={App}>
    <Route component={DefaultLayoutView}>
      <IndexRoute component={DashboardView} />
      <Route path="notifications">
        <IndexRoute component={NotificationsView} />
        <Route path="create" component={NotificationCreateView} />
        <Route path="edit/:notificationId" component={NotificationEditView} />
        <Route path="copy/:notificationId" component={NotificationCopyView} />
      </Route>
      <Route path="applications">
        <Route path="manage" component={ManageApplicationsView} />
        <Route path="create" component={ApplicationCreateView} />
      </Route>
      <Route path="themes">
        <IndexRoute component={ThemesView} />
      </Route>
      <Route path="settings">
        <IndexRoute component={SettingsView} />
      </Route>
      <Route path="embed">
        <IndexRoute component={EmbedCodeView} />
      </Route>
      <Route path="account" component={AccountLayoutView}>
        <IndexRoute component={AccountView} />
        <Route path="billing" component={BillingView} />
      </Route>
      <Route path="integration">
        <Route path="create" component={IntegrationCreateView} />
        <Route path=":integrationId" component={IntegrationView} />
      </Route>
      <Route path="help">
        <IndexRoute component={HelpView} />
      </Route>
      <Route path="invoice/confirm/:id" component={InvoiceConfirmView} />
    </Route>
    <Route path='integration/weebly/setup' component={WeeblySetupView} />
    <Route path='integration/ecwid/setup' component={EcwidSetupView} />
    <Route path='integration/shopify/setup' component={ShopifySetupView} />
    <Route path='subscription' component={SubscriptionView} />
    <Route path='payment' component={PaymentView} />
    <Route path='login' component={LoginView} />
    <Route path='signup' component={RegisterView} />
    <Route path='sso' component={SSOView} />
    <Route path='forgot-password' component={ForgotPasswordView} />
    <Route path='reset' component={ResetPasswordView} />
    <Route path='no-subscription' component={SubscriptionCancelledView} />
  </Route>
)

