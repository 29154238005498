
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { loginUser } from 'actions/auth'
import { push } from 'react-router-redux'
import Loading from 'components/Loading'
import styles from './SSO.css'
import { getQueryVariable } from 'utils/misc'
import { loginSSO } from 'actions/auth'

export class SSOView extends React.Component {

  static propTypes = {
    loading: PropTypes.bool,
  }

  componentWillMount() {
    let auth = getQueryVariable('auth')
    this.props.dispatch(loginSSO({ authToken: auth, redirect: '/' }))
  }

  render = () => {
    return (
      <div className={styles.root}>
        <Loading loading={true} text={'Please wait, logging you in...'} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.auth.isLoggingIn || state.system.isLoading,
  }
}

export default connect(mapStateToProps)(SSOView)

