import React from 'react'
import { connect } from 'react-redux'

import ShopifySubscription from 'views/ShopifySubscription/ShopifySubscription';
import StripeSubscription from 'views/StripeSubscription/StripeSubscription';

export const SubscriptionView = ({ user }) => {
  if (user.origin === 'shopify') {
    return <ShopifySubscription />
  } else {
    return <StripeSubscription />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.item,
    subscription: state.subscription.item,
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(SubscriptionView)
