
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { GET_NOTIFICATIONS, getNotifications, getNotificationsFail, getNotificationsSuccess } from 'actions/notification'
import { CREATE_NOTIFICATION, createNotificationSuccess, createNotificationFail } from 'actions/notification'
import { DELETE_NOTIFICATION, deleteNotificationSuccess, deleteNotificationFail } from 'actions/notification'
import { UPDATE_NOTIFICATION, updateNotificationSuccess, updateNotificationFail } from 'actions/notification'
import { CREATE_IMAGE, createImageSuccess, createImageFail } from 'actions/notification'
import { showGrowl } from 'actions/misc'

export function* getNotificationsSaga(action) {
  const state = yield select()
  const response = yield call(Api.getNotifications, state.auth.authToken, action.args.application.id, action.args.page, action.args.size)

  if (response.metadata.success) {
    yield put(getNotificationsSuccess(response))
  } else {
    yield put(getNotificationsFail(response))
  }
}

export function* createNotification(action) {
  const state = yield select()
  const response = yield call(Api.createNotification, state.auth.authToken, action.args.data)

  if (response.metadata.success) {
    yield put(createNotificationSuccess(response))
    yield put(push('/notifications'))
    yield put(showGrowl('success', 'New notification has been created'))
  } else {
    yield put(createNotificationFail(response))
  }
}

export function* deleteNotificationSaga(action) {
  const state = yield select()
  const notification = action.notification
  const response = yield call(Api.deleteNotification, state.auth.authToken, action.args.notification.id)

  if (response.metadata.success) {
    yield put(deleteNotificationSuccess({ notification: action.args.notification}))
  } else {
    yield put(deleteNotificationFail())
  }
}

export function* updateNotificationSaga(action) {
  const state = yield select()
  const response = yield call(Api.updateNotification, state.auth.authToken, action.args.notification, action.args.data)

  if (response.metadata.success) {
    yield put(updateNotificationSuccess(response))
    yield put(showGrowl('success', 'Notification has been updated'))
  } else {
    yield put(updateNotificationFail(response))
  }
}

export function* createImageSaga(action) {
  const state = yield select()
  const response = yield call(Api.createImage, state.auth.authToken, action.args.file)

  if (response.metadata.success) {
    yield put(createImageSuccess(response, { workspaceId: action.args.workspaceId }))
  } else {
    yield put(createImageFail(response, { workspaceId: action.args.workspaceId }))
  }
}

export default {
  getNotifications: () =>  takeLatest(GET_NOTIFICATIONS.REQUEST, getNotificationsSaga),
  createNotification: () =>  takeLatest(CREATE_NOTIFICATION.REQUEST, createNotification),
  deleteNotification: () => takeLatest(DELETE_NOTIFICATION.REQUEST, deleteNotificationSaga),
  updateNotification: () => takeLatest(UPDATE_NOTIFICATION.REQUEST, updateNotificationSaga),
  createImage: () => takeLatest(CREATE_IMAGE.REQUEST, createImageSaga),
}
