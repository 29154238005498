
import deepFreeze from 'deep-freeze'

import { GET_CREDIT_CARD, CREATE_CREDIT_CARD } from 'actions/payment'
import { LOGOUT_USER } from 'actions/auth'

export const paymentState = {
  isFetching: false,
  isFetched: false,
  serverError: null,
  item: {}
}

export const payment = (state=paymentState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetching: false,
        isFetched: false,
        item: {},
      }
    }
    case GET_CREDIT_CARD.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false,
        serverError: null,
      })
    }
    case GET_CREDIT_CARD.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: true,
        item: action.response.data,
        serverError: null,
      })
    }
    case GET_CREDIT_CARD.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false,
        serverError: action.response.metadata,
        item: {},
      })
    }
    case CREATE_CREDIT_CARD.REQUEST: {
      return Object.assign({}, state, {
        isCreating: true,
        serverError: null,
      })
    }
    case CREATE_CREDIT_CARD.SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false,
        item: action.response.data,
        serverError: null,
      })
    }
    case CREATE_CREDIT_CARD.FAIL: {
      return Object.assign({}, state, {
        isCreating: false,
        serverError: action.response.metadata,
        item: {}
      })
    }
  }

  return state
}
