import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router'
import Select from 'react-select'
import styles from './SideMenu.css'
import ApplicationSelect from 'components/ApplicationSelect'

export default class SideMenu extends React.Component {

  static propTypes = {
    applications: PropTypes.object.isRequired,
    onApplicationChange: PropTypes.func.isRequired,
    integrations: PropTypes.object.isRequired,
    quota: PropTypes.object.isRequired,
  }

  getIntegrationsList = () => {
    let nodes = []
    let ignore = ['crowdlever', 'google_analytics']

    for (let integration of this.props.integrations.items) {
      if (ignore.indexOf(integration.kind) != -1) {
        continue
      }
      nodes.push(
          <li className="nav-item" key={integration.id}>
            <Link className="nav-link" to={`/integration/${integration.id}`}><i className="far fa-cog fa-fw"></i> {integration.name}</Link>
          </li>
      )
    }
    return nodes
  }

  renderAppQuotaMessage = () => {
    if (!this.props.quota.isFetched) {
      return
    }

    return (
      <span>({this.props.quota.item.appsUsed}/{this.props.quota.item.appsQuota})</span>
    )
  }

  renderFullSideMenu = () => {
    return (
      <div className={styles.root}>
        <div>
          <ApplicationSelect
            applications={this.props.applications}
            onChange={this.props.onApplicationChange}
            mode={'always-visible'}
          />
        </div>

        <div className="mt-5 text-muted">
          Website {this.renderAppQuotaMessage()}
        </div>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link" to={'/applications/manage'}><i className="far fa-cog fa-fw"></i> Manage</Link>
          </li>
          { this.props.quota.isFetched && this.props.quota.item.canAddApps ?
            <li className="nav-item">
              <Link className="nav-link" to={'/applications/create'}><i className="far fa-plus fa-fw"></i> Add website</Link>
            </li>
            : null
          }
        </ul>

        <div className="mt-2 text-muted">
          General
        </div>
        <ul className="nav flex-column">
          <li className="nav-item"><Link className="nav-link" to={'/'}><i className="far fa-chart-bar fa-fw"></i> Dashboard</Link></li>
          <li className="nav-item"><Link className="nav-link" to={'/notifications'}><i className="far fa-comment fa-fw"></i> Notifications</Link></li>
          <li className="nav-item"><Link className="nav-link" to={'/themes'}><i className="far fa-paint-brush fa-fw"></i> Theme</Link></li>
          <li className="nav-item"><Link className="nav-link" to={'/settings'}><i className="far fa-sliders-v-square fa-fw"></i> Settings</Link></li>
          <li className="nav-item"><Link className="nav-link" to={'/embed'}><i className="far fa-code fa-fw"></i> Embed</Link></li>
        </ul>

        <div className="mt-2 text-muted">
         App Integrations
        </div>
        <ul className="nav flex-column">
          <li className="nav-item"><Link className="nav-link" to={'/integration/create'}><i className="far fa-plus fa-fw"></i> Add</Link></li>
          {this.getIntegrationsList()}
        </ul>
      </div>
    )
  }

  renderNoApplicationsMenu = () => {
    return (
      <div className={styles.root}>
        <p>Hey there</p>
        <p>It looks like you haven't created any websites yet.</p>
        <p>Use the form on the right to add your website.</p>
      </div>
    )
  }

  render() {
    if (this.props.applications.items.length == 0) {
      return this.renderNoApplicationsMenu()
    } else {
      return this.renderFullSideMenu()
    }
  }
}
