
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import styles from './AccountLayout.css'
import Tabs from 'components/Tabs'

export class AccountLayoutView extends React.Component {

  static propTypes = {
    tab: PropTypes.string,
  }

  state = {
    selectedTab: null,
  }

  getTabs = () => {
    return [
      { id: 1, label: 'Basic', url: '/account' },
      { id: 2, label: 'Billing', url: '/account/billing' },
    ]
  }

  componentWillMount() {
    let tab = null
    if (this.props.tab) {
      tab = this.getTabs().find(t => t.id == this.props.tab)
    } else {
      tab = this.getTabs()[0]
    }

    this.setState({ selectedTab: tab })
  }


  handleTabClick = (tab) => {
    this.setState({ selectedTab: tab })

    if (this.props.location.pathname != tab.url) {
      this.props.dispatch(push({ pathname: tab.url, query: { tab: tab.id }} ))
    }
  }

  render() {
    return (
      <div className={styles.root}>
        <h2>Account</h2>
        <Tabs tabs={this.getTabs()} onClick={this.handleTabClick} selectedTab={this.state.selectedTab} />
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    tab: ownProps.location.query.tab,
  }
}

export default connect(mapStateToProps)(AccountLayoutView)

