
import deepFreeze from 'deep-freeze'

import { LOGIN_USER, LOGOUT_USER } from 'actions/auth'
import { GET_USER, CREATE_USER } from 'actions/user'

export const authState = {
  loggedIn: false,
  isLoggingIn: false,
  authToken: null,
  loginError: null,
  isAuthedViaCookie: null,
}

export const auth = (state=authState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return Object.assign({}, state, {
        loggedIn: false,
        authToken: null,
        loginError: null,
        isAuthedViaCookie: null,
      })
    }
    case LOGIN_USER.REQUEST: {
      return Object.assign({}, state, {
        isLoggingIn: true
      })
    }
    case LOGIN_USER.SUCCESS: {
      return Object.assign({}, state, {
        isLoggingIn: false,
        loggedIn: true,
        authToken: action.response.data.authToken,
        loginError: null,
      })
    }
    case LOGIN_USER.FAIL: {
      return Object.assign({}, state, {
        isLoggingIn: false,
        loginError: action.response.metadata
      })
    }
    case CREATE_USER.SUCCESS: {
      return Object.assign({}, state, {
        isLoggingIn: false,
        loggedIn: true,
        authToken: action.response.data.authToken
      })
    }

  }

  return state
}

