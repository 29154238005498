// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.DatePicker__root {\n    display: inline-block;\n    width: 100px;\n}\n\n.DatePicker__overlay {\n    position: absolute;\n    background: white;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);\n    z-index: 999;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "DatePicker__root",
	"overlay": "DatePicker__overlay"
};
module.exports = exports;
