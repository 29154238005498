import PropTypes from 'prop-types';
import React from 'react';
import styles from './TextEditor.css'
import RichTextEditor from 'react-rte'

export default class TextEditor extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  state = {
    value: '',
  }

  componentWillMount() {
    if (this.props.value) {
      this.setState({ value: RichTextEditor.createValueFromString(this.props.value, 'html') })
    } else {
      this.setState({ value: RichTextEditor.createEmptyValue() })
    }
  }

  getToolbarConfig = () => {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS',  'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ]
    }

    return toolbarConfig
  }

  handleMessageChange = (value) => {
    this.setState({ value: value })
    this.props.onChange( value.toString('html') )
  }

  render() {
    return (
      <RichTextEditor
        toolbarConfig={this.getToolbarConfig()}
        onChange={this.handleMessageChange}
        value={this.state.value}
        toolbarClassName={styles.editorToolbar}
        editorClassName={styles.editor}
      />
  )
  }
}
