// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.TextEditor__editorToolbar {\n  font-size: 14px;\n  margin: auto !important;\n  padding: 10px 10px 5px !important;\n  border-bottom: none !important;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  background-color: #efefef;\n}\n\n.TextEditor__editor {\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", \"Helvetica\", \"Arial\", sans-serif !important;\n}\n", ""]);
// Exports
exports.locals = {
	"editorToolbar": "TextEditor__editorToolbar",
	"editor": "TextEditor__editor"
};
module.exports = exports;
