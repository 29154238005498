// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionCancelled__root {\n    text-align: center;\n}\n\n.SubscriptionCancelled__content {\n    width: 400px;\n    margin: 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "SubscriptionCancelled__root",
	"content": "SubscriptionCancelled__content"
};
module.exports = exports;
