
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_NOTIFICATIONS = createRequestTypes('GET_NOTIFICATIONS')
export const CREATE_NOTIFICATION = createRequestTypes('CREATE_NOTIFICATION')
export const CREATE_IMAGE = createRequestTypes('CREATE_IMAGE')
export const DELETE_IMAGE = createRequestTypes('DELETE_IMAGE')
export const DELETE_NOTIFICATION = createRequestTypes('DELETE_NOTIFICATION')
export const COPY_NOTIFICATION = createRequestTypes('COPY_NOTIFICATION')
export const UPDATE_NOTIFICATION = createRequestTypes('UPDATE_NOTIFICATION')

export const [
  createNotification,
  createNotificationSuccess,
  createNotificationFail
] = createAsyncRequestActions(CREATE_NOTIFICATION)

export const [
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFail
] = createAsyncRequestActions(GET_NOTIFICATIONS)

export const [
  createImage,
  createImageSuccess,
  createImageFail
] = createAsyncRequestActions(CREATE_IMAGE)

export const [
  deleteImage,
  deleteImageSuccess,
  deleteImageFail,
] = createAsyncRequestActions(DELETE_IMAGE)

export const [
  deleteNotification,
  deleteNotificationSuccess,
  deleteNotificationFail
] = createAsyncRequestActions(DELETE_NOTIFICATION)

export const [
  updateNotification,
  updateNotificationSuccess,
  updateNotificationFail
] = createAsyncRequestActions(UPDATE_NOTIFICATION)

