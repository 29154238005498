import React from 'react'
import moment from 'moment'
import { currencyToSymbol } from 'utils/misc'

const DATE_FORMAT = 'Do MMMM YYYY'

export const getSubscriptionDescription = (subscription) => {
  const plan = subscription.plan
  const symbol = currencyToSymbol(plan.currency)
  const nextBillingDate = subscription.periodEndAt
  const nextDate = moment(new Date(nextBillingDate*1000)).format(DATE_FORMAT)

  if (plan.kind === 'free_plan') {
    return <div>You are currently on the {plan.name} plan.</div>
  }

  // subscription is about to be cancelled at the end of the billing period to free
  if (subscription.cancelAtPeriodEnd) {
    let endDate = moment(subscription.periodEndAt*1000).format(DATE_FORMAT)
    return (
      <div>
        <p>
          You are currently on the {plan.name} plan, billed at <b>{symbol}{plan.amount}</b> per <b>{plan.billingIntervalSingular}</b>.
        </p>
        <div className="notification">
          <p>
            Your subscription is set to be cancelled at the end of your billing period on {endDate}. You will not be charged again.
          </p>
        </div>
      </div>
    )
  } else {
    if (subscription.status === 'trialing') {
      let trialEndDate = moment(subscription.trialEndAt*1000).format(DATE_FORMAT)
      return (
        <div>
          <p>You are currently <b>trialing</b> the {plan.name} plan. The trial period will end on {trialEndDate}.</p>
          <p>Once your trial period ends you will be charged <b>{symbol}{plan.amount}</b> per <b>{plan.billingIntervalSingular}</b></p>
        </div>
      )
    } else {
      return (
        <p>
          You are currently on the {plan.name} plan, billed at <b>{symbol}{plan.amount}</b> per <b>{plan.billingIntervalSingular}</b>.
          Your next payment will be on {nextDate}.
        </p>
      )
    }
  }
}
