
const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAIL    = 'FAIL'

export const createRequestTypes = (base) => {
  return [REQUEST, SUCCESS, FAIL].reduce((acc, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const createAsyncRequestActions = (baseActionTypes) => {
  const request = (args) => ({ type: baseActionTypes.REQUEST, args: args })
  const success = (response, args) => ({ type: baseActionTypes.SUCCESS, response: response, args: args })
  const fail = (response, args) => ({ type: baseActionTypes.FAIL, response: response, args: args })

  return [request, success, fail]
}
