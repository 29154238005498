// google oauth saga

import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'
import queryString from 'query-string'

import { CONNECT_GOOGLE_ANALYTICS, connectGoogleAnalyticsSuccess, connectGoogleAnalyticsFail } from 'actions/ga'
import { GET_GOOGLE_ACCOUNT, getGoogleAccountSuccess, getGoogleAccountFail } from 'actions/ga'
import { UPDATE_GOOGLE_ACCOUNT, updateGoogleAccountSuccess, updateGoogleAccountFail } from 'actions/ga'
import { getApplicationAnalyticsSummary } from 'actions/application'

// special saga which will poll the api for conversion stats once the ga account
// has been connected, and the goal has been setup. We have to wait on a task in the
// backend to complete to get this data
function* conversionRateDataCheckerSaga(authToken, application) {
  let runs = 0
  const sleep = 2000
  const pollMaxTime = 60 * 5 // poll for a max of 5 minutes then give up
  const pollMax = (pollMaxTime * 1000) / sleep

  while (true) {
    const response = yield call(Api.getGoogleAccount, authToken, application)
    console.log('polling backend for conversion data')

    if (!response.metadata.success) {
      return
    }

    if (response.data.goals[0].lastFetchedAt > 0) {
      yield put(getGoogleAccountSuccess(response))
      yield put(getApplicationAnalyticsSummary({ application: application }))
      return
    }

    if (runs > pollMax) {
      return
    }
    runs = runs + 1
    yield call(delay, sleep)
  }
}

// this will poll the opened oauth connect window for changes to it's url
// if auth was successful, it will close the popup and return a success
// message, otherwise it'll return an error
const listenForCredentials = (popup) => {
  return new Promise((resolve, reject) => {
    const checkForUrlChanges = (popup) => {
      let uri = null
      try {
        uri = queryString.parse(popup.location.search)
      } catch (e) {}
      console.log(uri)

      if (uri && uri.returned) {
        if (uri.connected) {
          popup.close()
          resolve({ message: 'Google authentication succeeded' })
        }
        if (uri.error) {
          popup.close()
          reject({ message: 'There was an error with authentication' })
        }
      } else if (popup.closed) {
        reject({ message: 'Google authentication was cancelled' })
      } else {
        setTimeout(() => {
          checkForUrlChanges(popup)
        }, 500)
      }
    }
    checkForUrlChanges(popup)
  })
  .then(response => {
    return { metadata: { success: true, message: response.message }}
  })
  .catch(error => {
    return { metadata: { success: false, message: error.message  }}
  })
}

export function* connectGoogleAnalyticsSaga(action) {
  const state = yield select()
  const response = yield call(Api.getGoogleAnalyticsOAuthRedirectUrl, state.auth.authToken, action.args.application)

  if (!response.metadata.success) {
    return
  }

  // change the url of the popup to the one we want
  action.args.popup.location = response.data.url

  // listen for location changes on the popup
  let authResponse = yield call(listenForCredentials, action.args.popup)

  if (authResponse.metadata.success) {
    yield put(connectGoogleAnalyticsSuccess(authResponse))
    yield call(getGoogleAccountSaga, action)
  } else {
    yield put(connectGoogleAnalyticsFail(authResponse))
  }
}

export function* getGoogleAccountSaga(action) {
  const state = yield select()
  const response = yield call(Api.getGoogleAccount, state.auth.authToken, action.args.application)

  if (response.metadata.success) {
    yield put(getGoogleAccountSuccess(response))

    // start polling the backend for an update on the ga analytics fetch task
    if (response.data.isSetup && response.data.oauth.connected && !response.data.goals[0].lastFetchedAt) {
      yield spawn(conversionRateDataCheckerSaga, state.auth.authToken, action.args.application)
    }
  } else {
    yield put(getGoogleAccountFail(response))
  }
}

export function* updateGoogleAccountSaga(action) {
  const state = yield select()
  const response = yield call(Api.updateGoogleAccount, state.auth.authToken,  action.args.application, action.args.data)

  if (response.metadata.success) {
    yield put(updateGoogleAccountSuccess(response))
    yield spawn(conversionRateDataCheckerSaga, state.auth.authToken, action.args.application)
  } else {
    yield put(updateGoogleAccountFail(response))
  }
}

export default {
  connectGoogleAnalytics: () => takeLatest(CONNECT_GOOGLE_ANALYTICS.REQUEST, connectGoogleAnalyticsSaga),
  getGoogleAccount: () => takeLatest(GET_GOOGLE_ACCOUNT.REQUEST, getGoogleAccountSaga),
  updateGoogleAccount: () => takeLatest(UPDATE_GOOGLE_ACCOUNT.REQUEST, updateGoogleAccountSaga),
}


