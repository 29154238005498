// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimePicker__root {\n    display: inline-block;\n    width: 100px;\n    margin-left: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "TimePicker__root"
};
module.exports = exports;
