
import { takeEvery, takeLatest, delay } from 'redux-saga'
import { call, put, fork, select, spawn } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import Api from 'utils/Api'

import { GET_CREDIT_CARD, getCreditCardSuccess, getCreditCardFail } from 'actions/payment'
import { CREATE_CREDIT_CARD, createCreditCardSuccess, createCreditCardFail } from 'actions/payment'

export function* getCreditCardSaga(action) {
  const state = yield select()
  const response = yield call(Api.getCreditCard, state.auth.authToken)

  if (response.metadata.success) {
    yield put(getCreditCardSuccess(response))
  } else {
    yield put(getCreditCardFail(response))
  }
}

export function* createCreditCardSaga(action) {
  const state = yield select()
  const response = yield call(Api.createCreditCard, state.auth.authToken, action.args.card)
  if (!response.metadata.success) {
    yield put(createCreditCardFail(response))
  } else {
    yield put(createCreditCardSuccess(response))
    if (action.args.redirect) {
      yield put(push(action.args.redirect))
    }
  }
}

export default {
  getCreditCard: () => takeLatest(GET_CREDIT_CARD.REQUEST, getCreditCardSaga),
  createCreditCard: () => takeLatest(CREATE_CREDIT_CARD.REQUEST, createCreditCardSaga),
}
