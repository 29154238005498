
import React from 'react'
import Cookies from 'cookies-js'

/**
 * This renders client side errors from the validator
 *
 * @param {string} fieldName The name of field we want to render errors for
 * @param {object} validationResult The result from the validation
 */
export const renderErrorForField = (fieldName, validationResult) => {
  if (!validationResult) {
    return
  }
  for (let fieldItem of validationResult.errors) {
    if (fieldItem.attribute == fieldName) {
      let message = fieldItem.messages ? fieldItem.messages[0] : fieldItem.message
      return (
          <span className="help is-danger">{message}</span>
      )
    }
  }
}

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export const saveUserDataToBrowser = (data) => {
  Cookies.set(AppConfig.COOKIE_AUTH_KEY, JSON.stringify({
    authToken: data.authToken,
    firstName: data.firstName,
    lastName: data.lastName,
    emailAddress: data.emailAddress
  }), { expires: 3600 })
}

export const clearUserDataFromBrowser = () => {
  Cookies.expire(AppConfig.COOKIE_AUTH_KEY)
  Cookies.expire(AppConfig.DEFAULT_APPLICATION)
}

export const saveDefaultSelectedApplication = (application) => {
  Cookies.set(AppConfig.DEFAULT_APPLICATION, JSON.stringify({'applicationId': application.id}), { expires: 3600 })
}

export const pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export const currencyToSymbol = (code) => {
  const map = {
    'gbp': '£',
    'usd': '$',
  }

  return map[code.toLowerCase()]
}

export const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
    }
  }
}

export const insertStripeJsLibrary = (setStripe) => {
  const id = 'stripe-js'
  let s = document.getElementById(id)
  if (!s) {
    let tag = document.createElement('script')
    tag.id = id
    tag.type = 'text/javascript'
    tag.src = 'https://js.stripe.com/v3/'
    tag.onload = function() {
      setStripe(window.Stripe(AppConfig.STRIPE_PUBLIC_KEY))
    }
    document.getElementsByTagName('head')[0].appendChild(tag)
  } else {
    setStripe(window.Stripe(AppConfig.STRIPE_PUBLIC_KEY))
  }
}