// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationRow__root {\n    display: flex;\n}\n\n.NotificationRow__image {\n    margin-right: 1rem;\n    overflow: hidden;\n    width: 64px;\n    height: 64px;\n}\n\n.NotificationRow__image img {\n    width: 100%;\n}\n\n.NotificationRow__right {\n    width: 100%;\n}\n\n.NotificationRow__footer {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.NotificationRow__created {\n}\n\n.NotificationRow__controls {\n    text-align: right;\n}\n\n.NotificationRow__control a {\n    margin-left: 5px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "NotificationRow__root",
	"image": "NotificationRow__image",
	"right": "NotificationRow__right",
	"footer": "NotificationRow__footer",
	"created": "NotificationRow__created",
	"controls": "NotificationRow__controls",
	"control": "NotificationRow__control"
};
module.exports = exports;
