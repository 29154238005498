import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const GET_CREDIT_CARD = createRequestTypes('GET_CREDIT_CARD')
export const CREATE_CREDIT_CARD = createRequestTypes('CREATE_CREDIT_CARD')

export const [
  getCreditCard,
  getCreditCardSuccess,
  getCreditCardFail
] = createAsyncRequestActions(GET_CREDIT_CARD)

export const [
  createCreditCard,
  createCreditCardSuccess,
  createCreditCardFail,
] = createAsyncRequestActions(CREATE_CREDIT_CARD)
