import PropTypes from 'prop-types';
import React from 'react';
import styles from './Tabs.css'
import cx from 'classnames'

export default class Tabs extends React.Component {

  static propTypes = {
    tabs: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    selectedTab: PropTypes.object.isRequired
  }

  isSelected = (tab) => {
    if (!this.props.selectedTab) {
      return false
    }
    if (this.props.selectedTab.label === tab.label) {
      return true
    }
    return false
  }

  createTabClickHandler = (item) => {
    return (evt) => {
      evt.preventDefault()
      this.props.onClick(item)
    }
  }

  renderTabs = () => {
    let nodes = []
    for (let item of this.props.tabs) {
      nodes.push(
        <li key={item.label} className='nav-item'>
          <a href="#tab" onClick={this.createTabClickHandler(item)} className={cx('nav-link', { 'active': this.isSelected(item)})} >
            { item.icon ? <span className="mr-1">{ item.icon }</span> : null }
            <span>{item.label}</span>
          </a>
        </li>
      )
    }

    return nodes
  }

  render() {
    return (
      <ul className="nav nav-tabs">
        {this.renderTabs()}
      </ul>
    )
  }
}
