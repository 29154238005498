
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import ApplicationForm from 'components/ApplicationForm'
import { updateApplication } from 'actions/application'
import styles from './ManageApplications.css'

class ManageApplicationsView extends React.Component {

  static propTypes ={
    applications: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
  }

  handleSave = (evt, data) => {
    evt.preventDefault()
    let application = {
      id: this.props.selected.id,
      ...data
    }
    this.props.dispatch(updateApplication({ application: application }))
  }

  render() {
    return (
      <div className={styles.root}>
        <h2>Manage websites</h2>
        <ApplicationForm buttonName={'Update website'} onSave={this.handleSave} application={this.props.selected} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    applications: state.applications,
    selected: state.applications.items.find(app => app.id == state.applications.selectedId),
  }
}

export default connect(mapStateToProps)(ManageApplicationsView)

