import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select'
import styles from './TimeDeltaPicker.css'
import cx from 'classnames'

export default class TimeDeltaPicker extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    unit: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  getOptions = () => {
    let values = [
      { value: 'hour', label: 'Hours' },
      { value: 'day', label: 'Days' },
      { value: 'week', label: 'Weeks' },
      { value: 'month', label: 'Months' }
    ]

    return values
  }

  handleChange = (data) => {
    let d = Object.assign({ unit: this.props.unit, value: this.props.value }, data)
    this.props.onChange(d)
  }

  render() {
    return (
        <div className={cx(styles.root, this.props.className)}>
          <div className={styles.inner}>
            <input className={cx('form-control', styles.input)} type="text" value={this.props.value} onChange={(evt) => this.handleChange({ value: evt.target.value }) } />
            <div className={styles.selectContainer}>
              <Select  options={this.getOptions()} value={this.props.unit} onChange={(option) => this.handleChange({ unit: option.value  })} />
            </div>
          </div>
        </div>
    )
  }
}

