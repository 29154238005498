// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ThemePreview__root {\n    background-repeat: repeat;\n    min-height: 800px;\n    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.4);\n    position: relative;\n}\n\n\n.ThemePreview__notificationContainer {\n    position: absolute;\n    bottom: 10px;\n}\n\n.ThemePreview__notificationContainer > div {\n    height: 100%;\n}\n\n.ThemePreview__left {\n    left: 10px;\n}\n\n.ThemePreview__right {\n    right: 210px;\n}\n\n.ThemePreview__normal {\n    width: 300px;\n}\n\n.ThemePreview__normal .cl-notification-image {\n    width: 70px;\n    height: 70px;\n}\n\n.ThemePreview__normal img {\n    width: 60px;\n}\n\n.ThemePreview__large {\n    width: 400px;\n}\n\n.ThemePreview__large .cl-notification-image {\n    width: 100px;\n    height: 100px;\n}\n\n.ThemePreview__large .cl-notification-message {\n    font-size: 16px;\n}\n\n.ThemePreview__large img {\n    width: 90px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "ThemePreview__root",
	"notificationContainer": "ThemePreview__notificationContainer",
	"left": "ThemePreview__left",
	"right": "ThemePreview__right",
	"normal": "ThemePreview__normal",
	"large": "ThemePreview__large"
};
module.exports = exports;
