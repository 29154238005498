// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.App__root {\n    padding: 2em;\n}\n\n.App__appContainer {\n    background-color: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "App__root",
	"appContainer": "App__appContainer"
};
module.exports = exports;
