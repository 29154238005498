// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SideMenu__root {\n\n}\n\n.SideMenu__selectOuter {\n}\n\n\n.SideMenu__disabled {\n    opacity: 0.5;\n    pointer-events: none;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "SideMenu__root",
	"selectOuter": "SideMenu__selectOuter",
	"disabled": "SideMenu__disabled"
};
module.exports = exports;
