// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CreditCardForm__root {\n}\n\n.StripeElement {\n    display: block;\n    padding: 10px 14px;\n    border-radius: 0.1rem;\n    background: white;\n    border: 0.05rem solid #d3d6de\n  }\n\n.StripeElement--focus {\n    border-color: #00d1b2;\n    -webkit-transition: all 150ms ease;\n    transition: all 150ms ease;\n    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);\n}\n\n.CreditCardForm__error {\n    color: red;\n    margin: 0 0 1rem 0;\n}\n\n.CreditCardForm__controls {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 1rem;\n}\n\n.CreditCardForm__secure {\n    align-self: center;\n}\n\n.CreditCardForm__secure > .fa {\n    color: #53c653;\n}", ""]);
// Exports
exports.locals = {
	"root": "CreditCardForm__root",
	"error": "CreditCardForm__error",
	"controls": "CreditCardForm__controls",
	"secure": "CreditCardForm__secure"
};
module.exports = exports;
