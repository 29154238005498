// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.ShopifySetup__root {\n    margin: 50px auto 0 auto;\n    width: 400px;\n}\n\n.ShopifySetup__buttonRow {\n    margin-top: 20px;\n}\n\n.ShopifySetup__connect {\n    text-align: center;\n    margin-bottom: 10px;\n}\n\n.ShopifySetup__vendor {\n    width: 64px;\n    display: inline-block;\n}\n\n.ShopifySetup__arrow {\n    display: inline-block;\n    width: 64px;\n    margin-left: 10px;\n}\n\n.ShopifySetup__logo {\n    margin-left: 10px;\n    display: inline-block;\n    width: 64px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ShopifySetup__root",
	"buttonRow": "ShopifySetup__buttonRow",
	"connect": "ShopifySetup__connect",
	"vendor": "ShopifySetup__vendor",
	"arrow": "ShopifySetup__arrow",
	"logo": "ShopifySetup__logo"
};
module.exports = exports;
