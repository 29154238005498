import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'containers/Modal/Modal'

export const ModalRoot = ({ modal }) => {
  if (!modal) {
    return null
  }

  return ReactDOM.createPortal(
    <Modal>{modal}</Modal>, document.body)
}

export default ModalRoot