import React from 'react'
import styles from './Pagination.css'

const getPages = (currentPage, totalPages, leftEdge = 2, leftCurrent = 3, rightCurrent = 3, rightEdge = 2) => {
  let last = 0
  let ret = []
  for (let i = 1; i < totalPages + 1; i++) {
    if (i <= leftEdge || (i > currentPage - leftCurrent - 1 && i < currentPage + rightCurrent) || i > totalPages - rightEdge) {
      if (last + 1 !== i) {
        ret.push(null)
      }
      ret.push(i)
      last = i
    }
  }
  return ret
}

const getTotalPages = (total, size) => {
  return Math.ceil(total / size)
}

const createClickHandler = (page, onClick) => {
  return (evt) => {
    evt.preventDefault()
    onClick(page)
  }
}

const PageLinks = ({ currentPage, pages, onClick }) => {
  let nodes = []
  for (let page of pages) {
    if (page) {
      if (page !== currentPage) {
        nodes.push(
          <li className="page-item" key={page}>
            <a className="page-link" href={`#page-${page}`} id={`page-${page}`} onClick={createClickHandler(page, onClick)}>{page}</a>
          </li>
        )
      } else {
        nodes.push(
          <li key={page} className="page-item active">
            <a className="page-link" href={`#page-${page}`} id={`page-${page}`}>{page}</a>
          </li>
        )
      }
    } else {
      nodes.push(
        <li key={'spacer'} className="page-item"><span>...</span></li>
      )
    }
  }

  return <>{nodes}</>
}

const PreviousLink = ({ page, onClick }) => {
  if (page === 1) {
    return <a className="page-link disabled" href="#prev" onClick={evt => evt.preventDefault()} >Previous</a>
  }

  return <a className="page-link" href="#prev" id="prev" onClick={createClickHandler(page-1, onClick)}>Previous</a>
}

const NextLink = ({ page, totalPages, onClick }) => {
  if (page === totalPages) {
    return <a className="page-link disabled" href="#next" onClick={evt => evt.preventDefault()}>Next page</a>
  }

  return <a className="page-link" href="#next" id="next" onClick={createClickHandler(page+1, onClick)}>Next page</a>
}

const Pagination = ({ page, total, size, onClick }) => {
  const totalPages = getTotalPages(total, size)
  const pages = getPages(page, totalPages)
   
  if (totalPages <= 1) {
    return <div></div>
  }

  return (
    <div className={styles.root}>
      <nav>
        <ul className="pagination">
          <li className="page-item">
            <PreviousLink page={page} onClick={onClick} />
          </li>
          <PageLinks pages={pages} currentPage={page} onClick={onClick} />
          <li className="page-item">
            <NextLink page={page} totalPages={totalPages} onClick={onClick} />
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Pagination