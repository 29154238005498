
import PropTypes from 'prop-types';
import React from 'react';
import styles from './AnalyticsSummary.css'
import Loading from 'components/Loading'
import { currencyToSymbol } from 'utils/misc'
import numeral from 'numeral'
import className from 'classnames'

export default class AnalyticsSummary extends React.Component {

  static propTypes = {
    summary: PropTypes.object,
    quota: PropTypes.object,
    subscription: PropTypes.object,
    onUpgradeClick: PropTypes.func.isRequired,
  }

  getUpgradeMessage = () => {
    let classN = className({
      [styles.stat]: true,
      [styles.upgrade]: true,
    })
    return (
        <div className={classN}>
          <p className={styles.heading}>Get access to conversion tracking</p>
          <p className={styles.text}><a onClick={this.props.onUpgradeClick}>Upgrade &raquo;</a></p>
        </div>
      )
  }

  getConversionGoalSummary = (summary) => {
    if (!summary.goal.exists) {
      return null
    }

    if (summary.goal.lastFetchedAt === null) {
     return (
        <div className={styles.stat}>
          <p className={styles.heading}>Conversion</p>
          <p className={styles.textSmall}>Loading data...</p>
        </div>
      )
    }

    let conversion = (
      <div key="conversions" className={styles.stat}>
        <p className={styles.heading}>Conversion</p>
        <p className={styles.text}>{numeral(summary.goal.conversions).format()}</p>
      </div>
    )

    let conversionValue = (
      <div key="conversions-value" className={styles.stat}>
        <p className={styles.heading}>Value</p>
        <p className={styles.text}>{currencyToSymbol(summary.goal.currency)}{numeral(summary.goal.conversionsValue).format()}</p>
      </div>
    )
    return [conversion, conversionValue]
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.level}>
          <div className={styles.stat}>
            <p className={styles.heading}>Total views</p>
            <p className={styles.text}>{numeral(this.props.summary.totalViews).format()}</p>
          </div>
          <div className={styles.stat}>
            <p className={styles.heading}>Total clicks</p>
            <p className={styles.text}>{numeral(this.props.summary.totalClicks).format()}</p>
          </div>
          <div className={styles.stat}>
            <p className={styles.heading}>Active notifications</p>
            <p className={styles.text}>{numeral(this.props.summary.totalQueued).format()}</p>
          </div>
          <div className={styles.stat}>
            <p className={styles.heading}>Click through rate</p>
            <p className={styles.text}>{this.props.summary.ctr}%</p>
          </div>
        </div>
        <div className={styles.level}>
          <div className={styles.stat}>
            <p className={styles.heading}>Event views quota used</p>
            <p className={styles.text}>{this.props.quota.item.eventViewsPercentageUsed}%</p>
          </div>
        </div>
      </div>
    )
  }

}
