
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

export class RootView extends React.Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentWillMount() {
    if (!this.props.auth.loggedIn) {
      return this.props.dispatch(push('/login'))
    } else {
      return this.props.dispatch(push('/'))
    }
  }

  render() {
    return (
      <div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

export default connect(mapStateToProps)(RootView)

