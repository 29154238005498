// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Nav__root {\n    margin-bottom: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n    padding: 1rem;\n}\n\n.Nav__brand {\n    width: 150px;\n}\n\n.Nav__brand img {\n    width: 100%;\n}\n\n.Nav__account {\n    display: flex;\n}\n\n.Nav__account > * {\n    margin-left: 0.25rem;\n}", ""]);
// Exports
exports.locals = {
	"root": "Nav__root",
	"brand": "Nav__brand",
	"account": "Nav__account"
};
module.exports = exports;
