import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router'
import styles from './Nav.css'
import logo from 'assets/crowdlever.png'

export default class Nav extends React.Component {

  static propTypes = {
    user: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.brand}>
          <Link to={'/'}>
            <img src={logo} />
          </Link>
        </div>
        <div className={styles.account}>
          <div>
            Welcome back {this.props.user.item.firstName}
          </div>
          <Link to={'/account'}>
            Your account <i className="far fa-fw fa-user-circle" />
          </Link>
          <Link to={'/help'}>
            Help <i className="far fa-fw fa-question-circle" />
          </Link>
          <a href="#logout" onClick={this.props.onLogout}>
            Logout <i className="far fa-fw fa-sign-out" />
          </a>
        </div>
      </div>
    )
  }
}
