// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DefaultLayout__root {\n    background-color: #fff;\n}\n\n.DefaultLayout__content {\n    padding-bottom: 1rem;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "DefaultLayout__root",
	"content": "DefaultLayout__content"
};
module.exports = exports;
