// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.ShopifyIntegration__section {\n    margin-bottom: 20px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"section": "ShopifyIntegration__section"
};
module.exports = exports;
