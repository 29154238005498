// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimeDeltaPicker__root {\n    display: inline-block;\n    width: auto;\n}\n\n.TimeDeltaPicker__inner {\n    display: flex;\n}\n\n.TimeDeltaPicker__selectContainer {\n    width: 10em;\n    display: inline-block;\n    margin-left: 10px;\n}\n\n.TimeDeltaPicker__input {\n    width: 10rem !important;\n}\n\n/* react-select component styling */\n\n.TimeDeltaPicker__select {\n}\n\n.TimeDeltaPicker__select .Select-control {\n    height: 40px;\n}\n\n.TimeDeltaPicker__select .Select-placeholder {\n    line-height: 40px;\n}\n\n.TimeDeltaPicker__select .Select-value {\n    line-height: 40px !important;\n}\n\n", ""]);
// Exports
exports.locals = {
	"root": "TimeDeltaPicker__root",
	"inner": "TimeDeltaPicker__inner",
	"selectContainer": "TimeDeltaPicker__selectContainer",
	"input": "TimeDeltaPicker__input",
	"select": "TimeDeltaPicker__select"
};
module.exports = exports;
