import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames'

import styles from './NotificationForm.css'
import { renderErrorForField } from 'utils/misc'
import Dropzone from 'react-dropzone'
import DateTimePicker from 'components/DateTimePicker'
import TextEditor from 'components/TextEditor'

export default class NotificationForm extends React.Component {

  static propTypes = {
    notification: PropTypes.object,
    buttonName: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onImageUpload: PropTypes.func.isRequired,
    onImageDelete: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    isUploading: PropTypes.bool,
    isUploaded: PropTypes.bool,
    validation: PropTypes.object,
  }

  static defaultProps = {
    buttonName: 'Save',
    isSaving: false,
  }

  state = {
    publishedAt: new Date(),
    message: '',
    url: '',
    hasPreview: false,
    files: [],
  }

  componentWillMount() {
    if (this.props.notification) {
      this.setState({
        message: this.props.notification.message,
        url: this.props.notification.url,
        files: this.props.notification.image ? [{
          preview: this.props.notification.image.url
        }] : [],
        hasPreview: this.props.notification.image ? true : false,
        publishedAt: new Date(this.props.notification.publishedAt*1000),
      })
    }
  }

  handleSave = (evt) => {
    const data = {
      message: this.state.message,
      url: this.state.url,
      publishedAt: parseInt(this.state.publishedAt/1000),
    }

    this.props.onSave(evt, data)
  }

  handleMessageChange = (value) => {
    this.setState({ message: value })
  }

  handleUrlChange = (evt) => {
    this.setState({ url: evt.target.value })
  }

  handleDrop = (files) => {
    this.setState({
      hasPreview: true,
      files: files,
    })
    this.props.onImageUpload(files)
  }

  handleRemoveImage = (evt) => {
    evt.preventDefault()

    this.setState({
      hasPreview: false,
      files: [],
    })

    this.props.onImageDelete()
  }

  handleDateTimeChange = (date) => {
    console.log(date)
    this.setState({
      publishedAt: date,
    })
  }

  getDropzoneNode = () => {
    let zone = null
    let acceptMimeType = 'image/*'
    let maxFilesizeBytes = 15000000 // 15mb

    if (this.state.hasPreview) {
      zone = (
        <div className="content">
          <img src={this.state.files[0].preview} />
          {this.props.isUploaded ? <p><a href="#" onClick={this.handleRemoveImage}>Remove image</a></p> : '' }
          {this.props.isUploading ? 'Uploading..' : '' }
        </div>
      )
    } else {
      zone = (
        <Dropzone className={styles.dropzone} onDrop={this.handleDrop} multiple={false} accept={acceptMimeType} maxSize={maxFilesizeBytes}>
          <p>Drag photo in here to add an image to this notification (max 10MB)</p>
        </Dropzone>
      )
    }

    return zone
  }

  

  render() {
    const btnCls = cx('btn btn-primary', {
      'is-loading': this.props.isSaving || this.props.isUploading,
    })

    return (
      <form className={cx('form', styles.root)}>
        <div className={styles.left}>
          <div className="form-group">
            <label className="label">Image</label>
            {this.getDropzoneNode()}
          </div>
        </div>
        <div className={styles.right}>
          <label className="label">Message</label>
          <div className="form-group">
            <TextEditor onChange={this.handleMessageChange} value={this.state.message} />
            {renderErrorForField('message', this.props.validation)}
          </div>
          <div className="form-group">
            <label className="label">Url</label>
            <input onChange={this.handleUrlChange} value={this.state.url} className="form-control" type="text" placeholder="http://www.example.com" />
            {renderErrorForField('url', this.props.validation)}
          </div>
          <div className="form-group">
            <label className="label">Date/time</label>
            <DateTimePicker date={this.state.publishedAt} onChange={this.handleDateTimeChange} />
          </div>
          <div className="form-group">
            <button onClick={this.handleSave} className={btnCls}>{this.props.buttonName}</button>
          </div>
        </div>
      </form>
    )
  }
}

