import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { loginUser } from 'actions/auth'
import { Link } from 'react-router'
import { getNotifications, deleteNotification } from 'actions/notification'
import NotificationRow from 'components/NotificationRow'
import Pagination from 'components/Pagination'
import styles from './Notifications.css'

export class NotificationsView extends React.Component {

  static propTypes = {
    notifications: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
  }

  componentDidMount = () => {
    let args = {
      application: {
        id: this.props.application.id
      },
      page: 1,
      size: this.props.notifications.size,
    }
    this.props.dispatch(getNotifications(args))
  }

  handleDelete = (notification) => {
    this.props.dispatch(deleteNotification({ notification: notification}))
  }

  handleEdit = (notification) => {
    this.props.dispatch(push(`/notifications/edit/${notification.id}`))
  }

  handleCopy = (notification) => {
    this.props.dispatch(push(`/notifications/copy/${notification.id}/`))
  }

  handlePageClick = (page) => {
    this.props.dispatch(getNotifications({
      application: {
        id: this.props.application.id
      },
      page: page,
      size: this.props.notifications.size,
    }))
  }

  render() {
    let rows = this.props.notifications.items.map(item => {
      return <NotificationRow
        key={item.id}
        notification={item}
        onDelete={this.handleDelete}
        onCopy={this.handleCopy}
        onEdit={this.handleEdit}
      />
    })

    return (
      <div className={styles.root}>
        <h2>Your queued notifications</h2>
        <div className="mb-3">
          <Link to={'/notifications/create'} className="btn btn-primary"><span className="icon"><i className="fa fa-fw fa-plus"></i></span> <span>Add notification</span></Link>
        </div>
        {rows}
        <Pagination
          onClick={this.handlePageClick}
          size={this.props.notifications.size}
          total={this.props.notifications.total}
          page={this.props.notifications.page}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications,
    application: state.applications.selected
  }
}

export default connect(mapStateToProps)(NotificationsView)

