import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { updateEventTemplate, updateEcwidIntegration } from 'actions/integrations'
import styles from './EcwidIntegration.css'

export class EcwidIntegrationView extends React.Component {

  static propTypes = {
    integration: PropTypes.object.isRequired,
  }

  state = {
    template: null,
    templateText: '',
    updateExisting: false,
    storeUrl: '',
  }

  componentWillMount() {
    let defaultEventTemplate = this.props.integration.eventTemplates.find(t => t.default == true)
    this.setState({
      template: defaultEventTemplate,
      templateText: defaultEventTemplate.templateText,
      storeUrl: this.props.integration.ecwid.storeUrl,
    })
  }

  getTags = () => {
    let tags = [
      { tag: '{{ first_name }}', description: 'First name of person who bought the product' },
      { tag: '{{ location }}', description: 'Location of customer in the format City, Country, e.g. London, United Kingdom' },
      { tag: '{{ product_name  }}', description: 'The name of the product' },
      { tag: '{{ city }}', description: 'The billing city of the person who bought the product, this can be blank' },
      { tag: '{{ country }}', description: 'The billing country of the person who bought the product' },
      { tag: '{{ price }}', description: 'The price of the product' },
    ]

    return tags
  }

  getTagExplanation = () => {
    let rows = this.getTags().map(tagItem => {
      return (
        <tr key={tagItem.tag}>
          <td><span className="tag">{ tagItem.tag }</span></td>
          <td>{ tagItem.description}</td>
        </tr>
      )
    })

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Tag</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  handleTemplateSave = (evt) => {
    evt.preventDefault()

    let data = Object.assign({}, this.state.template, {
      templateText: this.state.templateText,
    })

    this.props.dispatch(updateEventTemplate({
      integration: this.props.integration,
      eventTemplate: data,
      updateExisting: this.state.updateExisting,
    }))
  }

  handleTemplateTextChange = (evt) => {
    this.setState({
      templateText: evt.target.value,
    })
  }

  handleUpdateExisting = (evt) => {
    this.setState({ updateExisting: evt.target.checked })
  }

  handleStoreUrlChange = (evt) => {
    this.setState({ storeUrl: evt.target.value })
  }

  handleStoreUrlChangeSubmit = (evt) => {
    evt.preventDefault()
    this.props.dispatch(updateEcwidIntegration({
      data: {
        id: this.props.integration.ecwid.id,
        storeUrl: this.state.storeUrl,
      }
    }))
  }

  render() {
    return (
      <div className={styles.root}>
        <h2>Manage Ecwid integration</h2>

        <div className="alert alert-info">
          Your Ecwid site is successfully integrated <i className="fa fa-check"></i>
        </div>

        <div>
          <p>Your Ecwid website is fully integrated with Crowdlever, any new orders that you get will create a
          notification automatically</p>
        </div>

         <div className={styles.section}>
           <h4>Change store url</h4>
           <p>The product links on notifications are created using the url in your store profile on Ecwid. If you use a custom domain for your store, set it here so that links will go to the correct store.</p>

           <form className="form">
            <div className="form-group">
              <input className="form-control" type="text" value={this.state.storeUrl} onChange={this.handleStoreUrlChange} />
            </div>
            <div className="form-group">
              <button type="button" onClick={this.handleStoreUrlChangeSubmit} className="btn btn-primary">Change store url</button>
            </div>
           </form>
         </div>

        <h4>Change notification template</h4>
        <p>You can change the notification message that will be displayed for new orders using the template below</p>

        <form className="form">
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Notification template..."
              value={this.state.templateText}
              onChange={this.handleTemplateTextChange}></textarea>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={this.state.updateExisting} onChange={this.handleUpdateExisting} />
              <label className="form-check-label">Update existing notifications</label>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-primary" onClick={this.handleTemplateSave}>Save template</button>
          </div>
        </form>

        <br/>
        <p className="content">You can use the following tags in the template used to create notifications for Ecwid</p>
        {this.getTagExplanation()}

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return ownProps
}

export default connect(mapStateToProps)(EcwidIntegrationView)

