
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Growl.css'
import classNames from 'classnames'

export default class Growl extends React.Component {

  static propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    timeout: PropTypes.number.isRequired,
  }

  state = {
    show: true
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        show: false
      })
    }, this.props.timeout)
  }

  handleCloseClick = (evt) => {
    evt.preventDefault()
    this.props.onClose()
  }

  render() {
    let containerClass = classNames({
      [styles.ns_box]: true,
      [styles.ns_bar]: true,
      [styles.ns_show]: this.state.show,
      [styles.ns_hide]: !this.state.show,
      [styles['ns_effect-slidetop']]: true
    })

    let key = 'growl'
    if (this.state.show) {
      key += 'showing'
    } else {
      key += 'hiding'
    }

    return (
      <div key={key} className={containerClass}>
        <div className={styles.ns_box_inner}>
          <p>{this.props.message}</p>
        </div>
        <span className={styles.ns_close} onClick={this.handleCloseClick}></span>
      </div>
    )
  }
}
