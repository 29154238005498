
import deepFreeze from 'deep-freeze'

import { SET_DEFAULT_APPLICATION } from 'actions/application'
import { GET_THEMES, UPDATE_THEME } from 'actions/theme'
import { LOGOUT_USER } from 'actions/auth'

export const themesState = {
  isFetched: false,
  isFetching: false,
  isSaving: false,
  items: []
}

export const themes = (state=themesState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return {
        isFetched: false,
        isFetching: false,
        isSaving: false,
        items: [],
      }
    }
    case GET_THEMES.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        isFetched: false
      })
    }
    case GET_THEMES.SUCCESS: {
      return Object.assign({}, state, {
        isFetched: true,
        isFetching: false,
        items: action.response.data
      })
    }
    case GET_THEMES.FAIL: {
      return Object.assign({}, state, {
        isFetching: false,
        isFetched: false
      })
    }
    case UPDATE_THEME.REQUEST: {
      return state
    }
    case UPDATE_THEME.SUCCESS: {
      deepFreeze(state)
      // update the specific theme in the state
      let newItems = state.items.map(theme => {
        if (theme.id == action.response.data.id) {
          return action.response.data
        } else {
          return theme
        }
      })
      return Object.assign({}, state, {
        isUpdating: false,
        isUpdated: true,
        items: newItems
      })
    }
    case UPDATE_THEME.FAIL: {
      return state
    }
  }

  return state
}
