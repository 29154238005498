
import Cookies from 'cookies-js'
import { push } from 'react-router-redux'
import { createRequestTypes, createAsyncRequestActions } from 'actions'

export const LOGIN_USER = createRequestTypes('LOGIN_USER')
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_SSO = createRequestTypes('LOGIN_SSO')

// sends the reset password link
export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD')
export const RESET_PASSWORD_CLEAR = 'RESET_PASSWORD_CLEAR'

// does the actual password reset with token
export const RESET_UPDATE_PASSWORD = createRequestTypes('RESET_UPDATE_PASSWORD')

export const [
  resetUpdatePassword,
  resetUpdatePasswordSuccess,
  resetUpdatePasswordFail,
] = createAsyncRequestActions(RESET_UPDATE_PASSWORD)

export const [
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
] = createAsyncRequestActions(RESET_PASSWORD)

export const [
  loginSSO,
  loginSSOSuccess,
  loginSSOFail,
] = createAsyncRequestActions(LOGIN_SSO)

export const [
  loginUser,
  loginUserSuccess,
  loginUserFail,
] = createAsyncRequestActions(LOGIN_USER)

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  }
}

export const resetPasswordClear = () => {
  return {
    type: RESET_PASSWORD_CLEAR
  }
}

