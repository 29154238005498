
const popupSettings = "scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no";

const getPopupSize = (provider) => {
  switch (provider) {
    case 'google': {
      return { width: 542, height: 633 }
    }
  }
}

const getPopupSettings = (provider) => {
  const size=  getPopupSize(provider)
  const settingsStr = `${popupSettings},width=${size.width},height=${size.height}`

  return settingsStr
}

export const openPopup = (provider, url, name) => {
  return window.open(url, name, getPopupSettings(provider))
}
