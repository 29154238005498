// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntegrationCreate__integrations {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.IntegrationCreate__card {\n  margin: 1rem;\n  padding: 1rem;\n  border: 1px solid var(--grey-200);\n}\n\n.IntegrationCreate__image img {\n  width: 200px;\n}\n\n.IntegrationCreate__controls {\n  text-align: center;\n  margin-top: 1rem;\n}", ""]);
// Exports
exports.locals = {
	"integrations": "IntegrationCreate__integrations",
	"card": "IntegrationCreate__card",
	"image": "IntegrationCreate__image",
	"controls": "IntegrationCreate__controls"
};
module.exports = exports;
